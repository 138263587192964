import { batch } from 'react-redux'

import { makeActionCreator } from '../../../config/store/utils'

import { deleteQuote, postGenerateQuote, putSavedQuote } from '../../../services/quotes'
import { getProviderAbility } from '../../../services/config'
import { getInfoAttachmentFiles } from '../../../services/attachment'

export const GENERATE_DIRECT_DEAL_QUOTE = 'GENERATE_DIRECT_DEAL_QUOTE'
export const GENERATE_DIRECT_DEAL_QUOTE_SUCCESS = 'GENERATE_DIRECT_DEAL_QUOTE_SUCCESS'
export const GENERATE_DIRECT_DEAL_QUOTE_ERROR = 'GENERATE_DIRECT_DEAL_QUOTE_ERROR'

export const onGenerateDirectDealQuote = makeActionCreator(GENERATE_DIRECT_DEAL_QUOTE, 'payload')
export const onGenerateDirectDealQuoteSuccess = makeActionCreator(GENERATE_DIRECT_DEAL_QUOTE_SUCCESS, 'payload')
export const onGenerateDirectDealQuoteError = makeActionCreator(GENERATE_DIRECT_DEAL_QUOTE_ERROR, 'payload')

export const onGenerateDirectDealQuoteThunk = payload => async dispatch => {
  dispatch(onGenerateDirectDealQuote())

  try {
    const { data } = await postGenerateQuote({ sheetCode: payload.code, actionType: GENERATE_DIRECT_DEAL_QUOTE })

    return batch(() => {
      dispatch(
        onGenerateDirectDealQuoteSuccess({
          quote: {
            idQuote: data?.payload?.id,
            idSubBusiness: data?.payload?.idBusiness,
            idBusiness: data?.payload?.idAdjunto
          }
        })
      )
      payload.callback()
    })
  } catch (err) {
    return dispatch(onGenerateDirectDealQuoteError({ err }))
  }
}

export const SAVED_DIRECT_DEAL_QUOTE = 'SAVED_DIRECT_DEAL_QUOTE'
export const SAVED_DIRECT_DEAL_QUOTE_SUCCESS = 'SAVED_DIRECT_DEAL_QUOTE_SUCCESS'
export const SAVED_DIRECT_DEAL_QUOTE_ERROR = 'SAVED_DIRECT_DEAL_QUOTE_ERROR'

export const onSavedDirectDealQuote = makeActionCreator(SAVED_DIRECT_DEAL_QUOTE, 'payload')
export const onSavedDirectDealQuoteSuccess = makeActionCreator(SAVED_DIRECT_DEAL_QUOTE_SUCCESS)
export const onSavedDirectDealQuoteError = makeActionCreator(SAVED_DIRECT_DEAL_QUOTE_ERROR, 'payload')

export const onSavedDirectDealQuoteThunk = payload => async dispatch => {
  dispatch(onSavedDirectDealQuote())

  try {
    await putSavedQuote({ payload: payload.quoteModel, actionType: SAVED_DIRECT_DEAL_QUOTE })

    return batch(() => {
      dispatch(onSavedDirectDealQuoteSuccess())
      payload.callback(payload.quoteModel)
    })
  } catch ({ originalError }) {
    return batch(() => {
      dispatch(
        onSavedDirectDealQuoteError({
          err: originalError?.response?.data?.errores
            ? originalError?.response?.data?.errores[0]
            : { descripcion: 'No ha sido posible agregar la cotización' }
        })
      )
      payload.callbackError({
        err: originalError?.response?.data?.errores
          ? originalError?.response?.data?.errores[0]
          : { descripcion: 'No ha sido posible agregar la cotización' }
      })
    })
  }
}

export const GET_INFO_DNI_DIRECT_DEAL_QUOTE = 'GET_INFO_DNI_DIRECT_DEAL_QUOTE'
export const GET_INFO_DNI_DIRECT_DEAL_QUOTE_SUCCESS = 'GET_INFO_DNI_DIRECT_DEAL_QUOTE_SUCCESS'
export const GET_INFO_DNI_DIRECT_DEAL_QUOTE_ERROR = 'GET_INFO_DNI_DIRECT_DEAL_QUOTE_ERROR'

export const onGetInfoDniDirectDealQuote = makeActionCreator(GET_INFO_DNI_DIRECT_DEAL_QUOTE, 'payload')
export const onGetInfoDniDirectDealQuoteSuccess = makeActionCreator(GET_INFO_DNI_DIRECT_DEAL_QUOTE_SUCCESS)
export const onGetInfoDniDirectDealQuoteError = makeActionCreator(GET_INFO_DNI_DIRECT_DEAL_QUOTE_ERROR, 'payload')

export const onGetInfoDniDirectDealQuoteThunk = payload => async dispatch => {
  dispatch(onGetInfoDniDirectDealQuote())

  try {
    const { data } = await getProviderAbility({
      providerDni: payload.quoteDni,
      actionType: GET_INFO_DNI_DIRECT_DEAL_QUOTE
    })

    return batch(() => {
      dispatch(onGetInfoDniDirectDealQuoteSuccess())
      payload.callback(data?.payload?.businessName)
    })
  } catch (err) {
    return batch(() => {
      dispatch(onGetInfoDniDirectDealQuoteError({ err }))
      payload.callbackError()
    })
  }
}

export const STORAGE_ID_DIRECT_DEAL_QUOTE = 'STORAGE_ID_DIRECT_DEAL_QUOTE'
export const onStorageIdDirectDealQuote = makeActionCreator(STORAGE_ID_DIRECT_DEAL_QUOTE, 'payload')

export const CLEAN_QUOTE_DIRECT_DEAL_QUOTE = 'CLEAN_QUOTE_DIRECT_DEAL_QUOTE'
export const onCleanQuoteDirectDeal = makeActionCreator(CLEAN_QUOTE_DIRECT_DEAL_QUOTE)

export const VALID_ATTACHMENTS_DIRECT_DEAL_QUOTE = 'VALID_ATTACHMENTS_DIRECT_DEAL_QUOTE'
export const VALID_ATTACHMENTS_DIRECT_DEAL_QUOTE_SUCCESS = 'VALID_ATTACHMENTS_DIRECT_DEAL_QUOTE_SUCCESS'
export const VALID_ATTACHMENTS_DIRECT_DEAL_QUOTE_ERROR = 'VALID_ATTACHMENTS_DIRECT_DEAL_QUOTE_ERROR'

export const onValidAttachmentsDirectDealQuote = makeActionCreator(VALID_ATTACHMENTS_DIRECT_DEAL_QUOTE, 'payload')
export const onValidAttachmentsDirectDealQuoteSuccess = makeActionCreator(VALID_ATTACHMENTS_DIRECT_DEAL_QUOTE_SUCCESS)
export const onValidAttachmentsDirectDealQuoteError = makeActionCreator(
  VALID_ATTACHMENTS_DIRECT_DEAL_QUOTE_ERROR,
  'payload'
)

export const onValidAttachmentsDirectDealQuoteThunk = payload => async dispatch => {
  dispatch(onValidAttachmentsDirectDealQuote())

  try {
    const { data } = await getInfoAttachmentFiles({
      attachmentCodes: payload.attachmentCodes,
      actionType: VALID_ATTACHMENTS_DIRECT_DEAL_QUOTE
    })

    return batch(() => {
      dispatch(onValidAttachmentsDirectDealQuoteSuccess())
      payload.callback({ attachmentAmount: data?.cantidadAdjuntos || 0 })
    })
  } catch (err) {
    return dispatch(onValidAttachmentsDirectDealQuoteError({ err }))
  }
}

export const SAVE_QUOTE_DIRECT_DEAL_QUOTE = 'SAVE_QUOTE_DIRECT_DEAL_QUOTE'
export const onSaveQuoteDirectDealQuote = makeActionCreator(SAVE_QUOTE_DIRECT_DEAL_QUOTE, 'payload')

export const UPDATE_QUOTE_DIRECT_DEAL_QUOTE = 'UPDATE_QUOTE_DIRECT_DEAL_QUOTE'
export const onUpdateQuoteDirectDealQuote = makeActionCreator(UPDATE_QUOTE_DIRECT_DEAL_QUOTE, 'payload')

export const REMOVE_QUOTE_DIRECT_DEAL_QUOTE = 'REMOVE_QUOTE_DIRECT_DEAL_QUOTE'
export const REMOVE_QUOTE_DIRECT_DEAL_QUOTE_SUCCESS = 'REMOVE_QUOTE_DIRECT_DEAL_QUOTE_SUCCESS'
export const REMOVE_QUOTE_DIRECT_DEAL_QUOTE_ERROR = 'REMOVE_QUOTE_DIRECT_DEAL_QUOTE_ERROR'

export const onRemoveQuoteDirectDealQuote = makeActionCreator(REMOVE_QUOTE_DIRECT_DEAL_QUOTE)
export const onRemoveQuoteDirectDealQuoteSuccess = makeActionCreator(REMOVE_QUOTE_DIRECT_DEAL_QUOTE_SUCCESS, 'payload')
export const onRemoveQuoteDirectDealQuoteError = makeActionCreator(REMOVE_QUOTE_DIRECT_DEAL_QUOTE_ERROR, 'payload')

export const onRemoveQuoteDirectDealQuoteThunk = payload => async dispatch => {
  dispatch(onRemoveQuoteDirectDealQuote())

  try {
    await deleteQuote({ idQuote: payload.idQuote, actionType: REMOVE_QUOTE_DIRECT_DEAL_QUOTE_SUCCESS })

    return batch(() => {
      dispatch(onRemoveQuoteDirectDealQuoteSuccess({ idQuote: payload.idQuote }))
      payload.callback()
    })
  } catch (err) {
    return batch(() => {
      dispatch(onRemoveQuoteDirectDealQuoteError({ err }))
      payload.callbackError()
    })
  }
}

export const RESET_DIRECT_DEAL_QUOTE = 'RESET_DIRECT_DEAL_QUOTE'
export const onResetDirectDealQuote = makeActionCreator(RESET_DIRECT_DEAL_QUOTE)

export const OPEN_QUOTE_DIRECT_DEAL_QUOTE = 'OPEN_QUOTE_DIRECT_DEAL_QUOTE'
export const onOpenQuoteDirectDealQuote = makeActionCreator(OPEN_QUOTE_DIRECT_DEAL_QUOTE)

export const CLOSE_QUOTE_DIRECT_DEAL_QUOTE = 'CLOSE_QUOTE_DIRECT_DEAL_QUOTE'
export const onCloseQuoteDirectDealQuote = makeActionCreator(CLOSE_QUOTE_DIRECT_DEAL_QUOTE)

export const OPEN_DETAIL_QUOTE_DIRECT_DEAL_QUOTE = 'OPEN_DETAIL_QUOTE_DIRECT_DEAL_QUOTE'
export const onOpenDetailQuoteDirectDealQuote = makeActionCreator(OPEN_DETAIL_QUOTE_DIRECT_DEAL_QUOTE)

export const CLOSE_DETAIL_QUOTE_DIRECT_DEAL_QUOTE = 'CLOSE_DETAIL_QUOTE_DIRECT_DEAL_QUOTE'
export const onCloseDetailQuoteDirectDealQuote = makeActionCreator(CLOSE_DETAIL_QUOTE_DIRECT_DEAL_QUOTE)

export const INITIAL_SAVED_VALUES_DIRECT_DEAL_QUOTE = 'INITIAL_SAVED_VALUES_DIRECT_DEAL_QUOTE'
export const onInitialSavedValuesDirectDealQuote = makeActionCreator(INITIAL_SAVED_VALUES_DIRECT_DEAL_QUOTE, 'payload')
