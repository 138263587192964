import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { Button, Card, CircleSuccessIcon, Dialog, Input, Textarea, Typography } from '@chilecompra/react-kit/components'
import {
  checkInputErrorRequired,
  checkInputErrorMaxLength,
  useInput,
  useTextarea,
  useViewport
} from '@chilecompra/react-kit'

import {
  onGetInfoProviderDirectDealSummaryThunk,
  onCloseContractDialogDirectDealSummary
} from '../DirectDealSummary.actions'

import AttachmentsWrapper from '../../../components/components/AttachmentsWrapper.component'

import { Divider, Grid, Strong } from '../DirectDealSummary.styles'

import { formatDNI } from '../../../modules/utils/formatters'
import { useImperativeFormRefWithReset } from '../../../modules/hooks/useFormRef'

import {
  DIRECT_DEAL_SUMMARY_REQUEST_CONTRACT_STEP_DICTIONARY,
  CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL
} from '../../../config/settings/constants'

/**
 * The DirectDealSummaryContract dialog
 */
const DirectDealSummaryContractDialog = props => {
  const { contracts, formRef, loading, onSend, provider, userDni } = props

  const dispatch = useDispatch()
  const { size } = useViewport()

  const [contractValues, setContractValues] = useState(null)
  const [openModalAttachRequest, setOpenModalAttachRequest] = useState(false)
  const [openModalDeleteAllRequest, setOpenModalDeleteAllRequest] = useState(false)

  const {
    error: answerRequestError,
    onChange: answerRequestChange,
    value: answerRequestValue,
    onError: onAnswerRequestError,
    setValue: setAnswerRequestValue,
    onReset: onAnswerRequestReset
  } = useTextarea({
    initialValue: contractValues?.answer || '',
    errorCallbacks: [checkInputErrorMaxLength(500), checkInputErrorRequired()]
  })

  const {
    error: dniRequestError,
    value: dniRequestValue,
    onChange: onDniRequestChange,
    onError: onDniRequestError
  } = useInput({
    changeCallback: updatedValue =>
      updatedValue?.length > 10 && dispatch(onGetInfoProviderDirectDealSummaryThunk({ dni: updatedValue })),
    formatCallbacks: [value => formatDNI(value)],
    errorCallbacks: [checkInputErrorRequired()],
    initialValue: userDni || ''
  })

  const {
    error: businessRequestError,
    value: businessRequestValue,
    onChange: businessRequestChange,
    onError: onBusinessRequestError,
    setValue: setBusinessRequestValue
  } = useInput({
    errorCallbacks: [checkInputErrorRequired()],
    initialValue: provider?.providerLegalName || ''
  })

  const disabledSend = useMemo(() => {
    return (
      answerRequestValue === '' ||
      answerRequestError !== '' ||
      businessRequestValue === '' ||
      businessRequestError !== '' ||
      dniRequestValue === '' ||
      dniRequestError !== ''
    )
  }, [
    answerRequestValue,
    answerRequestError,
    businessRequestValue,
    businessRequestError,
    dniRequestValue,
    dniRequestError
  ])

  const handlerCloseRequest = () => {
    dispatch(onCloseContractDialogDirectDealSummary())
    setAnswerRequestValue('')
    onAnswerRequestReset()
  }

  useEffect(() => {
    if (provider && provider?.providerLegalName) {
      setBusinessRequestValue(provider.providerLegalName)
    } else {
      setBusinessRequestValue('')
    }
  }, [provider])

  useImperativeFormRefWithReset(
    formRef,
    () => ({
      answerRequestValue: {
        onChange: answerRequestChange,
        value: answerRequestValue,
        error: answerRequestError,
        onError: onAnswerRequestError
      },
      businessRequestError,
      businessRequestValue,
      dniRequestError,
      dniRequestValue
    }),
    [answerRequestValue, businessRequestError, businessRequestValue, dniRequestError, dniRequestValue]
  )

  useEffect(() => {
    setContractValues(contracts.requests.find(request => request.id === contracts?.sheetIdRequesting))
  }, [contracts?.openForm])

  return (
    <Dialog
      open={contracts?.openForm || false}
      disableBackdropClick
      verticalAlign="top"
      maxWidth={
        size.isTiny || size.isSmall || contracts?.step === DIRECT_DEAL_SUMMARY_REQUEST_CONTRACT_STEP_DICTIONARY.SUCCESS
          ? '400px'
          : '800px'
      }
      onClose={handlerCloseRequest}
    >
      {contracts.step === DIRECT_DEAL_SUMMARY_REQUEST_CONTRACT_STEP_DICTIONARY.FORM && (
        <Grid
          container
          spacing={2}
          sx={{
            padding: {
              xs: '16px',
              sm: '24px 24px',
              md: '30px 40px',
              lg: '50px 61px'
            }
          }}
        >
          <Grid item margin="0 0 4px">
            <Typography variant="h3" fontWeight="bold" color="black1" lineHeight="18px" margin="8px 0">
              Solicitud de un nuevo proceso de contratación
            </Typography>
            <Typography color="black1" variant="body2">
              Si ofreces el mismo producto o servicio, o conoces otro proveedor que lo haga por favor adjunta la
              documentación necesaria que lo demuestre.
            </Typography>
            <Divider margin="16px 0" />
          </Grid>
          <Grid item>
            <Card color="primary" padding="16px">
              <Typography color="black1" variant="body2">
                De acuerdo a la causal señalada en el Art. 8 bis de la ley 19.886, si ofreces los mismos productos o
                servicios solicitados por el organismo comprador en este Trato Directo, tendrás la posibilidad de
                responder y solicitar que se realice otro proceso de contratación, la respuesta que ingreses será de
                <Strong> carácter público</Strong> y todos los usuarios podrán visualizarla identificando la fuente.
              </Typography>
            </Card>
          </Grid>
          <Grid item>
            <Textarea
              error={answerRequestError}
              label="Ingresa tu respuesta"
              loading={loading}
              maxCount={500}
              size="medium"
              value={answerRequestValue}
              onChange={answerRequestChange}
              onError={onAnswerRequestError}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Input
              disabled
              label="RUT"
              loading={loading}
              value={dniRequestValue}
              onChange={onDniRequestChange}
              onError={onDniRequestError}
              error={dniRequestError}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Input
              disabled
              label="Razón social"
              loading={loading}
              value={businessRequestValue}
              onChange={businessRequestChange}
              onError={onBusinessRequestError}
              error={businessRequestError}
            />
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography fontSize="16px" fontWeight="bold">
              Adjuntar evidencia
            </Typography>
            <Typography fontSize="14px" lineHeight="18px" width="80%" margin="0 0 13px">
              Adjunta documentos que demuestren la veracidad de tu respuesta. Se admiten archivos con un peso máximo de
              20mb.
            </Typography>
            <AttachmentsWrapper
              business={CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL}
              idBusiness={contractValues?.idBusiness}
              subBusiness={contractValues?.subBusiness}
              isPrivate
              color="blank"
              canDelete
              canRead
              canWrite
              canWriteBulk
              openModal={openModalAttachRequest}
              openModalDeleteAll={openModalDeleteAllRequest}
              setOpenModal={setOpenModalAttachRequest}
              setOpenModalDeleteAll={setOpenModalDeleteAllRequest}
            />
          </Grid>
          <Grid item textAlign="left" lg={6} md={6} sm={12} xs={12}>
            <Button
              aria-label="Cerrar"
              variant="contained"
              width={size.isSmall || size.isTiny ? '100%' : '60%'}
              onClick={handlerCloseRequest}
            >
              Cerrar
            </Button>
          </Grid>
          <Grid item textAlign="right" lg={6} md={6} sm={12} xs={12}>
            <Button
              aria-label="Enviar solicitud"
              color="primary"
              disabled={disabledSend}
              loading={loading}
              variant="contained"
              width={size.isSmall || size.isTiny ? '100%' : '70%'}
              onClick={onSend}
            >
              Enviar solicitud
            </Button>
          </Grid>
        </Grid>
      )}
      {contracts.step === DIRECT_DEAL_SUMMARY_REQUEST_CONTRACT_STEP_DICTIONARY.SUCCESS && (
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item textAlign="center" padding="13px">
            <CircleSuccessIcon />
            <Typography fontSize="18px" lineHeight="22px" fontWeight="bold" color="black1">
              Solicitud enviada con éxito
            </Typography>
          </Grid>
          <Grid item margin="30px 0" textAlign="center" padding="0 70px">
            <Typography fontSize="16px" lineHeight="21px" color="black1">
              La solicitud de un nuevo proceso de contratación fue enviada y será evaluada por el organismo comprador.
            </Typography>
          </Grid>
          <Grid item padding="0 50px" margin="4px 0" textAlign="center">
            <Button aria-label="Cerrar" variant="containt" color="success" onClick={handlerCloseRequest}>
              Cerrar
            </Button>
          </Grid>
        </Grid>
      )}
    </Dialog>
  )
}

DirectDealSummaryContractDialog.propTypes = {
  contracts: PropTypes.shape({
    requests: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        dni: PropTypes.string,
        isActive: PropTypes.bool
      })
    ),
    openForm: PropTypes.bool,
    step: PropTypes.string,
    sheetIdRequesting: PropTypes.string
  }),
  formRef: PropTypes.shape({
    current: PropTypes.shape(Object)
  }),
  loading: PropTypes.bool,
  onSend: PropTypes.func,
  provider: PropTypes.shape({
    providerLegalName: PropTypes.string,
    providerDni: PropTypes.string
  }),
  userDni: PropTypes.string
}

export default DirectDealSummaryContractDialog
