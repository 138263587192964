import React from 'react'

import { Button } from '@chilecompra/react-kit/components'
import { deleteCookie } from '@chilecompra/react-kit'

import { PUBLIC_URL, REACT_APP_MERCADO_PUBLICO_BASE_URL } from '../../config/settings/environment'

import PublicSection from '../../components/Security/PublicSection'
import HeaderPublicMenu from './HeaderPublic.menu'

import { Container, Item, Logo, LogoLink, Root } from './HeaderPublic.styles'

/**
 * The Header Public's container.
 */
const HeaderPublicContainer = () => {
  const handleClickHome = (event, isPrivate) => {
    event.preventDefault()
    deleteCookie('access_token')
    if (isPrivate) {
      window.open(`${REACT_APP_MERCADO_PUBLICO_BASE_URL}/Portal/Modules/Menu/Menu.aspx`, '_top')
      return
    }
    location.href = `${REACT_APP_MERCADO_PUBLICO_BASE_URL}/Home`
  }

  return (
    <PublicSection>
      <Root>
        <HeaderPublicMenu />
        <Container direction="row" justifyContent="flex-start" margin="0 0 48px">
          <Item width="auto">
            <LogoLink href={`${REACT_APP_MERCADO_PUBLICO_BASE_URL}/Home`} target="_top">
              <Logo alt="Chilecompra" src={`${PUBLIC_URL}/assets/images/logo_mercadopublico.svg`} />
            </LogoLink>
            <Button
              aria-label="Volver al inicio"
              margin="10px 0 0 0"
              color="default"
              variant="outlined"
              onClick={event => handleClickHome(event)}
            >
              Volver al inicio
            </Button>
          </Item>
        </Container>
      </Root>
    </PublicSection>
  )
}

export default HeaderPublicContainer
