import React from 'react'
import PropTypes from 'prop-types'

import { Skeleton, Spinner } from '@chilecompra/react-kit/components'

import ProtectByFeatures from '../../components/Security/ProtectByFeatures'
import { GridItem, Link, IconArrowDownward } from './DirectDealSearchDownload.styles'

/**
 * The DirectDealSearchDownloadLink container.
 */
const DirectDealSearchDownloadLink = props => {
  const { error, loadingDownload, loadingSearch, onDownload, resultCount, rule } = props

  return (
    <ProtectByFeatures rule={rule}>
      <GridItem margin="10px 0 16px 0">
        {loadingSearch && <Skeleton width="150px" />}
        {!loadingSearch && error === undefined && resultCount > 0 && (
          <>
            <Link href="#" onClick={onDownload}>
              {loadingDownload ? 'Descargando resultados...' : 'Descargar resultados en excel'}
            </Link>

            {loadingDownload ? <Spinner size={15} /> : <IconArrowDownward margin="0 0 -3px 5px" fontSize="16px" />}
          </>
        )}
      </GridItem>
    </ProtectByFeatures>
  )
}

DirectDealSearchDownloadLink.propTypes = {
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  loadingDownload: PropTypes.bool,
  loadingSearch: PropTypes.bool,
  onDownload: PropTypes.func.isRequired,
  resultCount: PropTypes.number,
  rule: PropTypes.func
}

export default DirectDealSearchDownloadLink
