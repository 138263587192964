import React, { useEffect, useMemo, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { getCookie, useEffectOnce, useViewport } from '@chilecompra/react-kit'

import DirectDealBasicBackground from './DirectDealStepOne.basicbackground'
import DirectDealRequester from './DirectDealStepOne.requester'
import DirectDealStepOneSuccessDialog from './DirectDealStepOne.success.dialog'
import DirectDealStepOneFooter from './DirectDealStepOne.footer'

import {
  onCleanInfoStepZeroDirectDealStepOne,
  onCreateDirectDealThunk,
  onCloseConfirmStepOneDialog,
  onGetDescriptionCausesStepOneThunk,
  onGetUTMStepOneThunk,
  onResetStepOne
} from './DirectDealStepOne.actions'
import { onSavedInfoStepOneDirectDealStepTwo } from '../DirectDealStepTwo/DirectDealStepTwo.actions'
import { onReturnInfoStepZeroDirectDealStepZero } from '../DirectDealStepZero/DirectDealStepZero.actions'

import { Root, Divider } from './DirectDealStepOne.styles'

import routes from '../../config/settings/routes'
import { useFormRef } from '../../modules/hooks/useFormRef'
import { dialogScrollTop } from '../../modules/utils/dialogScrollTop'
import { DIRECT_DEAL_TYPE_CAUSES_NEED_ADITIONAL_DOCUMENTS } from '../../config/settings/constants'

/**
 * The DirectDealStepOne's container.
 */
const DirectDealStepOneContainer = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const formStepOneRef = useFormRef()
  const { size } = useViewport()

  const [isRequiringMoreDocuments, setRequiresMoreDocuments] = useState(false)
  const {
    loadings: directDealStepOneLoadings,
    info: requesterInfo,
    utm: directDealStepOneUTM,
    isShowConfirmDialog,
    sheetCode: directDealStepOneSheetCode,
    causes: directDealStepOneCauses,
    stepZero: directDealStepZero
  } = useSelector(state => state.directDealStepOne)

  const handlerBack = () => {
    const query = getCookie('search')
    navigate({ pathname: routes.search, search: query.toString() }, { replace: true })
  }

  const handlerBackStepZero = () => {
    batch(() => {
      dispatch(onReturnInfoStepZeroDirectDealStepZero(directDealStepZero))
      dispatch(onCleanInfoStepZeroDirectDealStepOne())
      navigate({ pathname: routes.directDealStepZero }, { state: { from: routes.directDealStepOne }, replace: true })
    })
  }

  const handlerNext = () => {
    if (isInvalidStepOne) {
      formStepOneRef.current?.cause?.onError()
      formStepOneRef.current?.dni?.onError()
      formStepOneRef.current?.email?.onError()
      formStepOneRef.current?.job?.onError()
      formStepOneRef.current?.name?.onError()
      formStepOneRef.current?.lastName?.onError()

      return
    }

    dispatch(
      onCreateDirectDealThunk({
        stepOne: {
          name: formStepOneRef.current.name?.value,
          cause: directDealStepOneCauses?.find(cause => cause.value === formStepOneRef.current.cause?.value) || null,
          amount: formStepOneRef.current.greaterThanThounsand?.value === '1',
          dni: formStepOneRef.current.dni?.value,
          lastName: formStepOneRef.current.lastName?.value,
          job: formStepOneRef.current.job?.value,
          email: formStepOneRef.current.email?.value,
          withPublicity: formStepOneRef.current.isSelectedCausePublicity
        },
        stepZero: {
          resolution: directDealStepZero?.resolution || '',
          resolutionDate: directDealStepZero?.resolutionDate || '',
          names: directDealStepZero?.names || '',
          lastNames: directDealStepZero?.lastNames || '',
          dni: directDealStepZero?.dni || '',
          job: directDealStepZero?.job || '',
          originDirectDeal: directDealStepZero?.origin
        },
        callback: sheet => {
          dispatch(onSavedInfoStepOneDirectDealStepTwo(sheet))
          dialogScrollTop()
        }
      })
    )
  }
  const handlerRedirectStepTwo = () => {
    navigate(
      { pathname: routes.directDealStepTwo.replace(/:directDealId/, directDealStepOneSheetCode) },
      { replace: true }
    )
  }

  const isInvalidStepOne = useMemo(() => {
    return (
      formStepOneRef.current.name?.value === '' ||
      formStepOneRef.current.name?.error !== '' ||
      formStepOneRef.current.cause?.value === '' ||
      formStepOneRef.current.cause?.error !== '' ||
      formStepOneRef.current.greaterThanThounsand?.value === '' ||
      formStepOneRef.current.dni?.value === '' ||
      formStepOneRef.current.dni?.error !== '' ||
      formStepOneRef.current.lastName?.value === '' ||
      formStepOneRef.current.lastName?.error !== '' ||
      formStepOneRef.current.job?.value === '' ||
      formStepOneRef.current.job?.error !== '' ||
      formStepOneRef.current.email?.value === '' ||
      formStepOneRef.current.email?.error !== ''
    )
  }, [
    formStepOneRef.current.name?.value,
    formStepOneRef.current.name?.error,
    formStepOneRef.current.cause?.value,
    formStepOneRef.current.cause?.error,
    formStepOneRef.current.greaterThanThounsand?.value,
    formStepOneRef.current.dni?.value,
    formStepOneRef.current.dni?.error,
    formStepOneRef.current.lastName?.value,
    formStepOneRef.current.lastName?.error,
    formStepOneRef.current.job?.value,
    formStepOneRef.current.job?.error,
    formStepOneRef.current.email?.value,
    formStepOneRef.current.email?.error
  ])

  useEffectOnce(() => {
    batch(() => {
      dispatch(onResetStepOne())
      dispatch(onCloseConfirmStepOneDialog())
      if (directDealStepZero?.origin) {
        dispatch(onGetDescriptionCausesStepOneThunk(directDealStepZero?.origin))
      }
      dispatch(onGetUTMStepOneThunk())
    })
  })

  useEffect(() => {
    setRequiresMoreDocuments(
      DIRECT_DEAL_TYPE_CAUSES_NEED_ADITIONAL_DOCUMENTS.some(item => item.id === formStepOneRef.current.cause?.value)
    )
  }, [formStepOneRef.current.cause?.value])

  return (
    <Root>
      <Divider width="100%" />
      <DirectDealBasicBackground
        stepZeroRadioOption={directDealStepZero?.origin}
        causes={directDealStepOneCauses}
        formRef={formStepOneRef}
        loading={directDealStepOneLoadings.page}
        requiredMoreDocuments={isRequiringMoreDocuments}
        utm={directDealStepOneUTM}
      />
      <Divider width={size.isTiny || size.isSmall ? '100%' : '67%'} />
      <DirectDealRequester
        formRef={formStepOneRef}
        loading={directDealStepOneLoadings.requester || directDealStepOneLoadings.page}
        requesterInfo={requesterInfo}
        requiredMoreDocuments={isRequiringMoreDocuments}
      />
      <Divider width="100%" />
      <DirectDealStepOneFooter
        loading={directDealStepOneLoadings.requester || directDealStepOneLoadings.page}
        onBack={handlerBackStepZero}
        onContinue={handlerNext}
      />
      <DirectDealStepOneSuccessDialog
        open={isShowConfirmDialog}
        sheetCode={directDealStepOneSheetCode}
        onBack={handlerBack}
        onNext={handlerRedirectStepTwo}
      />
    </Root>
  )
}

export default DirectDealStepOneContainer
