import React from 'react'
import { Grid as BaseGrid, styled } from '@mui/material'

export const Div = styled('div')`
  grid-area: new-directdeal-box;
`
export const Column = styled(({ margin, padding, ...props }) => <BaseGrid item {...props} />)`
  &.MuiGrid-item {
    margin: ${props => props.margin || 0};
    padding: ${props => props.padding || 0};
    width: 100%;
    height: 100%;
    background-color: ${props => (props.backgroundColor ? props.theme.palette.green[95] : props.theme.palette.white)};
    border: ${props => (props.border ? '1px solid' : 'none')};
  }
`
export const Row = styled(({ margin, padding, paddingTop, ...props }) => <BaseGrid container {...props} />)`
  &.MuiGrid-container {
    margin: ${props => props.margin || 0};
    padding: ${props => props.padding || 0};
    align-items: center;
  }
`
export const Image = styled('img')`
  width: 32px;
  height: 32px;
  display: block;
`
export const greenSheetIcon = styled('Img')`
  height: 204px;
  width: 200px;
`

export const Link = styled('a')`
  font-size: 16px;
  color: ${props => props.theme.palette.blue[50]};
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.palette.blue[35]};
  }

  &:active,
  &:focus,
  &:visited {
    color: ${props => props.theme.palette.brand.blue};
  }
`
