import React from 'react'

import BrandLayout from '../../components/Layouts/BrandLayout'
import ProtectByProfile from '../../components/Security/ProtectByProfile'

import AuthorizationContext from '../../containers/AuthProvider/AuthProvider.context'
import AuthorizationProviderContainer from '../../containers/AuthProvider/AuthProvider.container'
import DirectDealFilter from '../../containers/DirectDealFilter/DirectDealFilter.container'
import DirectDealPageHeader from '../../containers/DirectDealPageHeader/DirectDealPageHeader.container'
import DirectDealSearchDownload from '../../containers/DirectDealSearchDownload/DirectDealSearchDownload.container'
import FooterPublicContainer from '../../containers/FooterPublic/FooterPublic.container'
import HeaderPublicContainer from '../../containers/HeaderPublic/HeaderPublic.container'
import NewDirectDealBox from '../../containers/NewDirectDealBox/NewDirectDealBox.container'
import SearchAndFilterBar from '../../containers/SearchAndFilterBar/SearchAndFilterBar.container'
import SearchResults from '../../containers/SearchResults/SearchResults.container'

import NotFound from '../NotFound/NotFound'

import { SearchGridLayout } from './DirectDealSearch.styles'

/**
 * The DirectDealSearch's public page.
 */
const DirectDealSearchPublic = () => {
  const directDealSearchHeader = {
    breadcrumbs: [
      {
        text: 'Escritorio'
      },
      {
        text: 'Trato Directo'
      }
    ],
    title: 'Trato Directo'
  }

  return (
    <AuthorizationProviderContainer>
      <AuthorizationContext.Consumer>
        {({ isBuyer, isSeller }) => (
          <ProtectByProfile
            rule={userType => userType !== 'Comprador' && userType !== 'Proveedor'}
            fallback={() => <NotFound />}
          >
            <>
              <BrandLayout
                header={<HeaderPublicContainer />}
                featured={<DirectDealPageHeader {...directDealSearchHeader} />}
                footer={<FooterPublicContainer />}
              >
                <SearchGridLayout isBuyer={isBuyer} isSeller={isSeller}>
                  <SearchAndFilterBar />
                  <NewDirectDealBox />
                  <DirectDealSearchDownload />
                  <DirectDealFilter />
                  <SearchResults />
                </SearchGridLayout>
              </BrandLayout>
            </>
          </ProtectByProfile>
        )}
      </AuthorizationContext.Consumer>
    </AuthorizationProviderContainer>
  )
}
export default DirectDealSearchPublic
