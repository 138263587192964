import React from 'react'

import {
  Grid as BaseGrid,
  styled,
  Accordion as AccordionBase,
  AccordionSummary as AccordionSummaryBase,
  AccordionDetails as AccordionDetailsBase,
  Slider as SliderBase,
  IconButton as IconButtonBase,
  Divider as DividerBase
} from '@mui/material'
import { ExpandMore } from '@mui/icons-material'
import { DatePicker as DatePickerBase } from '@chilecompra/react-kit/components'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'

export const Accordion = styled(AccordionBase)`
  grid-area: filters;
  margin: 0 0 0 !important;
  padding: 0 24px 0 0;
`
export const AccordionSummary = styled(AccordionSummaryBase)`
  &.MuiAccordionSummary-root {
    padding: 0;
  }
`
export const AccordionDetails = styled(AccordionDetailsBase)`
  &.MuiAccordionDetails-root {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`
export const ExpandMoreIcon = styled(ExpandMore)``

export const Grid = styled(({ textAlign, ...props }) => <BaseGrid {...props} />)`
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  text-align: ${props => props.textAlign || 'initial'};
  width: 100%;
`
export const GridInline = styled(({ textAlign, ...props }) => <BaseGrid container {...props} />)`
  &.MuiGrid-container {
    height: ${props => props.height};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
  }
`
export const GridItemInline = styled(({ textAlign, ...props }) => <BaseGrid item {...props} />)`
  &.MuiGrid-item {
    height: ${props => props.height};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
  }
`
export const HiddenMd = styled('div')`
  ${props => props.theme.breakpoints.up('md')} {
    display: none;
  }
`
export const HiddenSm = styled('div')`
  ${props => props.theme.breakpoints.down('md')} {
    display: none;
  }
`
export const Slider = styled(SliderBase)`
  &.MuiSlider-root {
    .MuiSlider-thumb {
      color: ${props => props.theme.palette.brand.white};
      border: 5px solid ${props => props.theme.palette.blue[50]};
    }
  }
  &.MuiSlider-root {
    color: ${props => props.theme.palette.blue[50]};
  }
`

export const ArrowButton = styled(KeyboardArrowRightIcon)`
  background-color: ${({ disabled, theme }) => (disabled ? theme.palette.brand.gray3 : theme.palette.blue[50])};
  color: ${props => props.theme.palette.brand.white};
  border-radius: 50%;
  font-size: ${props => props.fontSize};
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  transition:
    color 0.3s,
    background-color 0.3s;
`

export const Button = styled(IconButtonBase)`
  color: ${props => props.theme.palette.brand.primary};
`

export const Hr = styled(DividerBase)`
  background: ${props => props.theme.palette.gray[85]};
  margin: ${props => props.margin};
`

export const DatePicker = styled(({ className, ...props }) => (
  <DatePickerBase
    {...props}
    PopoverProps={{
      ...props.PopoverProps,
      anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
      classes: { ...props.PopoverProps?.classes, paper: className },
      transformOrigin: { horizontal: 'left', vertical: 'top' }
    }}
    variant="inline"
  />
))`
  &.MuiPopover-paper {
    box-shadow: 0 3px 6px ${props => props.theme.palette.gray[0.16]};
  }

  &.MuiPopover-paper .MuiPickersBasePicker-container {
    .MuiPickersToolbar-toolbar {
      background-color: ${props => props.theme.palette.blue[50]};
    }

    .MuiPickersToolbar-toolbar .MuiButtonBase-root:hover {
      background-color: transparent;
    }

    .MuiTypography-subtitle1,
    .MuiTypography-body1 {
      font-size: 16px;
    }

    .MuiTypography-h4 {
      font-size: 34px;
    }

    .MuiTypography-caption {
      font-size: 14px;
    }

    .MuiTypography-body2 {
      font-size: 14px;
    }

    .MuiTypography-h5 {
      font-size: 24px;
    }

    .MuiPickersCalendarHeader-iconButton .MuiSvgIcon-root {
      font-size: 24px;
    }
  }

  &.MuiPopover-paper .MuiPickersDay-day {
    color: ${props => props.theme.palette.gray[20]};
  }

  &.MuiPopover-paper .MuiPickersDay-current {
    color: ${props => props.theme.palette.blue[50]} !important;
  }

  &.MuiPopover-paper .MuiPickersDay-dayDisabled,
  &.MuiPopover-paper .MuiPickersCalendarHeader-dayLabel {
    color: ${props => props.theme.palette.gray[50]};
  }

  &.MuiPopover-paper .MuiPickersDay-daySelected {
    background-color: ${props => props.theme.palette.blue[50]} !important;
    color: ${props => props.theme.palette.brand.white} !important;
  }

  &.MuiPopover-paper .MuiPickersCalendarHeader-transitionContainer .MuiTypography-body1 {
    color: ${props => props.theme.palette.gray[20]};
  }

  &.MuiPopover-paper .MuiPickersCalendarHeader-iconButton {
    color: ${props => props.theme.palette.gray[50]};
  }

  &.MuiPopover-paper .MuiIconButton-root.Mui-disabled {
    color: ${props => props.theme.palette.gray[50]};
  }

  &.MuiPopover-paper .MuiPickersYear-root {
    color: ${props => props.theme.palette.gray[20]};
  }

  &.MuiPopover-paper .MuiPickersYear-yearDisabled {
    color: ${props => props.theme.palette.gray[50]};
  }

  &.MuiPopover-paper .MuiPickersYear-yearSelected {
    color: ${props => props.theme.palette.blue[50]} !important;
  }

  &.MuiPopover-paper .MuiDialogActions-root {
    display: none !important;
  }

  &.MuiPopover-paper .MuiTouchRipple-root {
    display: none;
  }
`
