import React, { useEffect, useMemo, useState, useRef } from 'react'
import { batch, useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

import { getCookie, useEffectOnce, usePathParam } from '@chilecompra/react-kit'

import DirectDealStepTwoAbilityDialog from './Dialogs/DirectDealStepTwo.ability.dialog'
import DirectDealStepTwoConfirmDialog from './Dialogs/DirectDealStepTwo.confirm.dialog'
import DirectDealStepTwoSavedDialog from './Dialogs/DirectDealStepTwo.saved.dialog'
import DirectDealStepTwoSavedErrorDialog from './Dialogs/DirectDealStepTwo.saved.error.dialog'

import DirectDealStepTwoAddress from './DirectDealStepTwo.address'
import DirectDealStepTwoAttachments from './DirectDealStepTwo.attachments'
import DirectDealStepTwoButtons from './DirectDealStepTwo.footer'
import DirectDealStepTwoClosingDate from './DirectDealStepTwo.dateclose'
import DirectDealStepTwoGeneralInformation from './DirectDealStepTwo.information'
import DirectDealStepTwoProducts from './Products/DirectDealStepTwo.products'
import DirectDealStepTwoProvider from './DirectDealStepTwo.provider'
import DirectDealStepTwoQuotes from './Quotes/DirectDealStepTwo.quotes'
import DirectDealStepTwoResume from './DirectDealStepTwo.resume'
import DirectDealStepTwoTaxes from './DirectDealStepTwo.taxes'

import {
  onCloseAbilityDirectDealStepTwo,
  onCloseConfirmStepTwoDialog,
  onCloseSavedDirectDealStepTwo,
  onCloseSavedErrorDirectDealStepTwo,
  onGetAllCurrienciesDirectDealStepTwoThunk,
  onGetCausesStepTwoThunk,
  onGetCitiesThunk,
  onGetClosingDateThunk,
  onGetContractDurationSearchThunk,
  onGetContractTypeSearchThunk,
  onGetRegionsThunk,
  onGetSavedDirectDealThunk,
  onGetTaxesSearchThunk,
  onGetUnitMeasurementStepTwoThunk,
  onOpenAbilityDirectDealStepTwo,
  onOpenConfirmStepTwoDialog,
  onOpenSavedDirectDealStepTwo,
  onOpenSavedErrorDirectDealStepTwo,
  onPublishDirectDealThunk,
  onResetDirectDealStepTwo,
  onSavedInfoStepOneDirectDealStepTwo,
  onSaveDirectDealThunk,
  onStorageDirectDealStepTwo
} from './DirectDealStepTwo.actions'

import { onShowSnackbar } from '../SnackBarProvider/SnackBarProvider.actions'
import { onSavedInfoDirectDealVoucher } from '../DirectDealVoucher/DirectDealVoucher.actions'
import { onRemoveAllSuccess, onInitialSavedProductsDirectDealProducts } from '../ProductSearch/ProductSearch.actions'
import { onResetDirectDealQuote, onInitialSavedValuesDirectDealQuote } from './Quotes/DirectDealQuote.actions'

import { Divider, DivSection, Root } from './DirectDealStepTwo.styles'

import { useFormRef } from '../../modules/hooks/useFormRef'
import { dialogScrollTop } from '../../modules/utils/dialogScrollTop'
import { formatCurrencyToNumber } from '../../modules/utils/formatters'

import { STATUS_ABILITY_PROVIDER_DICTIONARY, CURRENCY_TRANSLATION } from '../../config/settings/constants'
import routes from '../../config/settings/routes'
import { REACT_APP_PROVIDERS_URL } from '../../config/settings/environment'

/**
 * The DirectDealStepTwo's container.
 */
const DirectDealStepTwoContainer = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  const formStepTwoRef = useFormRef()
  const directDealId = usePathParam('directDealId')

  const sectionAddressRef = useRef(null)
  const sectionAttachmentRef = useRef(null)
  const sectionDateCloseRef = useRef(null)
  const sectionGeneralInformationRef = useRef(null)
  const sectionProductsRef = useRef(null)
  const sectionProviderRef = useRef(null)
  const sectionTaxRef = useRef(null)
  const sectionQuoteRef = useRef(null)

  const {
    causes: directDealStepTwoCauses,
    cities: directDealStepTwoCities,
    contractDuration: directDealStepTwoContractDuration,
    contractType: directDealStepTwoContracts,
    currencies: directDealStepTwoCurrencies,
    closingDate: direcrDealStepTwoClosingDate,
    dialog: directDealStepTwoDialog,
    loadings: directDealStepTwoLoadings,
    provider: directDealStepTwoProvider,
    regions: region,
    sheetStorage: directDealStorageSheet,
    stepOne: directDealStepOneSheet,
    taxes: directDealStepTwoTaxes,
    unitMeasurement: directDealStepTwoUnitMeasurement
  } = useSelector(state => state.directDealStepTwo)

  const { productsSaved: directDealProducts } = useSelector(state => state.productSearch)
  const {
    quote: directDealQuoteSelected,
    quotes: directDealQuotes,
    loading: directDealQuoteLoading,
    idQuoteEdit: directDealIdQuoteSelected,
    dialog: directDealQuoteDialog
  } = useSelector(state => state.directDealQuote)

  const [currencySelected, setCurrencySelected] = useState(null)
  const [isInvalidProductsSelected, setInvalidProductsSelected] = useState(false)

  const isInvalidSectionGeneralInformation = useMemo(() => {
    return (
      formStepTwoRef.current.coin?.error !== '' ||
      formStepTwoRef.current.coin?.value === '' ||
      formStepTwoRef.current.contractCategory?.value === '' ||
      formStepTwoRef.current.contractType?.error !== '' ||
      formStepTwoRef.current.contractType?.value === '' ||
      formStepTwoRef.current.dateFinishExecute?.error !== '' ||
      formStepTwoRef.current.dateFinishExecute?.value === '' ||
      formStepTwoRef.current.description?.error !== '' ||
      formStepTwoRef.current.description?.value === '' ||
      formStepTwoRef.current.duration?.error !== '' ||
      formStepTwoRef.current.duration?.value === '' ||
      formStepTwoRef.current.justification?.error !== '' ||
      formStepTwoRef.current.justification?.value === '' ||
      formStepTwoRef.current.nameSheet?.error !== '' ||
      formStepTwoRef.current.nameSheet?.value === ''
    )
  }, [
    formStepTwoRef.current.coin?.error,
    formStepTwoRef.current.coin?.value,
    formStepTwoRef.current.contractCategory?.value,
    formStepTwoRef.current.contractType?.error,
    formStepTwoRef.current.contractType?.value,
    formStepTwoRef.current.dateFinishExecute?.error,
    formStepTwoRef.current.dateFinishExecute?.value,
    formStepTwoRef.current.description?.error,
    formStepTwoRef.current.description?.value,
    formStepTwoRef.current.justification?.error,
    formStepTwoRef.current.justification?.value,
    formStepTwoRef.current.nameSheet?.error,
    formStepTwoRef.current.nameSheet?.value
  ])
  const isNotAllowSaveSectionGeneralInformation = useMemo(() => {
    return (
      (formStepTwoRef.current.coin?.error !== '' && formStepTwoRef.current.coin?.value !== '') ||
      (formStepTwoRef.current.contractType?.value !== '' && formStepTwoRef.current.contractType.error !== '') ||
      (formStepTwoRef.current.dateFinishExecute?.value !== '' &&
        formStepTwoRef.current.dateFinishExecute?.error !== '') ||
      (formStepTwoRef.current.description?.value !== '' && formStepTwoRef.current.description?.error !== '') ||
      (formStepTwoRef.current.duration?.value !== '' && formStepTwoRef.current.duration?.error !== '') ||
      (formStepTwoRef.current.justification?.value !== '' && formStepTwoRef.current.justification?.error !== '') ||
      (formStepTwoRef.current.nameSheet?.value !== '' && formStepTwoRef.current.nameSheet?.error !== '')
    )
  }, [
    formStepTwoRef.current.coin?.error,
    formStepTwoRef.current.coin?.value,
    formStepTwoRef.current.contractCategory?.value,
    formStepTwoRef.current.contractType?.error,
    formStepTwoRef.current.contractType?.value,
    formStepTwoRef.current.dateFinishExecute?.error,
    formStepTwoRef.current.dateFinishExecute?.value,
    formStepTwoRef.current.description?.error,
    formStepTwoRef.current.description?.value,
    formStepTwoRef.current.justification?.error,
    formStepTwoRef.current.justification?.value,
    formStepTwoRef.current.nameSheet?.error,
    formStepTwoRef.current.nameSheet?.value
  ])

  const isInvalidSectionDateClose = useMemo(() => {
    return (
      directDealStepOneSheet?.withPublicity &&
      (formStepTwoRef.current.dateFromClose?.error !== '' ||
        formStepTwoRef.current.dateFromClose?.value === '' ||
        formStepTwoRef.current.hourDateClose?.error !== '' ||
        formStepTwoRef.current.hourDateClose?.value === '')
    )
  }, [
    formStepTwoRef.current.dateFromClose?.error,
    formStepTwoRef.current.dateFromClose?.value,
    formStepTwoRef.current.hourDateClose?.error,
    formStepTwoRef.current.hourDateClose?.value
  ])
  const isNotAllowSaveSectionDateClose = useMemo(() => {
    return (
      directDealStepOneSheet?.withPublicity &&
      ((formStepTwoRef.current.dateFromClose?.error !== '' && formStepTwoRef.current.dateFromClose?.value !== '') ||
        (formStepTwoRef.current.hourDateClose?.error !== '' && formStepTwoRef.current.hourDateClose?.value !== ''))
    )
  }, [
    formStepTwoRef.current.dateFromClose?.error,
    formStepTwoRef.current.dateFromClose?.value,
    formStepTwoRef.current.hourDateClose?.error,
    formStepTwoRef.current.hourDateClose?.value
  ])

  const isInvalidSectionProvider = useMemo(() => {
    return formStepTwoRef.current.provider?.error !== '' || formStepTwoRef.current.provider?.value === ''
  }, [formStepTwoRef.current.provider?.error, formStepTwoRef.current.provider?.value])
  const isNotAllowSaveSectionProvider = useMemo(() => {
    return formStepTwoRef.current.provider?.value !== '' && formStepTwoRef.current.provider?.error !== ''
  }, [formStepTwoRef.current.provider?.error, formStepTwoRef.current.provider?.value])

  const isInvalidSectionProducts = useMemo(() => {
    return directDealProducts.length <= 0 || formStepTwoRef.current.netTotal <= 0 || isInvalidProductsSelected
  }, [directDealProducts, formStepTwoRef.current.netTotal, isInvalidProductsSelected])

  const isInvalidSectionAddress = useMemo(() => {
    return (
      formStepTwoRef.current.address?.error !== '' ||
      formStepTwoRef.current.address?.value === '' ||
      formStepTwoRef.current.city?.value === '' ||
      formStepTwoRef.current.city?.error !== '' ||
      formStepTwoRef.current.commentary?.error !== '' ||
      formStepTwoRef.current.region?.value === '' ||
      formStepTwoRef.current.region?.error !== ''
    )
  }, [
    formStepTwoRef.current.address?.error,
    formStepTwoRef.current.address?.value,
    formStepTwoRef.current.city?.value,
    formStepTwoRef.current.city?.error,
    formStepTwoRef.current.commentary?.error,
    formStepTwoRef.current.region?.value,
    formStepTwoRef.current.region?.error
  ])
  const isNotAllowSaveSectionAddress = useMemo(() => {
    return (
      (formStepTwoRef.current.address?.error !== '' && formStepTwoRef.current.address?.value !== '') ||
      (formStepTwoRef.current.city?.error !== '' && formStepTwoRef.current.city?.value !== '') ||
      formStepTwoRef.current.commentary?.error !== '' ||
      (formStepTwoRef.current.region?.value !== '' && formStepTwoRef.current.region?.error !== '')
    )
  }, [
    formStepTwoRef.current.address?.error,
    formStepTwoRef.current.address?.value,
    formStepTwoRef.current.city?.value,
    formStepTwoRef.current.city?.error,
    formStepTwoRef.current.commentary?.error,
    formStepTwoRef.current.region?.value,
    formStepTwoRef.current.region?.error
  ])

  const isInvalidSectionTax = useMemo(() => {
    return (
      formStepTwoRef.current.charge?.error !== '' ||
      formStepTwoRef.current.charge?.value === '' ||
      formStepTwoRef.current.delivery?.error !== '' ||
      formStepTwoRef.current.delivery?.value === '' ||
      formStepTwoRef.current.discount?.error !== '' ||
      formStepTwoRef.current.discount?.value === '' ||
      formStepTwoRef.current.observationCharge?.error !== '' ||
      formStepTwoRef.current.observationDiscount?.error !== '' ||
      formStepTwoRef.current.taxes?.error !== '' ||
      formStepTwoRef.current.taxes?.value === ''
    )
  }, [
    formStepTwoRef.current.charge?.error,
    formStepTwoRef.current.charge?.value,
    formStepTwoRef.current.delivery?.error,
    formStepTwoRef.current.delivery?.value,
    formStepTwoRef.current.discount?.error,
    formStepTwoRef.current.discount?.value,
    formStepTwoRef.current.observationCharge?.error,
    formStepTwoRef.current.observationDiscount?.error,
    formStepTwoRef.current.taxes?.error,
    formStepTwoRef.current.taxes?.value
  ])
  const isNotAllowSaveSectionTax = useMemo(() => {
    return (
      (formStepTwoRef.current.coin?.value && formStepTwoRef.current.charge?.error !== '') ||
      (formStepTwoRef.current.coin?.value && formStepTwoRef.current.delivery?.error !== '') ||
      (formStepTwoRef.current.coin?.value && formStepTwoRef.current.discount?.error !== '') ||
      (formStepTwoRef.current.coin?.value && formStepTwoRef.current.observationCharge?.error !== '') ||
      (formStepTwoRef.current.coin?.value && formStepTwoRef.current.observationDiscount?.error !== '') ||
      (formStepTwoRef.current.coin?.value && formStepTwoRef.current.taxes?.error !== '')
    )
  }, [
    formStepTwoRef.current.charge?.error,
    formStepTwoRef.current.charge?.value,
    formStepTwoRef.current.delivery?.error,
    formStepTwoRef.current.delivery?.value,
    formStepTwoRef.current.discount?.error,
    formStepTwoRef.current.discount?.value,
    formStepTwoRef.current.observationCharge?.error,
    formStepTwoRef.current.observationDiscount?.error,
    formStepTwoRef.current.taxes?.error,
    formStepTwoRef.current.taxes?.value,
    formStepTwoRef.current.coin?.value
  ])

  const generateDirectDealModel = form => {
    return {
      id: directDealStepOneSheet?.id,
      code: directDealStepOneSheet?.code,
      contractDuration:
        directDealStepTwoContractDuration.find(duration => duration.value === form.duration?.value)?.id || null,
      contractType: form.contractType?.value,
      dispatchRegion: form.region?.value,
      dispatchCity: form.city?.value,
      dispatchAddress: form.address?.value,
      dispatchComment: form.commentary?.value,
      dispatchAmountDelivery: formatCurrencyToNumber(form.delivery?.value, form.coin?.value),
      dispatchAmountDiscount: formatCurrencyToNumber(form.discount?.value, form.coin?.value),
      dispatchObsDiscount: form.observationDiscount?.value,
      dispatchAmountCharge: formatCurrencyToNumber(form.charge?.value, form.coin?.value),
      dispatchObsCharge: form.observationCharge?.value,
      dispatchTax: form.taxes?.value,
      sheetJustification: form.justification?.value,
      sheetName: form.nameSheet?.value,
      sheetDescription: form.description?.value,

      dateExecute: form.dateFinishExecute?.value && form.dateFinishExecute?.value.toISOString(),
      dateClose: form.dateFromClose?.value && form.dateFromClose?.value.toISOString(),
      hourClose: form.hourDateClose?.value && form.hourDateClose?.value.toISOString(),
      providerDni: form.provider?.value,
      sheetCurrency: form.coin?.value,

      products: directDealProducts.map(product => {
        return {
          ...product,
          amount: formatCurrencyToNumber(product.amount, form.coin?.value),
          unitPrice: formatCurrencyToNumber(product.unitPrice, form.coin?.value),
          charge: formatCurrencyToNumber(product.charge, form.coin?.value),
          discount: formatCurrencyToNumber(product.discount, form.coin?.value),
          nameUnitMeasure: directDealStepTwoUnitMeasurement.find(unit => unit.code === product.unitMeasure)?.name || ''
        }
      }),
      total: form.total,
      totalClp: convertClpToTotalSheet(),
      subTotal: formStepTwoRef.current.netTotal || 0
    }
  }
  const handleSave = () => {
    const sheetStepTwo = generateDirectDealModel(formStepTwoRef.current)

    if (isNotAllowSaveSectionGeneralInformation) {
      formStepTwoRef.current.coin?.onError()
      formStepTwoRef.current.contractType?.onError()
      formStepTwoRef.current.dateFinishExecute?.onError()
      formStepTwoRef.current.description?.onError()
      formStepTwoRef.current.duration?.onError()
      formStepTwoRef.current.justification?.onError()
      formStepTwoRef.current.nameSheet?.onError()

      sectionGeneralInformationRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      return
    }

    if (isNotAllowSaveSectionDateClose) {
      formStepTwoRef.current.dateFromClose?.onError()
      formStepTwoRef.current.hourDateClose?.onError()

      sectionDateCloseRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      return
    }

    if (isNotAllowSaveSectionProvider) {
      formStepTwoRef.current.provider?.onError()

      sectionProviderRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      return
    }

    if (isNotAllowSaveSectionAddress) {
      formStepTwoRef.current.address?.onError()
      formStepTwoRef.current.city?.onError()
      formStepTwoRef.current.region?.onError()
      formStepTwoRef.current.commentary?.onError()

      sectionAddressRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      return
    }

    if (isNotAllowSaveSectionTax) {
      formStepTwoRef.current.taxes?.onError()
      formStepTwoRef.current.charge?.onError()
      formStepTwoRef.current.discount?.onError()
      formStepTwoRef.current.observationCharge?.onError()
      formStepTwoRef.current.observationDiscount?.onError()
      formStepTwoRef.current.delivery?.onError()

      sectionTaxRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      return
    }

    dispatch(
      onSaveDirectDealThunk({
        sheetStepTwo,
        callback: () => {
          dispatch(onOpenSavedDirectDealStepTwo())
          handleStorageForm()
          dialogScrollTop()
        },
        callbackError: () => {
          dispatch(onOpenSavedErrorDirectDealStepTwo())
          handleStorageForm()
          dialogScrollTop()
        }
      })
    )
  }
  const handlePublish = () => {
    const sheetStepTwo = generateDirectDealModel(formStepTwoRef.current)

    dispatch(
      onPublishDirectDealThunk({
        sheetStepTwo,
        callback: porId => {
          batch(() => {
            dispatch(
              onSavedInfoDirectDealVoucher({
                voucher: {
                  id: directDealStepOneSheet?.id,
                  code: directDealStepOneSheet?.code,
                  name: formStepTwoRef.current.nameSheet?.value,
                  cause: directDealStepOneSheet?.cause,
                  total: formStepTwoRef.current.total,
                  currency: currencySelected,
                  providerDni: formStepTwoRef.current.provider?.value,
                  providerLegalName: directDealStepTwoProvider?.businessName,
                  withPublicity: directDealStepOneSheet?.withPublicity,
                  dateClosed: formStepTwoRef.current.dateFromClose?.value,
                  hourClosed: formStepTwoRef.current.hourDateClose?.value,
                  porId
                }
              })
            )
            dispatch(onResetDirectDealStepTwo())
            dispatch(onResetDirectDealQuote())
            dispatch(onRemoveAllSuccess())
            navigate({ pathname: routes.directDealVoucher }, { replace: true })
          })
        },
        callbackError: ({ err }) => {
          dispatch(
            onShowSnackbar({
              title: err.descripcion,
              message: 'Te pedimos que lo vuelvas a intentar dentro de unos minutos.',
              severity: 'error'
            })
          )
          handleStorageForm()
          dialogScrollTop()
        }
      })
    )
  }
  const handleBack = () => {
    const query = getCookie('search')
    navigate({ pathname: routes.search, search: query.toString() }, { replace: true })

    batch(() => {
      dispatch(onCloseSavedDirectDealStepTwo())
      dispatch(onResetDirectDealStepTwo())
      dispatch(onResetDirectDealQuote())
      dispatch(onRemoveAllSuccess())
    })
  }
  const handleStorageForm = () => {
    dispatch(
      onStorageDirectDealStepTwo({
        sheet: {
          address: formStepTwoRef.current.address?.value,
          chargeValue: formStepTwoRef.current.charge?.value,
          city: formStepTwoRef.current.city?.value,
          code: directDealStepOneSheet?.code,
          coin: formStepTwoRef.current.coin?.value,
          comment: formStepTwoRef.current.commentary?.value,
          contractCategory: formStepTwoRef.current.contractCategory?.value,
          contractType: formStepTwoRef.current.contractType?.value,
          dateFinishExecute: formStepTwoRef.current.dateFinishExecute?.value,
          dateFromClose: formStepTwoRef.current.dateFromClose?.value,
          delivery: formStepTwoRef.current.delivery?.value,
          description: formStepTwoRef.current.description?.value,
          discount: formStepTwoRef.current.discount?.value,
          duration: formStepTwoRef.current.duration?.value,
          hourDateCLose: formStepTwoRef.current.hourDateClose?.value,
          justification: formStepTwoRef.current.justification?.value,
          nameSheet: formStepTwoRef.current.nameSheet?.value,
          observationCharge: formStepTwoRef.current.observationCharge?.value,
          observationDiscount: formStepTwoRef.current.observationDiscount?.value,
          providerDni: formStepTwoRef.current.provider?.value,
          region: formStepTwoRef.current.region?.value,
          tax: formStepTwoRef.current.taxes?.value,
          total: formStepTwoRef.current.total
        }
      })
    )
  }
  const handleCloseSavedDialog = () => {
    dispatch(onCloseSavedDirectDealStepTwo())
  }
  const handleCloseSavedErrorDialog = () => {
    dispatch(onCloseSavedErrorDirectDealStepTwo())
  }
  const handleOpenAbilityDialog = () => {
    dispatch(onOpenAbilityDirectDealStepTwo())
    dialogScrollTop()
  }
  const handleCloseAbilityDialog = () => {
    dispatch(onCloseAbilityDirectDealStepTwo())
  }
  const handleOpenConfirmDialog = () => {
    if (isInvalidSectionGeneralInformation) {
      formStepTwoRef.current.coin?.onError()
      formStepTwoRef.current.contractType?.onError()
      formStepTwoRef.current.dateFinishExecute?.onError()
      formStepTwoRef.current.description?.onError()
      formStepTwoRef.current.duration?.onError()
      formStepTwoRef.current.justification?.onError()
      formStepTwoRef.current.nameSheet?.onError()

      sectionGeneralInformationRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      return
    }

    if (isInvalidSectionDateClose) {
      formStepTwoRef.current.dateFromClose?.onError()
      formStepTwoRef.current.hourDateClose?.onError()

      sectionDateCloseRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      return
    }

    if (isInvalidSectionProvider) {
      formStepTwoRef.current.provider?.onError()

      sectionProviderRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      return
    }

    if (directDealStepTwoProvider?.skillState !== STATUS_ABILITY_PROVIDER_DICTIONARY.ABILITY) {
      handleOpenAbilityDialog()
      return
    }

    if (isInvalidSectionProducts) {
      sectionProductsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      return
    }

    if (isInvalidSectionAddress) {
      formStepTwoRef.current.address?.onError()
      formStepTwoRef.current.city?.onError()
      formStepTwoRef.current.region?.onError()
      formStepTwoRef.current.commentary?.onError()

      sectionAddressRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      return
    }

    if (isInvalidSectionTax) {
      formStepTwoRef.current.taxes?.onError()
      formStepTwoRef.current.charge?.onError()
      formStepTwoRef.current.discount?.onError()
      formStepTwoRef.current.observationCharge?.onError()
      formStepTwoRef.current.observationDiscount?.onError()
      formStepTwoRef.current.delivery?.onError()

      sectionTaxRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      return
    }

    if (directDealStepOneSheet?.withPublicity) {
      dispatch(onOpenConfirmStepTwoDialog())
    } else {
      handlePublish()
    }

    dialogScrollTop()
  }
  const handleCloseConfirmDialog = () => {
    dispatch(onCloseConfirmStepTwoDialog())
  }
  const handleRedirectProviderSheet = () => {
    window.open(`${REACT_APP_PROVIDERS_URL}/ficha/${formStepTwoRef.current.provider?.value}`, '_blank')
  }
  const handleProductDependencyError = () => {
    formStepTwoRef.current.coin?.onError()
    sectionGeneralInformationRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }
  const handleChangeAccordion = isInvalidProducts => setInvalidProductsSelected(isInvalidProducts)
  const convertClpToTotalSheet = () => {
    const currencySheet = formStepTwoRef.current.coin?.value || ''
    const totalSheet = formStepTwoRef.current.total || 0

    if (currencySheet) {
      if (currencySheet !== CURRENCY_TRANSLATION.CLP.cod) {
        const valueClp =
          directDealStepTwoCurrencies.find(currency => currency.type === formStepTwoRef.current.coin?.value)
            ?.valueClp || 0

        return parseInt(totalSheet * valueClp)
      }

      return parseInt(totalSheet)
    }

    return 0
  }
  const scrolledToSectionProducts = () => {
    if (location && location.state && location.state.from && location.state.from === routes.productSearch) {
      requestAnimationFrame(() => {
        sectionProductsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
      })
    }
  }

  const loadSheetSaved = () => {
    if (directDealId && location && location.state && location.state.from && location.state.from === routes.search) {
      dispatch(
        onGetSavedDirectDealThunk({
          directDealId,
          callback: response => {
            dispatch(onInitialSavedValuesDirectDealQuote(response.pricing))
            dispatch(onInitialSavedProductsDirectDealProducts(response.products))
            dispatch(onGetCitiesThunk(response.region))
            dispatch(onSavedInfoStepOneDirectDealStepTwo({ sheet: response.stepOne }))
          }
        })
      )
    }
  }

  useEffectOnce(() => {
    batch(() => {
      loadSheetSaved()
      scrolledToSectionProducts()

      dispatch(onCloseConfirmStepTwoDialog())
      dispatch(onCloseAbilityDirectDealStepTwo())
      dispatch(onCloseSavedDirectDealStepTwo())
      dispatch(onCloseSavedErrorDirectDealStepTwo())

      dispatch(onGetRegionsThunk())
      dispatch(onGetContractTypeSearchThunk())
      dispatch(onGetContractDurationSearchThunk())
      dispatch(onGetClosingDateThunk({ closingDate: new Date().toLocaleDateString().replace(/\//g, '-') }))
      dispatch(onGetTaxesSearchThunk())
      dispatch(onGetUnitMeasurementStepTwoThunk())
      dispatch(onGetAllCurrienciesDirectDealStepTwoThunk())
      dispatch(onGetCausesStepTwoThunk())
    })
  })

  useEffect(() => {
    if (directDealStepTwoCurrencies) {
      setCurrencySelected(
        directDealStepTwoCurrencies.find(currency => currency.value === formStepTwoRef.current.coin?.value)
      )
    }
  }, [formStepTwoRef.current.coin?.value])

  return (
    <Root>
      <Divider />
      <DivSection ref={sectionGeneralInformationRef}>
        <DirectDealStepTwoGeneralInformation
          cause={directDealStepOneSheet?.cause}
          contractDurations={directDealStepTwoContractDuration}
          contracts={directDealStepTwoContracts}
          currencies={directDealStepTwoCurrencies}
          formStepTwoRef={formStepTwoRef}
          loading={directDealStepTwoLoadings.main}
          storage={directDealStorageSheet}
        />
      </DivSection>
      <Divider />
      <DivSection ref={sectionAttachmentRef}>
        <DirectDealStepTwoAttachments
          causes={directDealStepTwoCauses}
          loading={directDealStepTwoLoadings.main}
          sheet={directDealStepOneSheet}
          storage={directDealStorageSheet}
        />
      </DivSection>

      {directDealStepOneSheet?.withPublicity && (
        <>
          <Divider />
          <DivSection ref={sectionDateCloseRef}>
            <DirectDealStepTwoClosingDate
              closeDate={direcrDealStepTwoClosingDate}
              formStepTwoRef={formStepTwoRef}
              loading={directDealStepTwoLoadings.main}
              storage={directDealStorageSheet}
            />
          </DivSection>
        </>
      )}
      <Divider />
      <DivSection ref={sectionProviderRef}>
        <DirectDealStepTwoProvider
          formStepTwoRef={formStepTwoRef}
          loading={directDealStepTwoLoadings.provider}
          provider={directDealStepTwoProvider}
          storage={directDealStorageSheet}
          onOpenDialog={handleOpenAbilityDialog}
          onRedirect={handleRedirectProviderSheet}
        />
      </DivSection>
      <Divider />
      <DivSection ref={sectionQuoteRef}>
        <DirectDealStepTwoQuotes
          code={directDealStepOneSheet?.code}
          dialog={directDealQuoteDialog}
          loading={directDealQuoteLoading}
          onAdd={handleStorageForm}
          quote={directDealQuoteSelected}
          quoteKey={directDealIdQuoteSelected}
          quotes={directDealQuotes}
        />
      </DivSection>
      <Divider />
      <DivSection ref={sectionProductsRef}>
        <DirectDealStepTwoProducts
          code={directDealStepOneSheet?.code}
          currency={currencySelected}
          formStepTwoRef={formStepTwoRef}
          loading={directDealStepTwoLoadings.main}
          products={directDealProducts}
          unitMeasurement={directDealStepTwoUnitMeasurement}
          onChangeAccordion={handleChangeAccordion}
          onDependencyError={handleProductDependencyError}
          onStorage={handleStorageForm}
          storage={directDealStorageSheet}
        />
      </DivSection>
      <Divider />
      <DivSection ref={sectionAddressRef}>
        <DirectDealStepTwoAddress
          cities={directDealStepTwoCities}
          formStepTwoRef={formStepTwoRef}
          loading={directDealStepTwoLoadings.regions}
          region={region}
          storage={directDealStorageSheet}
        />
      </DivSection>
      {formStepTwoRef.current.coin?.value && (
        <>
          <Divider />
          <DivSection ref={sectionTaxRef}>
            <DirectDealStepTwoTaxes
              currency={currencySelected}
              formStepTwoRef={formStepTwoRef}
              loading={directDealStepTwoLoadings.main}
              storage={directDealStorageSheet}
              taxes={directDealStepTwoTaxes}
            />
          </DivSection>
          <Divider />
          <DirectDealStepTwoResume
            currency={currencySelected}
            currencies={directDealStepTwoCurrencies}
            formStepTwoRef={formStepTwoRef}
            loading={directDealStepTwoLoadings.main}
            taxes={directDealStepTwoTaxes}
          />
        </>
      )}
      <Divider />
      <DirectDealStepTwoButtons
        loading={directDealStepTwoLoadings.main}
        onContinue={handleOpenConfirmDialog}
        onSave={handleSave}
        onBack={handleBack}
      />

      <DirectDealStepTwoSavedDialog open={directDealStepTwoDialog?.openSaved} onClose={handleCloseSavedDialog} />
      <DirectDealStepTwoAbilityDialog
        open={directDealStepTwoDialog?.openAbility}
        onClose={handleCloseAbilityDialog}
        onRedirect={handleRedirectProviderSheet}
      />
      <DirectDealStepTwoConfirmDialog
        loading={directDealStepTwoLoadings.main}
        open={directDealStepTwoDialog?.openConfirmation}
        onCreate={handlePublish}
        onClose={handleCloseConfirmDialog}
      />
      <DirectDealStepTwoSavedErrorDialog
        open={directDealStepTwoDialog?.openSavedError}
        onClose={handleCloseSavedErrorDialog}
      />
    </Root>
  )
}
export default DirectDealStepTwoContainer
