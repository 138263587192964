import React from 'react'
import PropTypes from 'prop-types'

import { Card, Skeleton, Typography } from '@chilecompra/react-kit/components'
import { useViewport } from '@chilecompra/react-kit'

import FormattedDateComponent from '../../components/components/FormattedDate.component'
import FormattedCurrencyComponent from '../../components/components/FormattedCurrency.component'
import AttachmentsWrapper from '../../components/components/AttachmentsWrapper.component'

import { CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL, CURRENCY_TRANSLATION } from '../../config/settings/constants'
import { REACT_APP_LAWSUIT_BASE_URL } from '../../config/settings/environment'

import { Divider, Grid, Link } from './DirectDealSummary.styles'

/**
 * The DirectDealSummary's details.
 */
const DirectDealSummaryDetail = props => {
  const { loading, sheet, onRedirect, lawsuitAmount } = props
  const { size } = useViewport()

  return (
    <Grid container spacing={2} margin="0">
      <Grid item md={8} xs={12}>
        <Card enableBorder lineColor="primary" color="blank" padding="32px 24px 0">
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 0'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontWeight="bold" variant="body2">
                Nombre
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && (
                <Typography color="black1" variant="body1">
                  {sheet?.name}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 0'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontWeight="bold" variant="body2">
                Descripción
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && (
                <Typography color="black1" variant="body1">
                  {sheet?.description}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 0'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontWeight="bold" variant="body2" width="60%">
                Causal de Trato Directo
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && (
                <Typography color="black1" variant="body1">
                  {sheet?.cause}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 0'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontWeight="bold" variant="body2" width="70%">
                Justificación del Trato Directo
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && (
                <Typography color="black1" variant="body1">
                  {sheet?.justification}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 0'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontWeight="bold" variant="body2">
                Fecha de publicación
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && (
                <Typography color="black1" variant="body1">
                  <FormattedDateComponent date={sheet?.datePublish} includeHours />
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 0'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontWeight="bold" variant="body2">
                Fecha de cierre
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && (
                <Typography color="black1" variant="body1">
                  <FormattedDateComponent date={sheet?.dateClosed} includeHours />
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 0'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontWeight="bold" variant="body2">
                Monto total
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && sheet?.totalAmount && (
                <Typography color="black1" variant="body1">
                  <FormattedCurrencyComponent
                    amount={
                      sheet?.currency === CURRENCY_TRANSLATION.CLP.cod
                        ? parseInt(sheet?.totalAmount || 0)
                        : sheet?.totalAmount || 0
                    }
                    currency={sheet?.currency || ''}
                    includeSymbol
                  />
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 0'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontWeight="bold" variant="body2">
                Moneda
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && sheet?.currency && (
                <Typography color="black1" variant="body1">
                  {CURRENCY_TRANSLATION[sheet?.currency]?.name} ({CURRENCY_TRANSLATION[sheet?.currency]?.cod})
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 3px 0'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontWeight="bold" variant="body2">
                Proveedor
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && (
                <>
                  <Link fontSize="16px" href="#" onClick={onRedirect}>
                    {sheet?.providerName}
                  </Link>
                  <Typography color="black3" fontSize="16px">
                    {sheet?.providerDni}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 0'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontWeight="bold" variant="body2">
                Duración del contrato
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && (
                <Typography color="black1" variant="body1">
                  {sheet?.contractDuration}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 0'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontWeight="bold" variant="body2" width="70%">
                Fecha de término de ejecución
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && (
                <Typography color="black1" variant="body1">
                  <FormattedDateComponent date={sheet?.contractDateFinish} />
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 0'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontWeight="bold" variant="body2">
                Categoría del contrato
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && (
                <Typography color="black1" variant="body1">
                  {sheet?.contractCategory}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 0'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontWeight="bold" variant="body2">
                Tipo de contrato
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && (
                <Typography color="black1" variant="body1">
                  {sheet?.contractType}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 0'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontWeight="bold" variant="body2" width="60%">
                Dirección de entrega o ejecución del servicio
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {loading && <Skeleton width="70%" />}
              {!loading && (
                <>
                  <Typography color="black1" variant="body1">
                    {sheet?.deliveryAddress}, {sheet?.deliveryCommune}, {sheet?.deliveryRegion}
                  </Typography>
                  {sheet?.deliveryComment && (
                    <Typography color="black1" variant="body1">
                      {sheet?.deliveryComment}
                    </Typography>
                  )}
                </>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 0'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontWeight="bold" variant="body2">
                Archivos adjuntos
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {!loading && sheet?.resolution?.subBusiness && sheet?.resolution?.idBusiness && (
                <AttachmentsWrapper
                  business={CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL}
                  subBusiness={sheet.resolution.subBusiness}
                  idBusiness={sheet.resolution.idBusiness}
                  isPrivate
                  canRead
                  color="blank"
                  showIconList={false}
                  typeView="list"
                />
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 0'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontWeight="bold" variant="body2">
                Informe Técnico
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {!loading && sheet?.report?.subBusiness && sheet?.report?.idBusiness && (
                <AttachmentsWrapper
                  business={CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL}
                  subBusiness={sheet.report.subBusiness}
                  idBusiness={sheet.report.idBusiness}
                  isPrivate
                  canRead
                  color="blank"
                  showIconList={false}
                  typeView="list"
                />
              )}
            </Grid>
          </Grid>
          <Divider />
          <Grid container padding={size.isDownToTiny ? '10px 0 10px 10px' : '16px 0 16px 0'}>
            <Grid item sm={3} xs={12}>
              <Typography color="black1" fontWeight="bold" variant="body2">
                Adjuntos opcionales
              </Typography>
            </Grid>
            <Grid item sm xs={12}>
              {!loading && sheet?.optional?.subBusiness && sheet?.optional?.idBusiness && (
                <AttachmentsWrapper
                  business={CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL}
                  subBusiness={sheet.optional.subBusiness}
                  idBusiness={sheet.optional.idBusiness}
                  isPrivate
                  canRead
                  color="blank"
                  showIconList={false}
                  typeView="list"
                />
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item md xs={12}>
        <Grid>
          <Card padding="24px 16px">
            <Grid container>
              <Grid item margin="0 0 4px">
                <Typography fontWeight="bold" color="black1" variant="body2">
                  {sheet?.buyerLegalName}
                </Typography>
              </Grid>
              <Grid item>
                <Typography color="black1" variant="body2">
                  {sheet?.buyerDni}
                </Typography>
                <Typography variant="body2" color="black3">
                  {sheet?.buyerName}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid padding="24px 0 0">
          <Card padding="24px 16px" color="blank" enableBorder>
            <Grid container spacing={1}>
              <Grid item>
                <Typography fontWeight="bold" color="black1" variant="body2">
                  Demandas ante el Tribunal de Contratación Pública
                </Typography>
              </Grid>
              {lawsuitAmount !== 0 && (
                <Grid item>
                  <Typography color="black1" variant="body2">
                    Este Trato Directo ha recibido {lawsuitAmount} demandas ante el{' '}
                    <Link href={REACT_APP_LAWSUIT_BASE_URL.concat(sheet?.code)} target="_blank" rel="noreferrer">
                      Tribunal de Contratación Pública.
                    </Link>
                  </Typography>
                </Grid>
              )}
              {lawsuitAmount === 0 && (
                <Grid item>
                  <Typography color="black1" variant="body2">
                    Este Trato Directo no ha recibido demandas ante el Tribunal de Contratación Pública.
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  )
}

DirectDealSummaryDetail.propTypes = {
  sheet: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    cause: PropTypes.string,
    justification: PropTypes.string,
    datePublish: PropTypes.string,
    dateClosed: PropTypes.string,
    totalAmount: PropTypes.number,
    currency: PropTypes.string,
    providerName: PropTypes.string,
    providerDni: PropTypes.string,
    contractDuration: PropTypes.string,
    contractDateFinish: PropTypes.string,
    contractCategory: PropTypes.string,
    contractType: PropTypes.string,
    deliveryAddress: PropTypes.string,
    deliveryRegion: PropTypes.string,
    deliveryCommune: PropTypes.string,
    deliveryComment: PropTypes.string,
    buyerName: PropTypes.string,
    buyerDni: PropTypes.string,
    buyerLegalName: PropTypes.string,
    resolution: {
      subBusiness: PropTypes.number,
      idBusiness: PropTypes.number
    },
    report: {
      subBusiness: PropTypes.number,
      idBusiness: PropTypes.number
    },
    optional: {
      subBusiness: PropTypes.number,
      idBusiness: PropTypes.number
    }
  }),
  loading: PropTypes.bool,
  onRedirect: PropTypes.func,
  lawsuitAmount: PropTypes.number
}

export default DirectDealSummaryDetail
