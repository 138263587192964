import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  CircleSuccessIcon,
  CircleErrorIcon,
  Dialog,
  Spinner,
  Typography
} from '@chilecompra/react-kit/components'
import { Card } from '@chilecompra/react-kit'

import { CircleWarningIcon, Grid, GridContainer, CautionInfo } from '../DirectDealSummary.styles'
import { DIRECT_DEAL_SUMMARY_ISSUE_PURCHASE_STEP_DICTIONARY } from '../../../config/settings/constants'

/**
 * The DirectDealStepSummary's issue dialog.
 */
const DirectDealSummaryIssueDialog = props => {
  const { issue, onClosed, onIssue, onRedirect, loading, providerName, providerDni } = props

  const handlerRedirect = id => {
    return () => onRedirect(id)
  }

  return (
    <Dialog open={issue?.open} disableBackdropClick verticalAlign="top" onClose={onClosed}>
      {issue?.step === DIRECT_DEAL_SUMMARY_ISSUE_PURCHASE_STEP_DICTIONARY.CONFIRMATION && (
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item textAlign="center" padding="13px">
            <CircleWarningIcon src="/assets/images/WarningCircleIcon.svg" alt="Circle Warning Icon" />
            <Typography fontSize="22px" lineHeight="22px" fontWeight="bold" color="black1" padding="16px 0 0">
              ¿Deseas generar la orden de compra?
            </Typography>
          </Grid>
          {!loading && (
            <>
              <Grid item margin="16px 0" textAlign="center">
                <Card color="blank" enableBorder>
                  <Typography fontSize="14px" lineHeight="18px" color="black1">
                    {providerName}
                  </Typography>
                  <Typography fontSize="14px" lineHeight="21px" color="black3">
                    {providerDni}
                  </Typography>
                </Card>
              </Grid>
              <Card color="warning">
                <GridContainer padding="0">
                  <CautionInfo />
                  <Typography variant="body2" color="black1" fontWeight="bold" padding="0 6px 0">
                    Importante
                  </Typography>
                </GridContainer>
                <GridContainer padding="0 24px 0">
                  <Typography variant="body2" color="black2">
                    Si has publicado una intención de Trato Directo y has decidido continuar con su generación, deberás
                    adjuntar el acto administrativo que autoriza dicho Trato Directo y aprueba el contrato respectivo en
                    la orden de compra.
                  </Typography>
                </GridContainer>
              </Card>

              <Grid item padding="42px 50px 0" margin="4px 0" textAlign="center">
                <Button aria-label="Generar orden de compra" color="success" variant="contained" onClick={onIssue}>
                  Generar orden de compra
                </Button>
              </Grid>
              <Grid item padding="0 50px" margin="4px 0" textAlign="center">
                <Button aria-label="Cancelar" variant="text" onClick={onClosed}>
                  Cancelar
                </Button>
              </Grid>
            </>
          )}

          {loading && (
            <Grid item textAlign="center" padding="25px 10px" margin="30px 0">
              <Spinner />
            </Grid>
          )}
        </Grid>
      )}
      {issue?.step === DIRECT_DEAL_SUMMARY_ISSUE_PURCHASE_STEP_DICTIONARY.SUCCESS && (
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item textAlign="center" padding="13px">
            <CircleSuccessIcon />
            <Typography fontSize="22px" lineHeight="22px" fontWeight="bold" color="black1">
              Orden de compra emitida
            </Typography>
          </Grid>
          <Grid item textAlign="center" padding="13px" margin="0 0 32px">
            <Typography fontSize="16px" lineHeight="21px" color="black1">
              Número de orden de compra
            </Typography>
            <Typography fontSize="27px" lineHeight="21px" color="success" fontWeight="bold">
              {issue?.purchaseCode}
            </Typography>
          </Grid>
          <Grid item padding="0 50px" margin="4px 0" textAlign="center">
            <Button
              aria-label="Ir a la orden de compra"
              color="success"
              variant="contained"
              onClick={handlerRedirect(issue?.purchaseId)}
            >
              Ir a la orden de compra
            </Button>
          </Grid>
          <Grid item padding="0 50px" margin="4px 0" textAlign="center">
            <Button aria-label="Volver a la ficha de Trato Directo" variant="text" onClick={onClosed}>
              Volver a la ficha de Trato Directo
            </Button>
          </Grid>
        </Grid>
      )}
      {issue?.step === DIRECT_DEAL_SUMMARY_ISSUE_PURCHASE_STEP_DICTIONARY.FAILURE && (
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item textAlign="center" padding="13px">
            <CircleErrorIcon />
            <Typography fontSize="22px" lineHeight="22px" fontWeight="bold" color="black1">
              Orden de compra no ha sido emitida
            </Typography>
          </Grid>
          <Grid item textAlign="center" padding="13px" margin="0 0 32px">
            <Typography fontSize="16px" lineHeight="21px" color="black1">
              Te pedimos que lo vuelvas a intentar dentro de unos minutos.
            </Typography>
          </Grid>
          <Grid item padding="0 50px" margin="4px 0" textAlign="center">
            <Button aria-label="Volver" variant="text" onClick={onClosed}>
              Volver a la ficha de Trato Directo
            </Button>
          </Grid>
        </Grid>
      )}
    </Dialog>
  )
}

DirectDealSummaryIssueDialog.propTypes = {
  issue: PropTypes.shape({
    open: PropTypes.bool,
    step: PropTypes.string,
    purchaseCode: PropTypes.string,
    purchaseId: PropTypes.string
  }),
  onClosed: PropTypes.func,
  onIssue: PropTypes.func,
  onRedirect: PropTypes.func,
  loading: PropTypes.bool,
  providerName: PropTypes.string,
  providerDni: PropTypes.string
}

export default DirectDealSummaryIssueDialog
