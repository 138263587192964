import {
  GET_ITEMS,
  GET_ORGANIZATIONS,
  GET_ORGANIZATIONS_ERROR,
  GET_ORGANIZATIONS_SUCCESS,
  GET_PROVIDERS,
  GET_PROVIDERS_ERROR,
  GET_PROVIDERS_SUCCESS,
  GET_ITEMS_ERROR,
  GET_ITEMS_SUCCESS,
  GET_REGIONS,
  GET_REGIONS_ERROR,
  GET_REGIONS_SUCCESS
} from './DirectDealFilter.actions'

const directDealFiltersState = {
  error: undefined,
  loading: false,
  providers: [],
  organizations: [],
  items: [],
  regions: []
}

/**
 * The DirectDealFilters reducer.
 */
const directDealFiltersReducer = (state = directDealFiltersState, { payload, type }) => {
  switch (type) {
    case GET_REGIONS:
    case GET_ITEMS:
    case GET_ORGANIZATIONS:
    case GET_PROVIDERS: {
      return { ...state, error: undefined, loading: true }
    }
    case GET_REGIONS_ERROR:
    case GET_ITEMS_ERROR:
    case GET_ORGANIZATIONS_ERROR:
    case GET_PROVIDERS_ERROR: {
      return { ...state, error: payload.error, loading: false }
    }
    case GET_PROVIDERS_SUCCESS: {
      return { ...state, error: undefined, loading: false, providers: payload.providers }
    }
    case GET_ORGANIZATIONS_SUCCESS: {
      return { ...state, error: undefined, loading: false, organizations: payload.organizations }
    }
    case GET_ITEMS_SUCCESS: {
      return { ...state, error: undefined, loading: false, items: payload.items }
    }
    case GET_REGIONS_SUCCESS: {
      return { ...state, error: undefined, loading: false, regions: payload.regions }
    }
    default: {
      return state
    }
  }
}

export default directDealFiltersReducer
