import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Autocomplete, CheckBox, CheckBoxSkeleton, Input, Select, Typography } from '@chilecompra/react-kit/components'
import {
  checkInputErrorMaxLength,
  checkInputErrorRegEx,
  putCookie,
  useAutocomplete,
  useCheckBox,
  useDatePicker,
  useSelect,
  useInput,
  useQuery,
  useViewport,
  useEffectOnce
} from '@chilecompra/react-kit'
import { format, parse } from 'date-fns'

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  ArrowButton,
  Button,
  DatePicker,
  ExpandMoreIcon,
  Grid,
  GridInline,
  GridItemInline,
  HiddenMd,
  HiddenSm,
  Hr
} from './DirectDealFilter.styles'

import {
  DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES,
  DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_BUYER,
  DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_SELLER,
  DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_PUBLIC,
  DIRECT_DEAL_SEARCH_ORDERS,
  CURRENCY_TRANSLATION
} from '../../config/settings/constants'

import { updateQuery } from '../../modules/utils/searches'
import { currencyFormat } from '../../config/store/utils'
import {
  ONLY_SIMBOLS_MONEY_CHECKED,
  REMOVED_THOUSANDS_DOT_CHECKED,
  ONLY_NUMBERS_POSITIVES_WITH_THOUSANDS_DOT_CLP_AND_SYMBOL
} from '../../modules/utils/regexes'

import useConditionalSearchNavigate from '../../modules/hooks/useCondicionalSearchNavigate'
import { useAuthorization } from '../AuthProvider/AuthProvider.hooks'

import {
  onGetProvidersThunk,
  onGetOrganizationsThunk,
  onGetItemsThunk,
  onGetRegionsThunk
} from './DirectDealFilter.actions'

/**
 * The DirectDealFilter container.
 */
const DirectDealFilter = () => {
  const dispatch = useDispatch()
  const query = useQuery()
  const {
    size: { isUpToLarge, isUpToMedium }
  } = useViewport()
  const { isBuyer, isSeller } = useAuthorization()
  const { conditionalNavigate } = useConditionalSearchNavigate(isSeller, isBuyer)

  const { loading: loadingSearch, causeCriteria: causeSelected, causes } = useSelector(state => state.searchFilters)
  const {
    loading: directDealFilterLoading,
    providers: byProvidersOptions,
    organizations: byOrganizationsOptions,
    items: byItemsOptions,
    regions: byRegionsOptions
  } = useSelector(state => state.directDealFilters)

  const [expanded, setExpanded] = useState(false)
  const [isDisabledBudgetEstimation, setDisabledBudgetEstimation] = useState(false)
  const [minBudget, setMinBudget] = useState(
    DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_SELLER.minBudget ||
      DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_PUBLIC.minBudget
  )
  const [maxBudget, setMaxBudget] = useState(
    DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_SELLER.maxBudget ||
      DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_PUBLIC.maxBudget
  )

  const {
    error: orderError,
    value: orderValue,
    onChange: handleOrderChange
  } = useSelect({
    initialValue: query.has('orderBy') ? query.get('orderBy') : DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES.orderBy,
    overwriteCallback: () =>
      query.has('orderBy') ? query.get('orderBy') : DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES.orderBy
  })
  const {
    error: dateFromError,
    value: dateFromValue,
    onChange: handleDateFromChange
  } = useDatePicker({
    initialValue: query.has('dateFrom')
      ? parse(query.get('dateFrom'), 'dd/MM/yyyy', new Date())
      : DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES.dateFrom,
    overwriteCallback: () =>
      query.has('dateFrom')
        ? parse(query.get('dateFrom'), 'dd/MM/yyyy', new Date())
        : DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES.dateFrom
  })
  const {
    error: dateToError,
    value: dateToValue,
    onChange: handleDateToChange
  } = useDatePicker({
    initialValue: query.has('dateTo')
      ? parse(query.get('dateTo'), 'dd/MM/yyyy', new Date())
      : DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES.dateTo,
    overwriteCallback: () =>
      query.has('dateTo')
        ? parse(query.get('dateTo'), 'dd/MM/yyyy', new Date())
        : DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES.dateTo
  })
  const {
    error: byProviderError,
    option: byProviderOption,
    value: byProviderValue,
    onChange: handleByProviderChange,
    onSelect: handleByProviderSelect
  } = useAutocomplete({
    changeCallback: updatedValue =>
      updatedValue?.length >= 3 && dispatch(onGetProvidersThunk({ pattern: updatedValue })),
    errorCallbacks: [checkInputErrorMaxLength(255)],
    initialOption: query.has('provider') ? { name: '', value: Number.parseInt(query.get('provider'), 10) } : null,
    initialValue: query.has('provider')
      ? query.get('provider')
      : DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_BUYER.provider ||
        DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_PUBLIC.provider,
    overwriteCallback: () =>
      query.has('provider')
        ? byProvidersOptions.find(option => option.value === Number.parseInt(query.get('provider'), 10))
        : null,
    selectCallback: updatedOption =>
      handleSearch({
        orderValue,
        dateFromValue,
        dateToValue,
        byProviderOption: updatedOption,
        byProviderValue,
        byOrganizationValue,
        byOrganizationOption,
        checkedReceivedAnswer,
        checkedSendAnswer,
        minBudget,
        maxBudget,
        regionValue,
        byItemOption,
        byItemValue
      })
  })
  const {
    error: byOrganizationError,
    option: byOrganizationOption,
    value: byOrganizationValue,
    onChange: handleByOrganizationChange,
    onSelect: handleByOrganizationSelect
  } = useAutocomplete({
    changeCallback: updatedValue =>
      updatedValue?.length >= 3 && dispatch(onGetOrganizationsThunk({ pattern: updatedValue })),
    errorCallbacks: [checkInputErrorMaxLength(255)],
    initialOption: query.has('organization')
      ? { name: '', value: Number.parseInt(query.get('organization'), 10) }
      : null,
    initialValue: query.has('organization')
      ? query.get('organization')
      : DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_SELLER.organization ||
        DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_PUBLIC.organization,
    overwriteCallback: () =>
      query.has('organization')
        ? byOrganizationsOptions.find(option => option.value === Number.parseInt(query.get('organization'), 10))
        : null,
    selectCallback: updatedOption =>
      handleSearch({
        orderValue,
        dateFromValue,
        dateToValue,
        byProviderOption,
        byProviderValue,
        byOrganizationValue,
        byOrganizationOption: updatedOption,
        checkedReceivedAnswer,
        checkedSendAnswer,
        minBudget,
        maxBudget,
        regionValue,
        byItemOption,
        byItemValue
      })
  })
  const { value: checkedReceivedAnswer, onChange: handleCheckBoxReceivedAnswer } = useCheckBox({
    initialValue: query.has('checkReceived')
      ? query.get('checkReceived') === '1'
      : DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_SELLER.checkReceived,
    overwriteCallback: () =>
      query.has('checkReceived')
        ? query.get('checkReceived') === '1'
        : DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_SELLER.checkReceived
  })
  const { value: checkedSendAnswer, onChange: handleCheckBoxSendAnswer } = useCheckBox({
    initialValue: query.has('checkSend')
      ? query.get('checkSend') === '1'
      : DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_SELLER.checkSend,
    overwriteCallback: () =>
      query.has('checkSend')
        ? query.get('checkSend') === '1'
        : DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_SELLER.checkSend
  })
  const {
    error: minError,
    value: minValueInput,
    onChange: minInputChange,
    onError: updateMinError,
    setValue: setMinValueInput
  } = useInput({
    errorCallbacks: [
      checkInputErrorMaxLength(15),
      checkInputErrorRegEx(ONLY_NUMBERS_POSITIVES_WITH_THOUSANDS_DOT_CLP_AND_SYMBOL)
    ],
    formatCallbacks: [value => currencyFormat(CURRENCY_TRANSLATION.CLP.cod, value)],
    initialValue:
      DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_SELLER.minBudget ||
      DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_PUBLIC.minBudget
  })
  const {
    error: maxError,
    value: maxValueInput,
    onChange: maxInputChange,
    onError: updateMaxError,
    setValue: setMaxValueInput
  } = useInput({
    errorCallbacks: [
      checkInputErrorMaxLength(15),
      checkInputErrorRegEx(ONLY_NUMBERS_POSITIVES_WITH_THOUSANDS_DOT_CLP_AND_SYMBOL)
    ],
    formatCallbacks: [value => currencyFormat(CURRENCY_TRANSLATION.CLP.cod, value)],
    initialValue:
      DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_SELLER.maxBudget ||
      DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_PUBLIC.maxBudget
  })
  const { value: regionValue, onChange: handleRegionChange } = useSelect({
    initialValue: query.has('region')
      ? query.get('region')
      : DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_SELLER.region ||
        DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_PUBLIC.region,
    overwriteCallback: () =>
      query.has('region')
        ? query.get('region')
        : DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_SELLER.region ||
          DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_PUBLIC.region
  })
  const {
    error: byItemError,
    option: byItemOption,
    value: byItemValue,
    onChange: handleByItemChange,
    onSelect: handleByItemSelect
  } = useAutocomplete({
    changeCallback: updatedValue => updatedValue?.length >= 3 && dispatch(onGetItemsThunk({ pattern: updatedValue })),
    errorCallbacks: [checkInputErrorMaxLength(255)],
    initialOption: query.has('item') ? { name: '', value: Number.parseInt(query.get('item'), 10) } : null,
    initialValue: query.has('item') ? query.get('item') : DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_PUBLIC.item,
    overwriteCallback: () =>
      query.has('item') ? byItemsOptions.find(option => option.value === Number.parseInt(query.get('item'), 10)) : null,
    selectCallback: updatedOption =>
      handleSearch({
        orderValue,
        dateFromValue,
        dateToValue,
        byProviderOption,
        byProviderValue,
        byOrganizationValue,
        byOrganizationOption: updatedOption,
        checkedReceivedAnswer,
        checkedSendAnswer,
        minBudget,
        maxBudget,
        regionValue,
        byItemOption,
        byItemValue
      })
  })

  const handleSearch = updatedValues => {
    let dictionary = {
      ...DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES,
      ...Object.fromEntries(query.entries()),
      orderBy: updatedValues.orderValue,
      page: DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES.page
    }

    if (dateFromError === '') {
      dictionary.dateFrom = format(updatedValues.dateFromValue, 'dd/MM/yyyy')
    }

    if (dateToError === '') {
      dictionary.dateTo = format(updatedValues.dateToValue, 'dd/MM/yyyy')
    }

    if (isSeller) {
      dictionary = {
        ...dictionary,
        ...DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_SELLER
      }

      dictionary.organization = updatedValues.byOrganizationOption?.value || ''
      dictionary.region = updatedValues.regionValue
      dictionary.checkReceived = updatedValues.checkedReceivedAnswer ? 1 : 0
      dictionary.checkSend = updatedValues.checkedSendAnswer ? 1 : 0
      dictionary.maxBudget = updatedValues.maxBudget
      dictionary.minBudget = updatedValues.minBudget
    }

    if (isBuyer) {
      dictionary = {
        ...dictionary,
        ...DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_BUYER
      }

      dictionary.provider = updatedValues.byProviderOption?.value || ''
    }

    if (!isBuyer && !isSeller) {
      dictionary = {
        ...dictionary,
        ...DIRECT_DEAL_SEARCH_FILTER_INITIAL_VALUES_BY_PUBLIC
      }

      dictionary.provider = updatedValues.byProviderOption?.value || ''
      dictionary.organization = updatedValues.byOrganizationOption?.value || ''
      dictionary.item = updatedValues.byItemOption?.value || ''
      dictionary.region = updatedValues.regionValue
      dictionary.maxBudget = updatedValues.maxBudget
      dictionary.minBudget = updatedValues.minBudget
    }

    updateQuery(query, dictionary)
    putCookie('search', query.toString())
    conditionalNavigate(query)
  }

  const handlerExpandedAccordion = (event, isExpanded) => {
    event.preventDefault()
    setExpanded(isExpanded)
  }

  const handlerBudgetInputChange = (event, changeHandler, setBudget) => {
    changeHandler(event)

    const rawValue = event && event.target ? event.target.value : 0
    const value = Number(rawValue.replace(REMOVED_THOUSANDS_DOT_CHECKED, ''))

    setBudget(value)
  }

  const handleMinInputChange = event => {
    handlerBudgetInputChange(event, minInputChange, setMinBudget)
  }

  const handleMaxInputChange = event => {
    handlerBudgetInputChange(event, maxInputChange, setMaxBudget)
  }

  const isCheckboxDisabled = useMemo(() => {
    if (causeSelected) {
      return causes.find(cause => cause.value === causeSelected)?.publicity || false
    }
    return false
  }, [causeSelected])

  useEffect(() => {
    handleSearch({
      orderValue,
      dateFromValue,
      dateToValue,
      byProviderOption,
      byProviderValue,
      byOrganizationValue,
      byOrganizationOption,
      checkedReceivedAnswer,
      checkedSendAnswer,
      minBudget,
      maxBudget,
      regionValue,
      byItemOption,
      byItemValue
    })
  }, [
    orderValue,
    dateFromValue,
    dateToValue,
    byProviderOption,
    byOrganizationOption,
    checkedReceivedAnswer,
    checkedSendAnswer,
    regionValue,
    byItemOption
  ])

  useEffect(() => {
    const minValue = minValueInput.split('$')
    const maxValue = maxValueInput.split('$')

    if (minValue[1] && maxValue[1]) {
      const sanitizedMinValue = minValueInput?.replace(ONLY_SIMBOLS_MONEY_CHECKED, '') || '0'
      const sanitizedMaxValue = maxValueInput?.replace(ONLY_SIMBOLS_MONEY_CHECKED, '') || '0'

      if (sanitizedMinValue !== minValueInput) {
        setMinValueInput(sanitizedMinValue)
      }

      if (sanitizedMaxValue !== maxValueInput) {
        setMaxValueInput(sanitizedMaxValue)
      }

      setDisabledBudgetEstimation(minError || maxError || minBudget > maxBudget)
    } else {
      /** Execute search when user has cleared budget fields */
      if (!minValue[1] && !maxValue[1]) {
        handleSearch({
          orderValue,
          dateFromValue,
          dateToValue,
          byProviderOption,
          byProviderValue,
          byOrganizationValue,
          byOrganizationOption,
          checkedReceivedAnswer,
          checkedSendAnswer,
          minBudget: '',
          maxBudget: '',
          regionValue,
          byItemOption,
          byItemValue
        })
      }

      setDisabledBudgetEstimation(true)
    }
  }, [minValueInput, maxValueInput])

  useEffectOnce(() => {
    dispatch(onGetRegionsThunk())
  })

  return (
    <Accordion expanded={isUpToLarge || isUpToMedium || expanded} elevation={0} onChange={handlerExpandedAccordion}>
      <HiddenMd>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="filtro-por">
          <Typography variant="h4" fontWeight="bold">
            Filtrar Por
          </Typography>
        </AccordionSummary>
      </HiddenMd>

      <AccordionDetails>
        <Grid container>
          <Grid item>
            <Select
              error={orderError}
              label="Ordenar por"
              loading={loadingSearch}
              options={DIRECT_DEAL_SEARCH_ORDERS || []}
              value={orderValue}
              onChange={handleOrderChange}
            />
            <Hr margin="5px 0 25px" />
          </Grid>

          <HiddenSm>
            <Grid item margin="0 0 24px">
              <Typography variant="h4" fontWeight="bold">
                Filtrar por
              </Typography>
            </Grid>
          </HiddenSm>

          {!isSeller && (
            <Grid item>
              <Autocomplete
                error={byProviderError}
                label="Proveedor"
                loading={loadingSearch}
                option={byProviderOption}
                options={byProvidersOptions}
                value={byProviderValue}
                placeholder="Ingresa al menos 3 caracteres"
                waiting={directDealFilterLoading}
                onChange={handleByProviderChange}
                onSelect={handleByProviderSelect}
              />
              {!isSeller && !isBuyer ? null : <Hr margin="5px 0 25px" />}
            </Grid>
          )}

          {!isBuyer && (
            <Grid item>
              <Autocomplete
                error={byOrganizationError}
                label="Organismo comprador"
                loading={loadingSearch}
                option={byOrganizationOption}
                options={byOrganizationsOptions}
                value={byOrganizationValue}
                placeholder="Ingresa al menos 3 caracteres"
                waiting={directDealFilterLoading}
                onChange={handleByOrganizationChange}
                onSelect={handleByOrganizationSelect}
              />
              <Hr margin="5px 0 25px" />
            </Grid>
          )}

          <Grid item margin="0 0 24px">
            <Typography variant="h4" fontWeight="bold">
              Fecha de publicación
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <DatePicker
              autoFocus={false}
              error={dateFromError}
              format="dd/MM/yyyy"
              label="Desde"
              loading={loadingSearch}
              maxDate={dateToValue}
              onChange={handleDateFromChange}
              size="medium"
              placeholder="Desde la fecha de publicación"
              value={dateFromValue}
              width="103%"
            />
          </Grid>
          <Grid item>
            <DatePicker
              autoFocus={false}
              error={dateToError}
              format="dd/MM/yyyy"
              label="Hasta"
              loading={loadingSearch}
              minDate={dateFromValue}
              onChange={handleDateToChange}
              size="medium"
              placeholder="Hasta la fecha de publicación"
              value={dateToValue}
              width="103%"
            />
          </Grid>

          {!isBuyer && (
            <>
              <Grid item>
                <Hr margin="5px 0 25px" />
                <Select
                  label="Región"
                  loading={loadingSearch}
                  options={byRegionsOptions}
                  placeholder="Todas las regiones"
                  value={regionValue}
                  onChange={handleRegionChange}
                />
              </Grid>

              {!isSeller && (
                <Grid item>
                  <Autocomplete
                    optionsLimit={40}
                    error={byItemError}
                    label="Rubro"
                    loading={loadingSearch}
                    option={byItemOption}
                    options={byItemsOptions}
                    value={byItemValue}
                    placeholder="Ingresa al menos 3 caracteres"
                    waiting={directDealFilterLoading}
                    onChange={handleByItemChange}
                    onSelect={handleByItemSelect}
                  />
                </Grid>
              )}

              <Grid item margin="30px 0 26px">
                <Typography variant="body2" fontWeight="bold" align="left">
                  Presupuesto estimado en pesos (CLP$)
                </Typography>
              </Grid>

              <GridInline direction="row">
                <GridItemInline lg={5} md={5} xs={12} margin="5px 0 0">
                  <Input
                    placeholder="$"
                    width="101px"
                    height="40px"
                    label="Mínimo"
                    value={minValueInput}
                    onChange={handleMinInputChange}
                    onError={updateMinError}
                    error={minError}
                    loading={loadingSearch}
                  />
                </GridItemInline>
                <GridItemInline lg={5} md={5} xs={12} margin="5px 0 0 5px">
                  <Input
                    placeholder="$"
                    width="101px"
                    height="40px"
                    label="Máximo"
                    value={maxValueInput}
                    onChange={handleMaxInputChange}
                    onError={updateMaxError}
                    error={maxError}
                    loading={loadingSearch}
                  />
                </GridItemInline>
                <GridItemInline lg={1} md={1} xs={12} margin="18px 10px 0 0">
                  <Button
                    aria-label="Buscar por presupuesto estimado en CLP"
                    variant="contained"
                    color="primary"
                    disabled={isDisabledBudgetEstimation}
                    onClick={() =>
                      handleSearch({
                        orderValue,
                        dateFromValue,
                        dateToValue,
                        byProviderOption,
                        byProviderValue,
                        byOrganizationValue,
                        byOrganizationOption,
                        checkedReceivedAnswer,
                        checkedSendAnswer,
                        minBudget,
                        maxBudget,
                        regionValue,
                        byItemOption,
                        byItemValue
                      })
                    }
                  >
                    <ArrowButton disabled={isDisabledBudgetEstimation} fontSize="18px" padding="8px" margin="4px 0 0" />
                  </Button>
                </GridItemInline>
              </GridInline>

              {isSeller && (
                <>
                  <Grid item margin="0 0 24px">
                    <Hr margin="25px 0 25px" />
                    <Typography variant="h4" fontWeight="bold">
                      Participación
                    </Typography>
                  </Grid>
                  <Grid item margin="0 0 22px">
                    {loadingSearch && <CheckBoxSkeleton />}
                    {!loadingSearch && (
                      <CheckBox
                        accessibility={{
                          label: 'Ver solamente los Tratos Directos de mis rubros que están recibiendo respuestas'
                        }}
                        checked={checkedReceivedAnswer}
                        disabled={checkedReceivedAnswer ? false : isCheckboxDisabled}
                        label={
                          <Typography margin="0 0 0 -7px" variant="body2">
                            Ver solamente los Tratos Directos de mis rubros que están recibiendo respuestas
                          </Typography>
                        }
                        margin="0 0 0 -3px"
                        onChange={handleCheckBoxReceivedAnswer}
                      />
                    )}
                  </Grid>
                  <Grid item>
                    {loadingSearch && <CheckBoxSkeleton />}
                    {!loadingSearch && (
                      <CheckBox
                        accessibility={{
                          label: 'Ver solamente los Tratos Directos en los que he enviado respuestas'
                        }}
                        checked={checkedSendAnswer}
                        disabled={checkedSendAnswer ? false : isCheckboxDisabled}
                        label={
                          <Typography margin="0 0 0 -7px" variant="body2">
                            Ver solamente los Tratos Directos en los que he enviado respuestas
                          </Typography>
                        }
                        margin="0 0 0 -3px"
                        onChange={handleCheckBoxSendAnswer}
                      />
                    )}
                  </Grid>
                </>
              )}
            </>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default DirectDealFilter
