import React from 'react'
import { Card, CircleWarningIcon, Typography } from '@chilecompra/react-kit/components'
import { GridContainer, GridItem } from './SearchResults.styles'

/**
 * The SearchResultsError container.
 */
const SearchResultsError = () => {
  return (
    <Card enableBorder color="blank" padding="50px 43px">
      <GridContainer>
        <GridItem xs={12} textAlign="center">
          <CircleWarningIcon />
          <Typography variant="body1" color="black1">
            No ha sido posible obtener el listado de Tratos Directos.
          </Typography>
          <Typography variant="body1" color="black1">
            Por favor, te pedimos que lo vuelvas a intentar dentro de unos minutos.
          </Typography>
        </GridItem>
      </GridContainer>
    </Card>
  )
}

export default SearchResultsError
