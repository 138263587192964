import React from 'react'

import { styled, Divider as DividerBase, Grid as BaseGrid } from '@mui/material'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'

import { TableCell as TableCellBase, TableRow as TableRowBase } from '@chilecompra/react-kit/components'

export const Root = styled('div')`
  width: 100%;
`
export const Divider = styled(DividerBase)`
  width: 100%;
`
export const Grid = styled(({ ...props }) => <BaseGrid {...props} />)`
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  width: 100%;
  text-align: ${props => props.textAlign};
  align-items: ${props => props.alignItems};
`
export const TableRow = styled(({ ...props }) => <TableRowBase {...props} />)``

export const TableCell = styled(({ full, ...props }) => <TableCellBase {...props} />)`
  background-color: ${props => props.full && props.theme.palette.blue[99]};
`
export const TableCellHeader = styled(({ full, ...props }) => <TableCellBase {...props} />)`
  border-top: 1px solid ${props => props.theme.palette.gray[85]};
  border-left: 1px solid ${props => props.theme.palette.gray[85]};
  background-color: ${props => props.full && props.theme.palette.blue[99]};
`
export const TableCellContent = styled(({ ...props }) => <TableCellBase {...props} />)`
  border-top: 1px solid ${props => props.theme.palette.gray[85]};
  border-right: 1px solid ${props => props.theme.palette.gray[85]} !important;
`
export const HeaderVoucher = styled('div')`
  border-top: 1px solid ${props => props.theme.palette.gray[85]};
  border-right: 1px solid ${props => props.theme.palette.gray[85]};
  border-left: 1px solid ${props => props.theme.palette.gray[85]};

  background-color: ${props => props.theme.palette.blue[99]};
  padding: 16px;
`
export const CellVoucher = styled('div')`
  border-top: 1px solid ${props => props.theme.palette.gray[85]};
  border-right: 1px solid ${props => props.theme.palette.gray[85]};
  border-left: 1px solid ${props => props.theme.palette.gray[85]};
  border-bottom: ${props => (props.enableBottomBorder ? `1px solid ${props.theme.palette.gray[85]}` : 'none')};
  padding: 16px;
`

export const Strong = styled('strong')`
  font-size: 16px;
`

export const MainCircle = styled('div')`
  width: 64px;
  height: 64px;
  background-color: ${props =>
    props.backgroundColor === 'green' ? props.theme.palette.green[90] : props.theme.palette.yellow[95]};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const InnerCircle = styled('div')`
  width: 40px;
  height: 40px;
  background-color: ${props => props.theme.palette.green[50]};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ContainerCheck = styled('div')`
  display: 'flex';
  justify-content: 'center';
  margin: ${props => props.margin};
`

export const StyledPriorityHighIcon = styled(PriorityHighIcon)`
  color: ${props => props.theme.palette.yellow[50]};
  font-size: 24px;
`
