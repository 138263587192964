import {
  CLEAN_QUOTE_DIRECT_DEAL_QUOTE,
  CLOSE_DETAIL_QUOTE_DIRECT_DEAL_QUOTE,
  CLOSE_QUOTE_DIRECT_DEAL_QUOTE,
  GENERATE_DIRECT_DEAL_QUOTE_ERROR,
  GENERATE_DIRECT_DEAL_QUOTE_SUCCESS,
  GENERATE_DIRECT_DEAL_QUOTE,
  GET_INFO_DNI_DIRECT_DEAL_QUOTE_ERROR,
  GET_INFO_DNI_DIRECT_DEAL_QUOTE_SUCCESS,
  GET_INFO_DNI_DIRECT_DEAL_QUOTE,
  OPEN_DETAIL_QUOTE_DIRECT_DEAL_QUOTE,
  OPEN_QUOTE_DIRECT_DEAL_QUOTE,
  REMOVE_QUOTE_DIRECT_DEAL_QUOTE,
  REMOVE_QUOTE_DIRECT_DEAL_QUOTE_ERROR,
  REMOVE_QUOTE_DIRECT_DEAL_QUOTE_SUCCESS,
  RESET_DIRECT_DEAL_QUOTE,
  SAVE_QUOTE_DIRECT_DEAL_QUOTE,
  SAVED_DIRECT_DEAL_QUOTE_ERROR,
  SAVED_DIRECT_DEAL_QUOTE_SUCCESS,
  SAVED_DIRECT_DEAL_QUOTE,
  STORAGE_ID_DIRECT_DEAL_QUOTE,
  UPDATE_QUOTE_DIRECT_DEAL_QUOTE,
  VALID_ATTACHMENTS_DIRECT_DEAL_QUOTE_ERROR,
  VALID_ATTACHMENTS_DIRECT_DEAL_QUOTE_SUCCESS,
  VALID_ATTACHMENTS_DIRECT_DEAL_QUOTE,
  INITIAL_SAVED_VALUES_DIRECT_DEAL_QUOTE
} from './DirectDealQuote.actions'

const directDealQuoteState = {
  dialog: { openQuote: false, openQuoteDetail: false },
  error: undefined,
  idQuoteEdit: null,
  loading: false,
  quote: null,
  quotes: []
}

/**
 * The DirectDealQuote reducer.
 */
const directDealQuoteReducer = (state = directDealQuoteState, { payload, type }) => {
  switch (type) {
    case GENERATE_DIRECT_DEAL_QUOTE:
    case GET_INFO_DNI_DIRECT_DEAL_QUOTE:
    case REMOVE_QUOTE_DIRECT_DEAL_QUOTE:
    case SAVED_DIRECT_DEAL_QUOTE:
    case VALID_ATTACHMENTS_DIRECT_DEAL_QUOTE: {
      return { ...state, error: undefined, loading: true }
    }
    case SAVED_DIRECT_DEAL_QUOTE_SUCCESS: {
      return { ...state, loading: false, quote: null }
    }
    case GENERATE_DIRECT_DEAL_QUOTE_SUCCESS: {
      return { ...state, loading: false, quote: { ...state.quote, ...payload.quote } }
    }
    case VALID_ATTACHMENTS_DIRECT_DEAL_QUOTE_SUCCESS: {
      return { ...state, loading: false, error: undefined }
    }
    case GET_INFO_DNI_DIRECT_DEAL_QUOTE_ERROR:
    case SAVED_DIRECT_DEAL_QUOTE_ERROR:
    case GENERATE_DIRECT_DEAL_QUOTE_ERROR:
    case REMOVE_QUOTE_DIRECT_DEAL_QUOTE_ERROR:
    case VALID_ATTACHMENTS_DIRECT_DEAL_QUOTE_ERROR: {
      return { ...state, error: payload?.err?.descripcion || `ERROR: ${type}`, loading: false }
    }
    case GET_INFO_DNI_DIRECT_DEAL_QUOTE_SUCCESS: {
      return { ...state, loading: false, error: undefined }
    }
    case STORAGE_ID_DIRECT_DEAL_QUOTE: {
      return { ...state, idQuoteEdit: payload }
    }
    case CLEAN_QUOTE_DIRECT_DEAL_QUOTE: {
      return { ...state, quote: null, idQuoteEdit: null }
    }
    case SAVE_QUOTE_DIRECT_DEAL_QUOTE: {
      return {
        ...state,
        quotes: [...state.quotes, payload]
      }
    }
    case UPDATE_QUOTE_DIRECT_DEAL_QUOTE: {
      return {
        ...state,
        quotes: state.quotes.map(quote =>
          quote.idQuote === payload.idQuote
            ? {
                ...quote,
                contributorDni: payload.contributorDni,
                contributorLegalName: payload.contributorLegalName,
                contributorPhone: payload.contributorPhone,
                contributorEmail: payload.contributorEmail
              }
            : quote
        )
      }
    }
    case REMOVE_QUOTE_DIRECT_DEAL_QUOTE_SUCCESS: {
      return {
        ...state,
        loading: false,
        quotes: state.quotes.filter(quote => quote.idQuote !== payload.idQuote)
      }
    }
    case RESET_DIRECT_DEAL_QUOTE: {
      return { ...state, quotes: [] }
    }
    case OPEN_QUOTE_DIRECT_DEAL_QUOTE: {
      return { ...state, dialog: { ...state.dialog, openQuote: true } }
    }
    case CLOSE_QUOTE_DIRECT_DEAL_QUOTE: {
      return { ...state, dialog: { ...state.dialog, openQuote: false } }
    }
    case OPEN_DETAIL_QUOTE_DIRECT_DEAL_QUOTE: {
      return { ...state, dialog: { ...state.dialog, openQuoteDetail: true } }
    }
    case CLOSE_DETAIL_QUOTE_DIRECT_DEAL_QUOTE: {
      return { ...state, dialog: { ...state.dialog, openQuoteDetail: false } }
    }
    case INITIAL_SAVED_VALUES_DIRECT_DEAL_QUOTE: {
      return { ...state, quotes: payload }
    }
    default: {
      return state
    }
  }
}

export default directDealQuoteReducer
