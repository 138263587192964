import {
  CLOSE_ABILITY_DIRECT_DEAL_STEP_TWO,
  CLOSE_CONFIRM_STEP_TWO_DIALOG,
  CLOSE_SAVED_CHANGES_DIRECT_DEAL_STEP_TWO,
  CLOSE_SAVED_ERROR_DIRECT_DEAL_STEP_TWO,
  GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO_ERROR,
  GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO_SUCCESS,
  GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO,
  GET_CAUSE_STEP_TWO_ERROR,
  GET_CAUSE_STEP_TWO_SUCCESS,
  GET_CAUSE_STEP_TWO,
  GET_CLOSING_DATE_ERROR,
  GET_CLOSING_DATE_SUCCESS,
  GET_CLOSING_DATE,
  GET_CONTRACT_DURATION_ERROR,
  GET_CONTRACT_DURATION_SUCCESS,
  GET_CONTRACT_DURATION,
  GET_CONTRACT_TYPE_ERROR,
  GET_CONTRACT_TYPE_SUCCESS,
  GET_CONTRACT_TYPE,
  GET_INFO_CITIES_ERROR,
  GET_INFO_CITIES_SUCCESS,
  GET_INFO_CITIES,
  GET_INFO_PROVIDER_ERROR,
  GET_INFO_PROVIDER_SUCCESS,
  GET_INFO_PROVIDER,
  GET_INFO_REGIONS_ERROR,
  GET_INFO_REGIONS_SUCCESS,
  GET_INFO_REGIONS,
  GET_INITIAL_VALUE_DIRECT_DEAL_STEP_TWO_ERROR,
  GET_INITIAL_VALUE_DIRECT_DEAL_STEP_TWO_SUCCESS,
  GET_INITIAL_VALUE_DIRECT_DEAL_STEP_TWO,
  GET_SAVED_DIRECT_DEAL_SEARCH_ERROR,
  GET_SAVED_DIRECT_DEAL_SEARCH_SUCCESS,
  GET_SAVED_DIRECT_DEAL_SEARCH,
  GET_TAXES_ERROR,
  GET_TAXES_SUCCESS,
  GET_TAXES,
  GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO_ERROR,
  GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO_SUCCESS,
  GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO,
  OPEN_ABILITY_DIRECT_DEAL_STEP_TWO,
  OPEN_CONFIRM_STEP_TWO_DIALOG,
  OPEN_SAVED_CHANGES_DIRECT_DEAL_STEP_TWO,
  OPEN_SAVED_ERROR_DIRECT_DEAL_STEP_TWO,
  PUBLISH_DIRECT_DEAL_ERROR,
  PUBLISH_DIRECT_DEAL_SUCCESS,
  PUBLISH_DIRECT_DEAL,
  RESET_DIRECT_DEAL_STEP_TWO,
  SAVE_DIRECT_DEAL_ERROR,
  SAVE_DIRECT_DEAL_SUCCESS,
  SAVE_DIRECT_DEAL,
  SAVED_INFO_STEP_ONE_DIRECT_DEAL_STEP_TWO,
  STORAGE_DIRECT_DEAL_STEP_TWO
} from './DirectDealStepTwo.actions'

const directDealStepTwoReducerState = {
  causes: [],
  cities: [],
  closingDate: null,
  contractDuration: [],
  contractType: null,
  currencies: [],
  dialog: {
    openAbility: false,
    openConfirmation: false,
    openQuote: false,
    openQuoteDetail: false,
    openSaved: false,
    openSavedError: false
  },
  error: undefined,
  loadings: { main: false, provider: false, regions: false },
  provider: null,
  regions: [],
  sheet: undefined,
  sheetStorage: undefined,
  stepOne: undefined,
  taxes: [],
  unitMeasurement: []
}

/**
 * The DirectDealStepTwoReducer reducer.
 */
const directDealStepTwoReducer = (state = directDealStepTwoReducerState, { payload, type }) => {
  switch (type) {
    case GET_SAVED_DIRECT_DEAL_SEARCH:
    case GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO:
    case GET_CAUSE_STEP_TWO:
    case GET_CLOSING_DATE:
    case GET_CONTRACT_DURATION:
    case GET_CONTRACT_TYPE:
    case GET_INITIAL_VALUE_DIRECT_DEAL_STEP_TWO:
    case GET_TAXES:
    case GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO:
    case PUBLISH_DIRECT_DEAL:
    case SAVE_DIRECT_DEAL: {
      return { ...state, loading: true, loadings: { ...state.loadings, main: true } }
    }
    case GET_INFO_CITIES:
    case GET_INFO_REGIONS: {
      return { ...state, loadings: { ...state.loadings, regions: true } }
    }
    case GET_INFO_PROVIDER: {
      return { ...state, loadings: { ...state.loadings, provider: true } }
    }
    case GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO_ERROR:
    case GET_SAVED_DIRECT_DEAL_SEARCH_ERROR:
    case GET_CAUSE_STEP_TWO_ERROR:
    case GET_CLOSING_DATE_ERROR:
    case GET_CONTRACT_DURATION_ERROR:
    case GET_CONTRACT_TYPE_ERROR:
    case GET_INITIAL_VALUE_DIRECT_DEAL_STEP_TWO_ERROR:
    case GET_TAXES_ERROR:
    case GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO_ERROR:
    case PUBLISH_DIRECT_DEAL_ERROR:
    case SAVE_DIRECT_DEAL_ERROR: {
      return {
        ...state,
        error: payload?.error?.descripcion || `ERROR: ${type}`,
        loadings: { ...state.loadings, main: false }
      }
    }
    case GET_INFO_CITIES_ERROR:
    case GET_INFO_REGIONS_ERROR: {
      return {
        ...state,
        error: payload?.error?.descripcion || `ERROR: ${type}`,
        loadings: { ...state.loadings, regions: false }
      }
    }
    case GET_INFO_PROVIDER_ERROR: {
      return {
        ...state,
        error: payload?.error?.descripcion || `ERROR: ${type}`,
        loadings: { ...state.loadings, provider: false }
      }
    }
    case GET_CLOSING_DATE_SUCCESS: {
      return { ...state, error: undefined, loadings: { ...state.loadings, main: false }, closingDate: payload }
    }
    case GET_CONTRACT_DURATION_SUCCESS: {
      return {
        ...state,
        error: undefined,
        loadings: { ...state.loadings, main: false },
        contractDuration: [...payload.contractDuration]
      }
    }
    case GET_CONTRACT_TYPE_SUCCESS: {
      return {
        ...state,
        error: undefined,
        loadings: { ...state.loadings, main: false },
        contractType: { ...payload.contractType }
      }
    }
    case GET_INFO_PROVIDER_SUCCESS: {
      return {
        ...state,
        error: undefined,
        loadings: { ...state.loadings, provider: false },
        provider: payload.provider
      }
    }
    case GET_INFO_REGIONS_SUCCESS: {
      return { ...state, loadings: { ...state.loadings, regions: false }, regions: payload.regions }
    }
    case GET_INFO_CITIES_SUCCESS: {
      return { ...state, loadings: { ...state.loadings, regions: false }, cities: payload.cities }
    }
    case GET_TAXES_SUCCESS: {
      return { ...state, error: undefined, loadings: { ...state.loadings, main: false }, taxes: payload.taxes }
    }
    case SAVE_DIRECT_DEAL_SUCCESS: {
      return { ...state, error: undefined, loadings: { ...state.loadings, main: false } }
    }
    case PUBLISH_DIRECT_DEAL_SUCCESS: {
      return { ...state, error: undefined, loadings: { ...state.loadings, main: false } }
    }
    case OPEN_CONFIRM_STEP_TWO_DIALOG: {
      return { ...state, dialog: { ...state.dialog, openConfirmation: true } }
    }
    case CLOSE_CONFIRM_STEP_TWO_DIALOG: {
      return { ...state, dialog: { ...state.dialog, openConfirmation: false } }
    }
    case GET_INITIAL_VALUE_DIRECT_DEAL_STEP_TWO_SUCCESS: {
      return { ...state, sheet: payload.sheet }
    }
    case GET_UNIT_MEASUREMENT_DIRECT_DEAL_STEP_TWO_SUCCESS: {
      return { ...state, unitMeasurement: payload.unitMeasurement, loadings: { ...state.loadings, main: false } }
    }
    case STORAGE_DIRECT_DEAL_STEP_TWO: {
      return { ...state, sheetStorage: { ...payload.sheet } }
    }
    case OPEN_SAVED_CHANGES_DIRECT_DEAL_STEP_TWO: {
      return { ...state, dialog: { ...state.dialog, openSaved: true } }
    }
    case CLOSE_SAVED_CHANGES_DIRECT_DEAL_STEP_TWO: {
      return { ...state, dialog: { ...state.dialog, openSaved: false } }
    }
    case GET_ALL_CURRIENCIES_DIRECT_DEAL_STEP_TWO_SUCCESS: {
      return { ...state, currencies: payload.currencies, loadings: { ...state.loadings, main: false } }
    }
    case OPEN_ABILITY_DIRECT_DEAL_STEP_TWO: {
      return { ...state, dialog: { ...state.dialog, openAbility: true } }
    }
    case CLOSE_ABILITY_DIRECT_DEAL_STEP_TWO: {
      return { ...state, dialog: { ...state.dialog, openAbility: false } }
    }
    case SAVED_INFO_STEP_ONE_DIRECT_DEAL_STEP_TWO: {
      return { ...state, stepOne: payload.sheet }
    }
    case RESET_DIRECT_DEAL_STEP_TWO: {
      return { ...state, provider: null, sheet: null, sheetStorage: null }
    }
    case GET_CAUSE_STEP_TWO_SUCCESS: {
      return { ...state, causes: payload.causes }
    }
    case GET_SAVED_DIRECT_DEAL_SEARCH_SUCCESS: {
      return {
        ...state,
        sheetStorage: { ...payload.sheet },
        loadings: { ...state.loadings, main: false }
      }
    }
    case OPEN_SAVED_ERROR_DIRECT_DEAL_STEP_TWO: {
      return { ...state, dialog: { ...state.dialog, openSavedError: true } }
    }
    case CLOSE_SAVED_ERROR_DIRECT_DEAL_STEP_TWO: {
      return { ...state, dialog: { ...state.dialog, openSavedError: false } }
    }
    default: {
      return state
    }
  }
}

export default directDealStepTwoReducer
