import { factoryService, getJsonRequest, getFileRequest } from '@chilecompra/react-kit/requests'
import { REACT_APP_ATTACHMENT_API_URL } from '../config/settings/environment'

/**
 * Gets information of the files
 */
export const getInfoAttachmentFiles = factoryService(({ attachmentCodes }) => {
  const { business, businessId, businessSubId } = attachmentCodes
  return getJsonRequest(`${REACT_APP_ATTACHMENT_API_URL}/adjuntos-api/${business}/${businessSubId}/${businessId}`)
})

/**
 * Download files by file id
 */
export const getDownloadAttachmentFiles = factoryService(({ fileUrl }) => {
  return getFileRequest(fileUrl)
})
