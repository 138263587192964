import React, { useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'

import { Card, Select, Skeleton, Typography, Radio } from '@chilecompra/react-kit/components'
import { checkInputErrorRequired, useSelect, useRadio } from '@chilecompra/react-kit'
import FormattedCurrencyComponent from '../../components/components/FormattedCurrency.component'

import { Grid, GridItem, Strong } from './DirectDealStepOne.styles'
import { useImperativeFormRef } from '../../modules/hooks/useFormRef'

import {
  BINARY_CONFIRMATION_RESPONSE,
  DIRECT_DEAL_CAUSES_DESCRIPTION_DEFAULT,
  DIRECT_DEAL_MORE_DOCUMENTS_ARE_REQUIRED,
  DIRECT_DEAL_UNIQUE_EXCEPTIONS,
  DIRECT_DEAL_UNIQUE_EXCEPTION_OVER_1000_UTM
} from '../../config/settings/constants'

/**
 * The DirectDealStepOne's basic background information.
 */
const DirectDealBasicBackground = props => {
  const { causes, loading, formRef, requiredMoreDocuments, utm, stepZeroRadioOption } = props

  const [isSelectedCausePublicity, setCausePublicity] = useState()
  const [causeDescription, setCauseDescription] = useState(DIRECT_DEAL_CAUSES_DESCRIPTION_DEFAULT)
  const [isUnder1000UTM, setCauseUnder1000UTM] = useState()
  const [isExceptionalCause, setExceptionalCause] = useState()
  const [radioValue, setRadioValue] = useState('')
  const [stepZeroRadioValue, setStepZeroRadioValue] = useState('')
  const [descriptionAvailable, setdescriptionAvailable] = useState(false)

  const {
    error: causeError,
    onChange: handleCauseChange,
    onError: handleCauseError,
    value: causeValue
  } = useSelect({
    errorCallbacks: [checkInputErrorRequired()],
    changeCallback: updatedValue => {
      setRadioAmountOption(updatedValue)
      if (isExceptionalCause && radioValue === BINARY_CONFIRMATION_RESPONSE[1].value) {
        setCausePublicity(false)
      } else if (isExceptionalCause && radioValue === BINARY_CONFIRMATION_RESPONSE[0].value) {
        setCausePublicity(true)
      }
      verificationCheckCauseUnder1000UTM(updatedValue)
      verificationCausePublicity(updatedValue, radioValue, isExceptionalCause)
      setdescriptionAvailable(false)
      changesCauseDescriptions(updatedValue)
    }
  })
  const {
    value: isGreaterThanThousandValue,
    onChange: handleGreaterThanThousandChange,
    setValue: setGreaterThanThousandValue
  } = useRadio({
    errorCallbacks: [checkInputErrorRequired()],
    changeCallback: updatedValue => {
      setRadioValue(updatedValue)
    }
  })

  useLayoutEffect(() => {
    setStepZeroRadioValue(stepZeroRadioOption)
    setRadioValue(isGreaterThanThousandValue)
    verificationCausePublicity(causeValue, radioValue, isGreaterThanThousandValue)
  }, [causeValue, radioValue, isExceptionalCause, isSelectedCausePublicity])

  const itsCauseUnder1000UTM = updatedValue => {
    const under1000Utm = causes.find(cause => cause.value === updatedValue && cause.over1000Utm === true)
    return under1000Utm
  }

  const isOver1000UTM = id => {
    return DIRECT_DEAL_UNIQUE_EXCEPTION_OVER_1000_UTM.some(item => item.id === id)
  }
  const setRadioAmountOption = updatedValue => {
    const over1000Utm = isOver1000UTM(updatedValue)
    if (over1000Utm) {
      setGreaterThanThousandValue(BINARY_CONFIRMATION_RESPONSE[0].value)
    } else {
      setGreaterThanThousandValue(BINARY_CONFIRMATION_RESPONSE[1].value)
    }
  }

  const verificationCheckCauseUnder1000UTM = updatedValue => {
    setCausePublicity()
    if (itsCauseUnder1000UTM(updatedValue)) {
      setCauseUnder1000UTM(true)
    } else {
      setCauseUnder1000UTM(false)
    }
    setRadioAmountOption(updatedValue)
  }

  const itsExceptionalCause = updatedValue => {
    const Exception = DIRECT_DEAL_UNIQUE_EXCEPTIONS.some(item => item.id === updatedValue)
    if (Exception) {
      setCauseUnder1000UTM(true)
      setGreaterThanThousandValue(BINARY_CONFIRMATION_RESPONSE[1].value)
    }
    return Exception
  }

  const verificationCausePublicity = updatedValue => {
    const causePublicity = causes.find(cause => cause.value === updatedValue && cause.publicity === true)
    const isExceptional = itsExceptionalCause(updatedValue)
    setExceptionalCause(isExceptional)
    checkStandardPublicity(causePublicity, isExceptional)
  }

  const checkStandardPublicity = (causePublicity, isExceptional) => {
    if (causePublicity && !isExceptional) {
      setCausePublicity(true)
    } else if (!causePublicity && !isExceptional) {
      setCausePublicity(false)
    }
  }

  const changesCauseDescriptions = updatedValue => {
    const description = causes.find(cause => cause.value === updatedValue)?.description
    if (description !== null || undefined) {
      setCauseDescription(description || DIRECT_DEAL_CAUSES_DESCRIPTION_DEFAULT)
      setdescriptionAvailable(true)
    }
  }

  useImperativeFormRef(
    formRef,
    () => ({
      cause: {
        error: causeError,
        onError: handleCauseError,
        value: causeValue
      },
      greaterThanThounsand: {
        value: isGreaterThanThousandValue
      },
      isSelectedCausePublicity
    }),
    [causeError, causeValue, isGreaterThanThousandValue, isSelectedCausePublicity]
  )

  return (
    <Grid container margin="33px 0" spacing={2}>
      <GridItem xs={12} md={2}>
        <Typography fontWeight="bold" variant="body1" margin="0 0 8px">
          Antecedentes básicos
        </Typography>
        <Typography variant="body2">Ingresa información específica de tu Trato Directo.</Typography>
      </GridItem>

      <GridItem xs={12} md={6}>
        <GridItem>
          <Select
            error={causeError}
            label="Causal de Trato Directo"
            placeholder="Selecciona la causal de tu Trato Directo"
            loading={loading}
            options={causes}
            value={causeValue}
            onChange={handleCauseChange}
          />
        </GridItem>
        <GridItem>
          {loading && <Skeleton width="150px" />}

          {!loading && (
            <>
              <Typography variant="body2" fontWeight="bold">
                ¿El Trato Directo será por un monto mayor a 1.000 UTM?
              </Typography>
              <Typography fontSize="12px" lineHeight="16px" color="black3" variant="body2">
                Monto referencial calculado en peso chileno{' '}
                {utm?.valueCLPRef && (
                  <FormattedCurrencyComponent amount={utm?.valueCLPRef} currency="CLP" includeSymbol />
                )}
              </Typography>
              <Typography fontSize="12px" lineHeight="16px" color="black3" variant="body2">
                Valor UTM al día {utm?.date}:{' '}
                {utm?.valueCLP && <FormattedCurrencyComponent amount={utm?.valueCLP} currency="CLP" includeSymbol />}
              </Typography>
            </>
          )}

          <Radio
            disabled={isUnder1000UTM}
            options={BINARY_CONFIRMATION_RESPONSE}
            loading={loading}
            margin="9px 0 0 -8px"
            value={isGreaterThanThousandValue}
            onChange={handleGreaterThanThousandChange}
          />
        </GridItem>

        {isSelectedCausePublicity && (
          <GridItem margin="0 0 32px">
            <Card color="primary">
              <Typography fontSize="14px" lineHeight="18px">
                Debido a la causal y monto del Trato Directo que deseas realizar, este{' '}
                <Strong>deberá permanecer publicado al menos 5 días hábiles</Strong> en caso de que exista otro
                proveedor que pueda ofrecer el mismo bien o servicio.
              </Typography>
            </Card>
          </GridItem>
        )}
      </GridItem>

      <GridItem xs={12} md={4}>
        {stepZeroRadioValue !== BINARY_CONFIRMATION_RESPONSE[0].value &&
          descriptionAvailable &&
          causeDescription.trim() && (
            <>
              <Card
                padding="24px"
                margin="0 0 26px"
                sx={{ display: { sm: 'block', xs: 'block', lg: 'none', md: 'none' } }}
              >
                <Grid container>
                  <GridItem>
                    <Typography variant="body1" fontWeight="bold" margin="0 0 16px">
                      Causal y justificación de Trato Directo
                    </Typography>
                  </GridItem>
                  <GridItem>
                    <Typography variant="body2">{causeDescription}</Typography>
                  </GridItem>
                </Grid>
              </Card>

              <Card
                padding="24px"
                margin="0 0 26px"
                sx={{
                  position: 'absolute',
                  top: '45%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 10,
                  maxWidth: '400px',
                  width: '90%',
                  display: { sm: 'none', xs: 'none', lg: 'block', md: 'block' }
                }}
              >
                <Grid container>
                  <GridItem>
                    <Typography variant="body1" fontWeight="bold" margin="0 0 16px">
                      Causal y justificación de Trato Directo
                    </Typography>
                  </GridItem>
                  <GridItem>
                    <Typography variant="body2">{causeDescription}</Typography>
                  </GridItem>
                </Grid>
              </Card>
            </>
          )}

        {isGreaterThanThousandValue === BINARY_CONFIRMATION_RESPONSE[0].value && requiredMoreDocuments && (
          <Card
            enableBorder
            lineColor="default"
            color="blank"
            padding="24px"
            sx={{ display: { sm: 'block', xs: 'block', lg: 'none', md: 'none' } }}
          >
            <Grid container>
              <GridItem>
                <Typography variant="h4" fontWeight="bold" padding="0 0 8px">
                  Se requiere informe técnico
                </Typography>
              </GridItem>
              <GridItem>
                <Typography variant="body2" lineHeight="18px">
                  {DIRECT_DEAL_MORE_DOCUMENTS_ARE_REQUIRED}
                </Typography>
              </GridItem>
            </Grid>
          </Card>
        )}
      </GridItem>
    </Grid>
  )
}

DirectDealBasicBackground.propTypes = {
  causes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string
    })
  ),
  stepZeroRadioOption: PropTypes.number,
  loading: PropTypes.bool,
  formRef: PropTypes.shape({
    current: PropTypes.shape(Object)
  }),
  utm: PropTypes.shape({
    date: PropTypes.string,
    valueCLP: PropTypes.number,
    valueCLPRef: PropTypes.number
  }),
  requiredMoreDocuments: PropTypes.bool
}

export default DirectDealBasicBackground
