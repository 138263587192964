import React from 'react'
import PropTypes from 'prop-types'

import {
  formateNumberToNationalFormatWithDecimals,
  formatNumberToCLP,
  formatNumberToInternationalFormatWithDecimals
} from '../../modules/utils/formatters'
import { CURRENCY_TRANSLATION } from '../../config/settings/constants'

/**
 * The FormattedCurrency component.
 */
const FormattedCurrencyComponent = props => {
  const { amount, currency, includeSymbol = false, notFormat = false } = props

  let formattedAmount = '0'

  if (!notFormat) {
    if (currency === CURRENCY_TRANSLATION.CLF.cod || currency === CURRENCY_TRANSLATION.UTM.cod) {
      formattedAmount = formateNumberToNationalFormatWithDecimals(amount)
    } else if (currency === CURRENCY_TRANSLATION.USD.cod || currency === CURRENCY_TRANSLATION.EUR.cod) {
      formattedAmount = formatNumberToInternationalFormatWithDecimals(amount)
    } else if (currency === CURRENCY_TRANSLATION.CLP.cod) {
      formattedAmount = formatNumberToCLP(amount)
    } else {
      formattedAmount = amount
    }
  } else {
    formattedAmount = amount
  }

  return (
    <>
      {includeSymbol && CURRENCY_TRANSLATION[currency]?.symbol}
      {amount && formattedAmount}
    </>
  )
}

FormattedCurrencyComponent.propTypes = {
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  includeSymbol: PropTypes.bool,
  notFormat: PropTypes.bool
}

export default FormattedCurrencyComponent
