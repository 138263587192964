import { combineReducers } from 'redux'

import authorizationProviderReducer from '../../containers/AuthProvider/AuthProvider.reducer'
import directDealFiltersReducer from '../../containers/DirectDealFilter/DirectDealFilter.reducer'
import directDealSearchDownloadReducer from '../../containers/DirectDealSearchDownload/DirectDealSearchDownload.reducer'
import directDealStepZeroReducer from '../../containers/DirectDealStepZero/DirectDealStepZero.reducer'
import directDealStepOneReducer from '../../containers/DirectDealStepOne/DirectDealStepOne.reducer'
import directDealStepTwoReducer from '../../containers/DirectDealStepTwo/DirectDealStepTwo.reducer'
import directDealSummaryReducer from '../../containers/DirectDealSummary/DirectDealSummary.reducer'
import directDealVoucherReducer from '../../containers/DirectDealVoucher/DirectDealVoucher.reducer'
import directDealQuoteReducer from '../../containers/DirectDealStepTwo/Quotes/DirectDealQuote.reducer'
import productCategorySearchReducer from '../../containers/ProductCategorySearch/ProductCategorySearch.reducer'
import productSearchReducer from '../../containers/ProductSearch/ProductSearch.reducer'
import searchAndFilterReducer from '../../containers/SearchAndFilterBar/SearchAndFilterBar.reducer'
import snackbarProviderReducer from '../../containers/SnackBarProvider/SnackBarProvider.reducer'

export default combineReducers({
  authorizationProvider: authorizationProviderReducer,
  directDealFilters: directDealFiltersReducer,
  directDealSearchDownload: directDealSearchDownloadReducer,
  directDealStepZero: directDealStepZeroReducer,
  directDealStepOne: directDealStepOneReducer,
  directDealStepTwo: directDealStepTwoReducer,
  directDealSummary: directDealSummaryReducer,
  directDealVoucher: directDealVoucherReducer,
  directDealQuote: directDealQuoteReducer,
  productCategorySearch: productCategorySearchReducer,
  productSearch: productSearchReducer,
  searchFilters: searchAndFilterReducer,
  snackbarProvider: snackbarProviderReducer
})
