import { makeActionCreator } from '../../config/store/utils'
import { getOrganizations, getProviders, getItems } from '../../services/organizations'
import { getRegion } from '../../services/config'

export const GET_PROVIDERS = 'GET_PROVIDERS'
export const GET_PROVIDERS_ERROR = 'GET_PROVIDERS_ERROR'
export const GET_PROVIDERS_SUCCESS = 'GET_PROVIDERS_SUCCESS'

export const onGetProviders = makeActionCreator(GET_PROVIDERS)
export const onGetProvidersError = makeActionCreator(GET_PROVIDERS_ERROR, 'payload')
export const onGetProvidersSuccess = makeActionCreator(GET_PROVIDERS_SUCCESS, 'payload')

export const onGetProvidersThunk = payload => async dispatch => {
  dispatch(onGetProviders())

  try {
    const { data } = await getProviders({
      actionType: GET_PROVIDERS,
      pattern: payload.pattern
    })

    return dispatch(
      onGetProvidersSuccess({
        providers:
          data?.payload.map(item => ({
            name: item.nombre,
            value: item.rut
          })) || []
      })
    )
  } catch (error) {
    return dispatch(onGetProvidersError({ error }))
  }
}

export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS'
export const GET_ORGANIZATIONS_ERROR = 'GET_ORGANIZATIONS_ERROR'
export const GET_ORGANIZATIONS_SUCCESS = 'GET_ORGANIZATIONS_SUCCESS'

export const onGetOrganizations = makeActionCreator(GET_ORGANIZATIONS)
export const onGetOrganizationsError = makeActionCreator(GET_ORGANIZATIONS_ERROR, 'payload')
export const onGetOrganizationsSuccess = makeActionCreator(GET_ORGANIZATIONS_SUCCESS, 'payload')

export const onGetOrganizationsThunk = payload => async dispatch => {
  dispatch(onGetOrganizations())

  try {
    const { data } = await getOrganizations({ actionType: GET_ORGANIZATIONS, pattern: payload.pattern })

    return dispatch(
      onGetOrganizationsSuccess({
        organizations:
          data?.payload.map(item => ({
            name: item.nombre,
            value: item.id
          })) || []
      })
    )
  } catch (error) {
    return dispatch(onGetOrganizationsError({ error }))
  }
}

export const GET_ITEMS = 'GET_ITEMS'
export const GET_ITEMS_ERROR = 'GET_ITEMS_ERROR'
export const GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS'

export const onGetItems = makeActionCreator(GET_ITEMS)
export const onGetItemsError = makeActionCreator(GET_ITEMS_ERROR, 'payload')
export const onGetItemsSuccess = makeActionCreator(GET_ITEMS_SUCCESS, 'payload')

export const onGetItemsThunk = payload => async dispatch => {
  dispatch(onGetItems())

  try {
    const { data } = await getItems({ actionType: GET_ITEMS, pattern: payload.pattern })

    return dispatch(
      onGetItemsSuccess({
        items:
          data?.payload.map(item => ({
            name: item.nombre,
            value: item.codigo
          })) || []
      })
    )
  } catch (error) {
    return dispatch(onGetItemsError({ error }))
  }
}

export const GET_REGIONS = 'GET_REGIONS'
export const GET_REGIONS_SUCCESS = 'GET_REGIONS_SUCCESS'
export const GET_REGIONS_ERROR = 'GET_REGIONS_ERROR'

export const onGetRegion = makeActionCreator(GET_REGIONS)
export const onGetRegionError = makeActionCreator(GET_REGIONS_ERROR, 'payload')
export const onGetRegionSuccess = makeActionCreator(GET_REGIONS_SUCCESS, 'payload')

export const onGetRegionsThunk = () => async dispatch => {
  dispatch(onGetRegion())

  try {
    const { data } = await getRegion()
    const regions_ = data.payload?.map(region => ({ name: region.nombre, value: region.id }))
    const everybodyItem = { name: 'Todas las regiones', value: '' }
    const regions = [everybodyItem, ...regions_]

    return dispatch(onGetRegionSuccess({ regions }))
  } catch (error) {
    return dispatch(onGetRegionError(error))
  }
}
