import {
  GET_AUTH_INFO,
  GET_AUTH_INFO_ERROR,
  GET_AUTH_INFO_SUCCESS,
  GET_PUBLIC_TOKEN,
  GET_PUBLIC_TOKEN_ERROR,
  GET_PUBLIC_TOKEN_SUCCESS,
  GET_TOKEN,
  GET_TOKEN_ERROR,
  GET_TOKEN_SUCCESS
} from './AuthProvider.actions'

const authorizationProviderState = {
  accessToken: undefined,
  authInfo: {
    loading: false,
    error: undefined,
    userType: '',
    isBuyer: false,
    isSeller: false,
    userDni: ''
  },
  gettingToken: true,
  error: false
}

/**
 * The authorization provider's reducer.
 */

const authorizationProviderReducer = (state = authorizationProviderState, { payload, type }) => {
  switch (type) {
    case GET_AUTH_INFO: {
      return { ...state, authInfo: { ...state.authInfo, loading: true } }
    }
    case GET_AUTH_INFO_ERROR: {
      return { ...state, authInfo: { ...state.authInfo, error: payload.error, loading: false } }
    }
    case GET_AUTH_INFO_SUCCESS: {
      return { ...state, authInfo: { ...state.authInfo, ...payload.authInfo, loading: false } }
    }
    case GET_PUBLIC_TOKEN:
    case GET_TOKEN: {
      return { ...state, gettingToken: true, error: false, accessToken: undefined }
    }
    case GET_PUBLIC_TOKEN_ERROR:
    case GET_TOKEN_ERROR: {
      return { ...state, gettingToken: false, error: true }
    }
    case GET_PUBLIC_TOKEN_SUCCESS:
    case GET_TOKEN_SUCCESS: {
      return { ...state, gettingToken: false, accessToken: payload.accessToken, error: false }
    }
    default: {
      return state
    }
  }
}

export default authorizationProviderReducer
