import React from 'react'
import PropTypes from 'prop-types'

import { Button, Dialog, Typography } from '@chilecompra/react-kit/components'
import { ContainerIconCircle, GridContainer, GridItem, MainCircle, StyledCheckIcon } from '../DirectDealStepTwo.styles'

/**
 * The DirectDealStepTwo's saved dialog.
 */
const DirectDealStepTwoSavedDialog = props => {
  const { onClose, open } = props

  return (
    <Dialog open={open} disableBackdropClick verticalAlign="top" onClose={onClose}>
      <GridContainer direction="row" justifyContent="center" alignItems="center">
        <GridItem textAlign="center" xs={12}>
          <ContainerIconCircle style={{ display: 'flex', justifyContent: 'center' }} margin="0 0 18px">
            <MainCircle backgroundColor="success">
              <StyledCheckIcon />
            </MainCircle>
          </ContainerIconCircle>
          <Typography variant="h3" fontWeight="bold" color="black1">
            Ficha guardada con éxito
          </Typography>
        </GridItem>
        <GridItem textAlign="center" xs={12} margin="2px 0 0">
          <Typography variant="body2" color="black1">
            Se han guardado todos los datos en la ficha de Trato Directo agregados hasta ahora.
          </Typography>
        </GridItem>
        <GridItem padding="0 50px" margin="38px 0 4px" textAlign="center" xs={12}>
          <Button aria-label="Volver" variant="outlined" color="default" onClick={onClose}>
            Volver
          </Button>
        </GridItem>
      </GridContainer>
    </Dialog>
  )
}

DirectDealStepTwoSavedDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
}

export default DirectDealStepTwoSavedDialog
