import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@chilecompra/react-kit/components'

import { GridContainer, GridItem } from './DirectDealStepZero.styles'
import { useViewport } from '@chilecompra/react-kit'

/**
 * The DirectDealStepZeroFooter's container.
 */
const DirectDealStepZeroFooter = props => {
  const { onBack, onContinue } = props
  const { size } = useViewport()

  return (
    <GridContainer margin="32px 0">
      <GridItem
        lg={2}
        md={2}
        sm={12}
        xs={12}
        textAlign={size.isSmall || size.isTiny ? 'center' : 'left'}
        order={{ lg: 1, md: 1, sm: 2, xs: 2 }}
      >
        <Button aria-label="Volver" variant="outlined" onClick={onBack} width="100%">
          Volver
        </Button>
      </GridItem>
      <GridItem
        lg={6}
        md={6}
        sm={12}
        xs={12}
        textAlign={size.isSmall || size.isTiny ? 'center' : 'right'}
        order={{ lg: 2, md: 2, sm: 1, xs: 1 }}
      >
        <Button
          aria-label="Siguiente"
          color="primary"
          variant="contained"
          onClick={onContinue}
          width={size.isSmall || size.isTiny ? '100%' : '40%'}
        >
          Siguiente
        </Button>
      </GridItem>
    </GridContainer>
  )
}

DirectDealStepZeroFooter.propTypes = {
  onBack: PropTypes.func,
  onContinue: PropTypes.func
}

export default DirectDealStepZeroFooter
