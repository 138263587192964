import { CURRENCY_TRANSLATION } from '../settings/constants'
import { formatCurrency } from '@chilecompra/react-kit'

/**
 * Action Creators' generator.
 *
 * Reference: https://redux.js.org/recipes/reducing-boilerplate#generating-action-creators
 */
export const makeActionCreator = (type, ...argNames) => {
  return (...args) => {
    const action = { type }

    argNames.forEach((_, index) => {
      action[argNames[index]] = args[index]
    })

    return action
  }
}

/**
 * To format a value with some currency format
 */
export const currencyFormat = (currency, value, withoutSymbol = false) => {
  if (!currency || (!value && value < 0)) {
    return
  }

  if (!CURRENCY_TRANSLATION[currency]) {
    return
  }

  const valueWithoutSymbol = String(value)
    .replace(/[\p{L}/$]/gu, '')
    .replace(/\./g, '')

  const formattedValue = formatCurrency(
    valueWithoutSymbol,
    !withoutSymbol ? CURRENCY_TRANSLATION[currency].symbol : '',
    CURRENCY_TRANSLATION[currency].maximumSignificantDigits,
    CURRENCY_TRANSLATION[currency].thousandSeparator,
    CURRENCY_TRANSLATION[currency].decimalSeparator
  )

  const formattedValueWithoutSpaces = formattedValue.replace(/\s/g, '')
  return formattedValueWithoutSpaces
}
