import {
  factoryService,
  getJsonRequest,
  postJsonRequest,
  putJsonRequest,
  deleteJsonRequest
} from '@chilecompra/react-kit/requests'
import { REACT_APP_API_BASE_URL } from '../config/settings/environment'

/**
 * Gets the list of quotes from sheet direct deal
 */
export const getQuotesDirectDeal = factoryService(({ sheetId }) => {
  return getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/cotizacion?codigoTratoDirecto=${sheetId}`)
})

/**
 * POST: Generate new quote by direct deal sheet
 */
export const postGenerateQuote = factoryService(({ sheetCode }) => {
  const body = {
    codigoTratoDirecto: sheetCode
  }
  return postJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/cotizacion`, { body })
})

/**
 * PUT: saved quote by direct deal sheet
 */
export const putSavedQuote = factoryService(({ payload }) => {
  const body = {
    rutCotizante: payload.contributorDni,
    nombreCotizante: payload.contributorLegalName,
    telefonoCotizante: payload.contributorPhone,
    correoCotizante: payload.contributorEmail
  }
  return putJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/cotizacion/${payload.idQuote}`, { body })
})

/**
 * DELETE: delete quote by direct deal sheet
 */
export const deleteQuote = factoryService(({ idQuote }) => {
  return deleteJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/cotizacion?idCotizacion=${idQuote}`)
})
