import { batch } from 'react-redux'

import { makeActionCreator } from '../../config/store/utils'

import { getIdentity, getUTM } from '../../services/config'
import { postDirectDealCreate } from '../../services/directdeal'
import { getCauses } from '../../services/causes'

import { onShowSnackbar } from '../SnackBarProvider/SnackBarProvider.actions'

export const GET_INFO_DNI = 'GET_INFO_DNI'
export const GET_INFO_DNI_SUCCESS = 'GET_INFO_DNI_SUCCESS'
export const GET_INFO_DNI_ERROR = 'GET_INFO_DNI_ERROR'

export const onGetInfoDNI = makeActionCreator(GET_INFO_DNI, 'payload')
export const onGetInfoDNISuccess = makeActionCreator(GET_INFO_DNI_SUCCESS, 'payload')
export const onGetInfoDNIError = makeActionCreator(GET_INFO_DNI_ERROR, 'payload')

export const onGetInfoDNIThunk = payload => async dispatch => {
  dispatch(onGetInfoDNI())

  try {
    const { data } = await getIdentity(payload)

    return dispatch(
      onGetInfoDNISuccess({
        names: data?.payload.nombres || '',
        lastNames: `${data?.payload.apellidoPaterno || ''} ${data?.payload.apellidoMaterno || ''}`
      })
    )
  } catch (err) {
    return batch(() => {
      dispatch(onGetInfoDNIError({ err }))
      dispatch(
        onShowSnackbar({
          title: 'No ha sido posible encontrar información',
          message: 'Te pedimos que lo vuelvas a intentar dentro de unos minutos.',
          severity: 'error'
        })
      )
    })
  }
}

export const CREATE_DIRECT_DEAL = 'CREATE_DIRECT_DEAL'
export const CREATE_DIRECT_DEAL_SUCCESS = 'CREATE_DIRECT_DEAL_SUCCESS'
export const CREATE_DIRECT_DEAL_ERROR = 'CREATE_DIRECT_DEAL_ERROR'

export const onCreateDirectDeal = makeActionCreator(CREATE_DIRECT_DEAL, 'payload')
export const onCreateDirectDealSuccess = makeActionCreator(CREATE_DIRECT_DEAL_SUCCESS, 'payload')
export const onCreateDirectDealError = makeActionCreator(CREATE_DIRECT_DEAL_ERROR, 'payload')

export const onCreateDirectDealThunk = payload => async dispatch => {
  dispatch(onCreateDirectDeal())
  try {
    const { data } = await postDirectDealCreate({
      sheetStepOne: payload?.stepOne,
      sheetStepZero: payload?.stepZero,
      actionType: CREATE_DIRECT_DEAL
    })

    return batch(() => {
      dispatch(onCreateDirectDealSuccess({ sheetCode: data?.payload.codigoTratoDirecto }))
      dispatch(onOpenConfirmStepOneDialog())
      payload.callback({
        sheet: {
          ...payload?.stepOne,
          id: data?.payload.id,
          code: data?.payload.codigoTratoDirecto,
          resolution: {
            subBusiness: data?.payload?.adjuntoResolucionDecreto?.idBusiness,
            idBusiness: data?.payload?.adjuntoResolucionDecreto?.idAdjunto
          },
          report: {
            subBusiness: data?.payload?.adjuntoCausal?.idBusiness,
            idBusiness: data?.payload?.adjuntoCausal?.idAdjunto
          },
          optional: {
            subBusiness: data?.payload?.adjuntoOpcional?.idBusiness,
            idBusiness: data?.payload?.adjuntoOpcional?.idAdjunto
          },
          originDirectDeal: payload?.stepZero?.originDirectDeal
        }
      })
    })
  } catch (err) {
    return dispatch(onCreateDirectDealError({ err }))
  }
}

export const GET_UTM_STEP_ONE = 'GET_UTM_STEP_ONE'
export const GET_UTM_STEP_ONE_SUCCESS = 'GET_UTM_STEP_ONE_SUCCESS'
export const GET_UTM_STEP_ONE_ERROR = 'GET_UTM_STEP_ONE_ERROR'

export const onGetUTMStepOne = makeActionCreator(GET_UTM_STEP_ONE)
export const onGetUTMStepOneSuccess = makeActionCreator(GET_UTM_STEP_ONE_SUCCESS, 'payload')
export const onGetUTMStepOneError = makeActionCreator(GET_UTM_STEP_ONE_ERROR, 'payload')

export const onGetUTMStepOneThunk = () => async dispatch => {
  dispatch(onGetUTMStepOne())

  try {
    const { data } = await getUTM()

    return dispatch(
      onGetUTMStepOneSuccess({
        date: data?.payload?.fecha,
        valueCLP: data?.payload?.valorPeso || 0,
        valueCLPRef: data?.payload?.valorReferencial || 0
      })
    )
  } catch (err) {
    return dispatch(onGetUTMStepOneError({ err }))
  }
}

export const OPEN_CONFIRM_STEP_ONE_DIALOG = 'OPEN_CONFIRM_STEP_ONE_DIALOG'
export const onOpenConfirmStepOneDialog = makeActionCreator(OPEN_CONFIRM_STEP_ONE_DIALOG)

export const CLOSE_CONFIRM_STEP_ONE_DIALOG = 'CLOSE_CONFIRM_STEP_ONE_DIALOG'
export const onCloseConfirmStepOneDialog = makeActionCreator(CLOSE_CONFIRM_STEP_ONE_DIALOG)

export const GET_DESCRIPTION_CAUSE_STEP_ONE = 'GET_DESCRIPTION_CAUSE_STEP_ONE'
export const GET_DESCRIPTION_CAUSE_STEP_ONE_SUCCESS = 'GET_DESCRIPTION_CAUSE_STEP_ONE_SUCCESS'
export const GET_DESCRIPTION_CAUSE_STEP_ONE_ERROR = 'GET_DESCRIPTION_CAUSE_STEP_ONE_ERROR'

export const onGetDescriptionCausesStepOne = makeActionCreator(GET_DESCRIPTION_CAUSE_STEP_ONE)
export const onGetDescriptionCausesStepOneSuccess = makeActionCreator(GET_DESCRIPTION_CAUSE_STEP_ONE_SUCCESS, 'payload')
export const onGetDescriptionCausesStepOneError = makeActionCreator(GET_DESCRIPTION_CAUSE_STEP_ONE_ERROR, 'payload')

export const onGetDescriptionCausesStepOneThunk = payload => async dispatch => {
  dispatch(onGetDescriptionCausesStepOne())

  try {
    const { data } = await getCauses({ filtrofecha: payload })
    return dispatch(
      onGetDescriptionCausesStepOneSuccess({
        causes: data?.payload
          ?.filter(cause => cause?.descCausal !== 'Todos')
          ?.map(cause => {
            return {
              value: cause?.idCausal,
              name: cause?.descCausal || '',
              description: cause?.informativo || '',
              publicity: cause?.publicidad || '',
              over1000Utm: cause?.mayor1000UTM
            }
          })
      })
    )
  } catch (err) {
    return batch(() => {
      dispatch(onGetDescriptionCausesStepOneError({ err }))
      dispatch(
        onShowSnackbar({
          title: 'No ha seleccionado opción en paso 0',
          message: 'Te pedimos que selecciones una opción en el paso cero.',
          severity: 'error'
        })
      )
    })
  }
}

export const RESET_STEP_ONE = 'RESET_STEP_ONE'
export const onResetStepOne = makeActionCreator(RESET_STEP_ONE)

export const SAVED_INFO_STEP_ZERO_DIRECT_DEAL_STEP_ONE = 'SAVED_INFO_STEP_ZERO_DIRECT_DEAL_STEP_ONE'
export const onSavedInfoStepZeroDirectDealStepOne = makeActionCreator(
  SAVED_INFO_STEP_ZERO_DIRECT_DEAL_STEP_ONE,
  'payload'
)

export const CLEAN_INFO_STEP_ZERO_DIRECT_DEAL_STEP_ONE = 'CLEAN_INFO_STEP_ZERO_DIRECT_DEAL_STEP_ONE'
export const onCleanInfoStepZeroDirectDealStepOne = makeActionCreator(CLEAN_INFO_STEP_ZERO_DIRECT_DEAL_STEP_ONE)
