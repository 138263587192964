import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Card, Typography } from '@chilecompra/react-kit'

import FormattedCurrency from './../../../components/components/FormattedCurrency.component'

import { GridContainer, GridItem } from '../DirectDealStepTwo.styles'

import { useImperativeFormRef } from '../../../modules/hooks/useFormRef'

/**
 * The DirectDealStepTwo totals container.
 */
const DirectDealStepTwoTotals = props => {
  const { currency, formStepTwoRef, products, onCalculate } = props
  const [netTotal, setNetTotal] = useState(0)

  const calculateProductsTotalNet = productsUpdate => {
    const total = productsUpdate.reduce((acc, product) => {
      const { amount, unitPrice, discount, charge, discountOrCharge } = product
      return (
        acc +
        onCalculate({
          item: {
            amount,
            unit: unitPrice,
            currencyType: currency?.type,
            discountOrCharge,
            discount,
            charge
          }
        })
      )
    }, 0)
    setNetTotal(total)
  }

  useEffect(() => {
    calculateProductsTotalNet(products)
  }, [products, currency])

  useImperativeFormRef(
    formStepTwoRef,
    () => ({
      netTotal
    }),
    [netTotal]
  )

  return (
    <Card padding="17px 0">
      <GridContainer>
        <GridItem xs={12}>
          <Typography variant="body2" color="black1">
            Total Neto
          </Typography>
          <Typography variant="subtitle" color="black1" margin="0 0 8px">
            con descuento o cargo
          </Typography>
          <Typography variant="body1" color="black1">
            <FormattedCurrency amount={netTotal} includeSymbol currency={currency?.type} />
          </Typography>
        </GridItem>
      </GridContainer>
    </Card>
  )
}

DirectDealStepTwoTotals.propTypes = {
  currency: PropTypes.shape({
    value: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
    symbol: PropTypes.string
  }),
  formStepTwoRef: PropTypes.shape({
    current: PropTypes.shape(Object)
  }),
  products: PropTypes.arrayOf(
    PropTypes.shape({
      amount: PropTypes.number,
      categoryId: PropTypes.number,
      charge: PropTypes.number,
      codeZGEN: PropTypes.string,
      detail: PropTypes.string,
      discount: PropTypes.number,
      discountOrCharge: PropTypes.string,
      dontKnowCodeZGEN: PropTypes.bool,
      isInvalid: PropTypes.bool,
      productId: PropTypes.number,
      productKey: PropTypes.string,
      productName: PropTypes.string,
      unitMeasure: PropTypes.string,
      unitPrice: PropTypes.number,
      withDiscountOrCharge: PropTypes.bool
    })
  ),
  onCalculate: PropTypes.func
}

export default DirectDealStepTwoTotals
