import React, { useEffect, useMemo, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import { getCookie, useEffectOnce, usePathParam } from '@chilecompra/react-kit'

import DirectDealSummaryContractDialog from './Dialogs/DirectDealSummary.contract.dialog'
import DirectDealSummaryIssueDialog from './Dialogs/DirectDealSummary.issue.dialog'
import DirectDealSummaryRequestDialog from './Dialogs/DirectDealSummary.request.dialog'

import DirectDealSummaryDecision from './DirectDealSummary.decision'
import DirectDealSummaryDetail from './DirectDealSummary.detail'
import DirectDealSummaryFooter from './DirectDealSummary.footer'
import DirectDealSummaryHeader from './DirectDealSummary.header'
import DirectDealSummaryInfoDecision from './DirectDealSummary.information'
import DirectDealSummaryProducts from './DirectDealSummary.products'
import DirectDealSummaryPurchases from './DirectDealSummary.purchases'
import DirectDealSummaryQuotes from './DirectDealSummary.quotes'
import DirectDealSummaryRequests from './DirectDealSummary.requests'
import DirectDealSummaryRequestsList from './DirectDealSummary.requestsList'

import { useAuthorization } from '../AuthProvider/AuthProvider.hooks'

import {
  onApplyDecisionDirectDealSummaryThunk,
  onCleanDirectDealSummary,
  onCloseContractDetailDialogDirectDealSummary,
  onCloseIssueDialogDirectDealSummary,
  onCreateContractDirectDealSummaryThunk,
  onGetContractRequestsDirectDealSummaryThunk,
  onGetDetailDirectDealSummaryThunk,
  onGetInfoProviderDirectDealSummaryThunk,
  onGetLawsuitsThunk,
  onGetQuotesDirectDealSummaryThunk,
  onIssuePurchaseDirectDealSummaryThunk,
  onOpenContractDetailDialogDirectDealSummary,
  onOpenContractDialogDirectDealSummary,
  onOpenIssueDialogDirectDealSummary,
  onPrepareContractDirectDealSummaryThunk,
  onGetPublicTokenDirectDealSummaryThunk
} from './DirectDealSummary.actions'

import { Root } from './DirectDealSummary.styles'

import { dialogScrollTop } from '../../modules/utils/dialogScrollTop'
import { useFormRef } from '../../modules/hooks/useFormRef'

import { REACT_APP_PROVIDERS_URL, REACT_APP_MERCADO_PUBLICO_BASE_URL } from '../../config/settings/environment'
import {
  DIRECT_DEAL_SHEET_STATUS_DICTIONARY,
  DIRECT_DEAL_SUMMARY_DECISION_ISSUE_PURCHASE_DICTIONARY
} from '../../config/settings/constants'
import routes from '../../config/settings/routes'

/**
 * The DirectDealSummary's container.
 */
const DirectDealSummaryContainer = () => {
  const { isBuyer, isSeller, userDni } = useAuthorization()
  const dispatch = useDispatch()
  const directDealId = usePathParam('directDealId')
  const navigate = useNavigate()
  const { state } = useLocation()

  const { contracts, issue, loading, provider, quotes, sheet, lawsuitAmount } = useSelector(
    state => state.directDealSummary
  )

  const formCreateRequestRef = useFormRef()
  const formDecisionRef = useFormRef()

  const [requestContractId, setRequestContractId] = useState(null)

  const handlerBack = () => {
    const query = getCookie('search')

    if (state && state.from === routes.searchPublic) {
      navigate({ pathname: routes.searchPublic, search: query.toString() }, { replace: true })
    } else {
      navigate({ pathname: routes.search, search: query.toString() }, { replace: true })
    }
  }

  const handlerCreateRequest = () => {
    dispatch(
      onCreateContractDirectDealSummaryThunk({
        requestAnswer: formCreateRequestRef.current.answerRequestValue.value,
        requestDni: formCreateRequestRef.current.dniRequestValue,
        requestId: contracts?.sheetIdRequesting,
        requestLegalName: formCreateRequestRef.current.businessRequestValue
      })
    )

    if (formCreateRequestRef.current) {
      formCreateRequestRef.current.resetField('answerRequestValue')
      formCreateRequestRef.current.cleanErrorField('answerRequestValue')
    }
  }

  const handlerOpenIssueDialog = () => {
    dispatch(onOpenIssueDialogDirectDealSummary())
    dialogScrollTop()
  }

  const handlerCloseIssuePurchase = () => {
    dispatch(onCloseIssueDialogDirectDealSummary())
  }

  const handlerRedirectProviderSheet = () => {
    dispatch(
      onGetPublicTokenDirectDealSummaryThunk({
        callback: publicAccess =>
          window.open(
            `${REACT_APP_PROVIDERS_URL}/ficha/callback?access_token=${publicAccess.token}&rut=${sheet?.providerDni}&expires_in=${publicAccess.expires_in}`
          )
      })
    )
  }

  const handlerIssuePurchase = async () => {
    if (
      formDecisionRef.current.valueRadioDecision ===
      DIRECT_DEAL_SUMMARY_DECISION_ISSUE_PURCHASE_DICTIONARY.CONTINUE_WITH_ORIGINAL_PROVIDER
    ) {
      dispatch(
        onApplyDecisionDirectDealSummaryThunk({
          decision: {
            sheetId: sheet?.id,
            mechanism: formDecisionRef.current.mechanismValue,
            decision: formDecisionRef.current.valueRadioDecision,
            description: formDecisionRef.current.reasonValue
          },
          callback: () => {
            dispatch(onIssuePurchaseDirectDealSummaryThunk({ sheet: { code: sheet?.code, total: sheet?.totalAmount } }))
          }
        })
      )
    } else {
      dispatch(onIssuePurchaseDirectDealSummaryThunk({ sheet: { code: sheet?.code, total: sheet?.totalAmount } }))
    }
  }

  const handlerRedirectStepOnePurchaseSheet = id => {
    window.location.href = `${REACT_APP_MERCADO_PUBLICO_BASE_URL}/PurchaseOrder/WizardCreateAndRedirect.aspx?POMODE=POEdit&porId=${id}`
  }

  const handlerOpenRequest = () => {
    dispatch(
      onPrepareContractDirectDealSummaryThunk({
        sheetId: sheet?.id,
        callback: () => {
          dispatch(onOpenContractDialogDirectDealSummary())
          dispatch(onGetInfoProviderDirectDealSummaryThunk(userDni))
          dialogScrollTop()
        }
      })
    )
  }

  const handlerCloseRequestDetail = () => {
    dispatch(onCloseContractDetailDialogDirectDealSummary())
  }

  const handlerOpenRequestDetail = request => {
    setRequestContractId(request?.id)
    dispatch(onOpenContractDetailDialogDirectDealSummary())
    dialogScrollTop()
  }
  const handlerDesist = () => {
    dispatch(
      onApplyDecisionDirectDealSummaryThunk({
        decision: {
          sheetId: sheet?.id,
          mechanism: formDecisionRef.current.mechanismValue,
          decision: formDecisionRef.current.valueRadioDecision,
          description: formDecisionRef.current.reasonValue
        }
      })
    )
  }

  const disabledRequest = useMemo(() => {
    return (
      formCreateRequestRef.current.answerRequestValue?.value === '' ||
      formCreateRequestRef.current.answerRequestValue?.error !== '' ||
      formCreateRequestRef.current.businessRequestValue === '' ||
      formCreateRequestRef.current.businessRequestError !== '' ||
      formCreateRequestRef.current.dniRequestValue === '' ||
      formCreateRequestRef.current.dniRequestError !== ''
    )
  }, [
    formCreateRequestRef.current.answerRequestValue?.value,
    formCreateRequestRef.current.answerRequestValue?.error,
    formCreateRequestRef.current.businessRequestValue,
    formCreateRequestRef.current.businessRequestError,
    formCreateRequestRef.current.dniRequestValue,
    formCreateRequestRef.current.dniRequestError
  ])

  const disabledIssue = useMemo(() => {
    return (
      formDecisionRef.current.valueRadioDecision === '' ||
      (formDecisionRef.current.valueRadioDecision ===
        DIRECT_DEAL_SUMMARY_DECISION_ISSUE_PURCHASE_DICTIONARY.CONTINUE_WITH_ORIGINAL_PROVIDER &&
        (formDecisionRef.current.reasonValue === '' || formDecisionRef.current.reasonError !== ''))
    )
  }, [
    formDecisionRef.current.reasonError,
    formDecisionRef.current.reasonValue,
    formDecisionRef.current.valueRadioDecision
  ])

  const disabledDesist = useMemo(() => {
    return (
      formDecisionRef.current.valueRadioDecision === '' ||
      (formDecisionRef.current.valueRadioDecision ===
        DIRECT_DEAL_SUMMARY_DECISION_ISSUE_PURCHASE_DICTIONARY.USE_ANOTHER_MECHANISM &&
        (formDecisionRef.current.mechanismValue === '' ||
          formDecisionRef.current.mechanismError !== '' ||
          formDecisionRef.current.reasonValue === '' ||
          formDecisionRef.current.reasonError !== '')) ||
      (formDecisionRef.current.valueRadioDecision ===
        DIRECT_DEAL_SUMMARY_DECISION_ISSUE_PURCHASE_DICTIONARY.STOP_PROCESS &&
        (formDecisionRef.current.reasonValue === '' || formDecisionRef.current.reasonError !== ''))
    )
  }, [
    formDecisionRef.current.mechanismError,
    formDecisionRef.current.mechanismValue,
    formDecisionRef.current.reasonError,
    formDecisionRef.current.reasonValue,
    formDecisionRef.current.valueRadioDecision
  ])

  useEffectOnce(() => {
    batch(() => {
      dispatch(onCloseIssueDialogDirectDealSummary())
      dispatch(onGetDetailDirectDealSummaryThunk(directDealId))
      dispatch(onGetQuotesDirectDealSummaryThunk(directDealId))
      dispatch(onGetContractRequestsDirectDealSummaryThunk(directDealId))
      dispatch(onGetLawsuitsThunk(directDealId))
    })
  })

  useEffect(() => {
    dispatch(onCleanDirectDealSummary())
  }, [dispatch])

  return (
    <Root>
      <DirectDealSummaryHeader sheetCode={sheet?.code} status={sheet?.status} loading={loading} />

      <DirectDealSummaryDetail
        lawsuitAmount={lawsuitAmount.lawsuits}
        loading={loading}
        onRedirect={handlerRedirectProviderSheet}
        sheet={sheet}
      />

      {sheet?.products && sheet?.products.length > 0 && (
        <DirectDealSummaryProducts products={sheet?.products} loading={loading} currency={sheet?.currency} />
      )}
      {quotes && quotes.length > 0 && <DirectDealSummaryQuotes quotes={quotes} loading={loading} />}

      {((isSeller && sheet?.status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.CLOSED) ||
        (!isSeller &&
          (sheet?.status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.RECEIVING_REQUEST ||
            sheet?.status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.CLOSED))) && (
        <DirectDealSummaryRequestsList
          contracts={contracts}
          loading={loading}
          isSeller={isSeller}
          isBuyer={isBuyer}
          status={sheet?.status}
        />
      )}

      {isSeller && sheet?.status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.RECEIVING_REQUEST && sheet?.withPublicity && (
        <DirectDealSummaryRequests
          contracts={contracts}
          loading={loading}
          onOpenDetail={handlerOpenRequestDetail}
          onOpenForm={handlerOpenRequest}
        />
      )}
      {isBuyer && sheet?.status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.CLOSED && sheet?.withPublicity && (
        <DirectDealSummaryDecision formRef={formDecisionRef} loading={loading} contracts={contracts} />
      )}

      {(sheet?.status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.ISSUE_PURCHASE ||
        sheet?.status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.CANCEL) &&
        sheet?.withPublicity && <DirectDealSummaryInfoDecision sheet={sheet} contracts={contracts} loading={loading} />}

      {sheet?.purchases && sheet?.status === DIRECT_DEAL_SHEET_STATUS_DICTIONARY.ISSUE_PURCHASE && (
        <DirectDealSummaryPurchases
          currency={sheet?.currency}
          purchases={sheet?.purchases}
          loading={loading}
          isBuyer={isBuyer}
          isSeller={isSeller}
        />
      )}

      <DirectDealSummaryFooter
        disabledDesist={disabledDesist}
        disabledIssue={disabledIssue}
        disabledRequest={disabledRequest}
        formDecisionRef={formDecisionRef}
        isBuyer={isBuyer}
        loading={loading}
        status={sheet?.status}
        onBack={handlerBack}
        onDesist={handlerDesist}
        onIssue={handlerOpenIssueDialog}
      />

      <DirectDealSummaryIssueDialog
        issue={issue}
        loading={loading}
        providerDni={sheet?.providerDni}
        providerName={sheet?.providerName}
        total={sheet?.totalAmount}
        onClosed={handlerCloseIssuePurchase}
        onIssue={handlerIssuePurchase}
        onRedirect={handlerRedirectStepOnePurchaseSheet}
      />
      <DirectDealSummaryContractDialog
        contracts={contracts}
        formRef={formCreateRequestRef}
        loading={loading}
        provider={provider}
        userDni={userDni}
        onSend={handlerCreateRequest}
      />
      <DirectDealSummaryRequestDialog
        contracts={contracts}
        loading={loading}
        requestId={requestContractId}
        onClosed={handlerCloseRequestDetail}
      />
    </Root>
  )
}

export default DirectDealSummaryContainer
