import React, { useMemo, useState } from 'react'
import { batch, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { Button, Dialog, Input, Typography } from '@chilecompra/react-kit/components'
import {
  checkInputErrorRequired,
  checkInputErrorMaxLength,
  checkInputErrorOnlyNumbers,
  checkInputErrorMinLength,
  checkInputErrorEmail,
  Skeleton,
  useInput,
  useViewport
} from '@chilecompra/react-kit'

import AttachmentsWrapper from '../../../components/components/AttachmentsWrapper.component'

import {
  onGetInfoDniDirectDealQuoteThunk,
  onSavedDirectDealQuoteThunk,
  onCleanQuoteDirectDeal,
  onSaveQuoteDirectDealQuote,
  onCloseQuoteDirectDealQuote
} from './DirectDealQuote.actions'
import { onShowSnackbar } from '../../SnackBarProvider/SnackBarProvider.actions'

import { AmountPrefix, Grid, SearchSuffix, SearchIconCustom } from './DirectDealQuote.styles'
import { Divider } from '../DirectDealStepTwo.styles'

import { formatDNI } from '../../../modules/utils/formatters'

import { CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL } from '../../../config/settings/constants'

/**
 * The DirectDealStepTwoQuote dialog
 */
const DirectDealStepTwoQuoteDialog = props => {
  const { open, quote, loading } = props

  const dispatch = useDispatch()
  const { size } = useViewport()

  const [openModalAttachQuote, setOpenModalAttachQuote] = useState(false)
  const [openModalDeleteAllQuote, setOpenModalDeleteAllQuote] = useState(false)

  const {
    error: quoteDniError,
    onChange: onChangeQuoteDni,
    onError: onErrorQuoteDni,
    onReset: onResetQuoteDni,
    value: quoteDniValue
  } = useInput({
    changeCallback: () => setQuoteLegalName(''),
    errorCallbacks: [checkInputErrorRequired()],
    formatCallbacks: [value => formatDNI(value)],
    initialValue: ''
  })

  const {
    error: quoteLegalNameError,
    onChange: onChangeQuoteLegalName,
    onReset: onResetQuoteLegalName,
    value: quoteLegalNameValue,
    setValue: setQuoteLegalName
  } = useInput({
    errorCallbacks: [checkInputErrorRequired()],
    initialValue: ''
  })

  const {
    error: quotePhoneError,
    onChange: onChangeQuotePhone,
    onError: onErrorQuotePhone,
    onReset: onResetQuotePhone,
    value: quotePhoneValue
  } = useInput({
    errorCallbacks: [checkInputErrorOnlyNumbers(), checkInputErrorMaxLength(9), checkInputErrorMinLength(9)],
    initialValue: ''
  })

  const {
    error: quoteEmailError,
    onChange: onChangeQuoteEmail,
    onError: onErrorQuoteEmail,
    onReset: onResetQuoteEmail,
    value: quoteEmailValue
  } = useInput({
    errorCallbacks: [checkInputErrorMaxLength(200), checkInputErrorEmail('Formato de email incorrecto.')],
    initialValue: ''
  })

  const handlerGetLegalName = () => {
    dispatch(
      onGetInfoDniDirectDealQuoteThunk({
        quoteDni: quoteDniValue,
        callback: legalName => {
          onResetQuoteLegalName()
          setQuoteLegalName(legalName)
        },
        callbackError: () => {
          setQuoteLegalName('')
          dispatch(
            onShowSnackbar({
              title: 'Proveedor no encontrado',
              message: 'Te pedimos que lo vuelvas a intentar.',
              severity: 'error'
            })
          )
        }
      })
    )
  }

  const handlerSavedQuote = () => {
    const quoteModel = {
      ...quote,
      idQuote: quote?.idQuote,
      contributorDni: quoteDniValue,
      contributorLegalName: quoteLegalNameValue,
      contributorPhone: quotePhoneValue,
      contributorEmail: quoteEmailValue
    }

    dispatch(
      onSavedDirectDealQuoteThunk({
        quoteModel,
        callback: quoteModel => {
          batch(() => {
            dispatch(onSaveQuoteDirectDealQuote(quoteModel))
            dispatch(
              onShowSnackbar({
                title: 'Cotización agregada con éxito',
                severity: 'success'
              })
            )
            handlerCloseQuote()
          })
        },
        callbackError: ({ err }) => {
          dispatch(
            onShowSnackbar({
              title: err.descripcion,
              message: 'Te pedimos que lo vuelvas a intentar dentro de unos minutos.',
              severity: 'error'
            })
          )
        }
      })
    )
  }

  const handlerCloseQuote = () => {
    batch(() => {
      dispatch(onCloseQuoteDirectDealQuote())
      dispatch(onCleanQuoteDirectDeal())
      onResetQuoteDni()
      onResetQuoteLegalName()
      onResetQuoteEmail()
      onResetQuotePhone()
    })
  }

  const disableSaved = useMemo(() => {
    return (
      quoteDniValue === '' ||
      quoteDniError !== '' ||
      quoteLegalNameValue === '' ||
      quoteLegalNameError !== '' ||
      (quotePhoneValue !== '' && quotePhoneError !== '') ||
      (quoteEmailValue !== '' && quoteEmailError !== '')
    )
  }, [
    quoteDniValue,
    quoteDniError,
    quoteLegalNameValue,
    quoteLegalNameError,
    quotePhoneValue,
    quotePhoneError,
    quoteEmailValue,
    quoteEmailError
  ])

  return (
    <Dialog
      open={open}
      disableBackdropClick
      verticalAlign="top"
      maxWidth={size.isTiny || size.isSmall ? '400px' : '600px'}
      onClose={handlerCloseQuote}
    >
      <Grid container spacing={2} margin="8px 0 0">
        <Grid item margin="0 0 4px">
          <Typography variant="h3" fontWeight="bold" color="black1" lineHeight="18px" margin="8px 0">
            Crear nueva cotizacion
          </Typography>
          <Divider margin="16px 0" />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Input
            error={quoteDniError}
            label="RUT cotizante"
            loading={loading}
            suffix={
              <SearchSuffix onClick={handlerGetLegalName}>
                <SearchIconCustom />
              </SearchSuffix>
            }
            onChange={onChangeQuoteDni}
            onError={onErrorQuoteDni}
            value={quoteDniValue}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Input
            disabled
            error={quoteLegalNameError}
            label="Razón social cotizante"
            loading={loading}
            onChange={onChangeQuoteLegalName}
            value={quoteLegalNameValue}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Input
            error={quotePhoneError}
            label="Teléfono cotizante"
            loading={loading}
            prefix={<AmountPrefix> +56</AmountPrefix>}
            onChange={onChangeQuotePhone}
            onError={onErrorQuotePhone}
            value={quotePhoneValue}
          />
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Input
            error={quoteEmailError}
            label="Email cotizante"
            loading={loading}
            type="email"
            onChange={onChangeQuoteEmail}
            onError={onErrorQuoteEmail}
            value={quoteEmailValue}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight="bold">
            Adjuntar archivos
          </Typography>
          <Typography variant="body2">Se admiten archivos en formato con un peso máximo de 20mb.</Typography>

          {!loading && quote && (
            <AttachmentsWrapper
              business={CODE_BUSINESS_ATTACHAMENTS_DIRECT_DEAL}
              idBusiness={quote.idBusiness}
              subBusiness={quote.idSubBusiness}
              isPrivate
              color="blank"
              canDelete
              canRead
              canWrite
              canWriteBulk
              openModal={openModalAttachQuote}
              openModalDeleteAll={openModalDeleteAllQuote}
              setOpenModal={setOpenModalAttachQuote}
              setOpenModalDeleteAll={setOpenModalDeleteAllQuote}
            />
          )}

          {loading && <Skeleton height={40} variant="rectangular" width={200} />}
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12} textAlign="left" order={{ lg: 1, md: 1, sm: 1, xs: 2 }}>
          <Button aria-label="Volver" width={size.isSmall || size.isTiny ? '100%' : '70%'} onClick={handlerCloseQuote}>
            Volver
          </Button>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12} textAlign="left" order={{ lg: 2, md: 2, sm: 2, xs: 1 }}>
          <Button
            aria-label="Guardar Cotizacion"
            disabled={disableSaved}
            loading={loading}
            color="primary"
            width={size.isSmall || size.isTiny ? '100%' : '80%'}
            onClick={handlerSavedQuote}
          >
            Guardar Cotizacion
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

DirectDealStepTwoQuoteDialog.propTypes = {
  loading: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  quote: PropTypes.shape({
    idQuote: PropTypes.string,
    idSubBusiness: PropTypes.number,
    idBusiness: PropTypes.number
  })
}

export default DirectDealStepTwoQuoteDialog
