import { REACT_APP_API_BASE_URL } from '../config/settings/environment'
import { factoryService, getJsonRequest } from '@chilecompra/react-kit/requests'

/**
 * Gets the list of Causes
 */
export const getCauses = factoryService(({ filtrofecha }) =>
  getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/parametros/causales?filtrofecha=${filtrofecha}`)
)
/**
 * Gets the list of Statuses
 */
export const getStatus = factoryService(() =>
  getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/parametros/estados`)
)
