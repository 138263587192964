import React, { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector, batch } from 'react-redux'

import { getCookie, useEffectOnce } from '@chilecompra/react-kit'

import DirectDealOrigin from './DirectDealStepZero.origin'
import DirectDealStepZeroFooter from './DirectDealStepZero.footer'

import { useFormRef } from '../../modules/hooks/useFormRef'
import routes from '../../config/settings/routes'

import { onSavedInfoStepZeroDirectDealStepOne } from '../DirectDealStepOne/DirectDealStepOne.actions'
import {
  onCleanInitialSheetDealStepZero,
  onCleanSignerDealStepZero
} from '../DirectDealStepZero/DirectDealStepZero.actions'

import { Root, Divider } from './DirectDealStepZero.styles'

/**
 * The DirectDealStepOne's container.
 */
const DirectDealStepZeroContainer = () => {
  const {
    loading: directDealStepZeroLoading,
    signer: directDealStepZeroSigner,
    initialSheet: directDealStepZeroValues
  } = useSelector(state => state.directDealStepZero)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const formStepZeroRef = useFormRef()
  const { state } = useLocation()

  const isInvalidStepZero = useMemo(() => {
    return (
      formStepZeroRef.current.resolution?.value === '' ||
      formStepZeroRef.current.resolution?.error !== '' ||
      formStepZeroRef.current.dateExecute?.value === '' ||
      formStepZeroRef.current.dateExecute?.error !== '' ||
      formStepZeroRef.current.signer?.value === '' ||
      formStepZeroRef.current.signer?.error !== '' ||
      formStepZeroRef.current.name?.error !== '' ||
      formStepZeroRef.current.name?.value === '' ||
      formStepZeroRef.current.lastName?.error !== '' ||
      formStepZeroRef.current.lastName?.value === '' ||
      formStepZeroRef.current.charge?.value === '' ||
      formStepZeroRef.current.charge?.error !== '' ||
      formStepZeroRef.current.origin?.value === ''
    )
  }, [
    formStepZeroRef.current.resolution?.value,
    formStepZeroRef.current.resolution?.error,
    formStepZeroRef.current.dateExecute?.value,
    formStepZeroRef.current.dateExecute?.error,
    formStepZeroRef.current.signer?.value,
    formStepZeroRef.current.signer?.error,
    formStepZeroRef.current.name?.error,
    formStepZeroRef.current.name?.value,
    formStepZeroRef.current.lastName?.error,
    formStepZeroRef.current.lastName?.value,
    formStepZeroRef.current.charge?.value,
    formStepZeroRef.current.charge?.error,
    formStepZeroRef.current.origin?.value
  ])

  const handlerBack = () => {
    batch(() => {
      const query = getCookie('search')

      dispatch(onCleanSignerDealStepZero())
      dispatch(onCleanInitialSheetDealStepZero())
      navigate({ pathname: routes.search, search: query.toString() }, { replace: true })
    })
  }

  const handlerNext = () => {
    if (isInvalidStepZero) {
      formStepZeroRef.current?.resolution?.onError()
      formStepZeroRef.current?.dateExecute?.onError()
      formStepZeroRef.current?.signer?.onError()
      formStepZeroRef.current?.name?.onError()
      formStepZeroRef.current?.lastName?.onError()
      formStepZeroRef.current?.charge?.onError()

      return
    }

    batch(() => {
      dispatch(
        onSavedInfoStepZeroDirectDealStepOne({
          resolution: formStepZeroRef.current.resolution?.value,
          resolutionDate: formStepZeroRef.current.dateExecute?.value,
          names: formStepZeroRef.current.name?.value,
          lastNames: formStepZeroRef.current.lastName?.value,
          dni: formStepZeroRef.current.signer?.value,
          job: formStepZeroRef.current.charge?.value,
          origin: formStepZeroRef.current.origin?.value
        })
      )
      dispatch(onCleanSignerDealStepZero())
      navigate({ pathname: routes.directDealStepOne }, { replace: true })
    })
  }

  useEffectOnce(() => {
    if ((state && state.from !== routes.directDealStepOne) || !state) {
      dispatch(onCleanSignerDealStepZero())
      dispatch(onCleanInitialSheetDealStepZero())
    }
  })

  return (
    <Root>
      <Divider />
      <DirectDealOrigin
        values={directDealStepZeroValues}
        signerInfo={directDealStepZeroSigner}
        loading={directDealStepZeroLoading}
        formRef={formStepZeroRef}
      />
      <Divider />
      <DirectDealStepZeroFooter onBack={handlerBack} onContinue={handlerNext} />
    </Root>
  )
}

export default DirectDealStepZeroContainer
