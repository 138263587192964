import { customizeError } from '@chilecompra/react-kit'
import { makeActionCreator } from '../../config/store/utils'
import { getProducts } from '../../services/products'

export const GET_PRODUCTS = 'GET_PRODUCTS'
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR'
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS'
export const onGetProducts = makeActionCreator(GET_PRODUCTS)
export const onGetProductsError = makeActionCreator(GET_PRODUCTS_ERROR, 'payload')
export const onGetProductsSuccess = makeActionCreator(GET_PRODUCTS_SUCCESS, 'payload')

export const onGetProductsThunk = payload => async dispatch => {
  dispatch(onGetProducts())

  try {
    const { data } = await getProducts({ name: payload.name })

    const products = data.payload?.categorias?.map(category => ({
      name: category.nombre,
      products: category.productos.map(product => ({
        id: product.id,
        name: product.nombre,
        categoryId: product.categoria
      }))
    }))

    dispatch(onGetProductsSuccess({ products }))
  } catch (error) {
    if (error?.code === 404) {
      if (
        error?.originalError?.response?.data?.success === 'OK' &&
        error?.originalError?.response?.data?.payload === 'Not Found'
      ) {
        return dispatch(
          onGetProductsError({
            error: customizeError({
              originalError: error?.originalError,
              code: error?.code,
              reason: 'NOT_FOUND_ERROR'
            }).toObject()
          })
        )
      }
    }

    return dispatch(
      onGetProductsError({
        error: customizeError({
          originalError: error?.originalError,
          code: error?.code,
          reason: 'SOMETHING_WENT_WRONG_ERROR'
        }).toObject()
      })
    )
  }
}

export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS'
export const onAddProductSuccess = makeActionCreator(ADD_PRODUCT_SUCCESS, 'payload')

export const REMOVE_PRODUCT_SUCCESS = 'REMOVE_PRODUCT_SUCCESS'
export const onRemoveProductSuccess = makeActionCreator(REMOVE_PRODUCT_SUCCESS, 'payload')

export const UPDATE_PRODUCTS_SAVED_SUCCESS = 'UPDATE_PRODUCTS_SAVED_SUCCESS'
export const onUpdateProductsSavedSuccess = makeActionCreator(UPDATE_PRODUCTS_SAVED_SUCCESS, 'payload')

export const REMOVE_ALL_SUCCESS = 'REMOVE_ALL_SUCCESS'
export const onRemoveAllSuccess = makeActionCreator(REMOVE_ALL_SUCCESS, 'payload')

export const REMOVE_SEARCHING_PRODUCTS = 'REMOVE_SEARCHING_PRODUCTS'
export const onRemoveSearchingProducts = makeActionCreator(REMOVE_SEARCHING_PRODUCTS, 'payload')

export const SAVED_PRODUCT_SUCCESS = 'SAVED_PRODUCT_SUCCESS'
export const onSavedProductSuccess = makeActionCreator(SAVED_PRODUCT_SUCCESS, 'payload')

export const RESET_AMOUNTS_PRODUCTS_SUCCESS = 'RESET_AMOUNTS_PRODUCTS_SUCCESS'
export const onResetAmountProducts = makeActionCreator(RESET_AMOUNTS_PRODUCTS_SUCCESS)

export const INITIAL_SAVED_PRODUCTS_DIRECT_DEAL_PRODUCTS = 'INITIAL_SAVED_PRODUCTS_DIRECT_DEAL_PRODUCTS'
export const onInitialSavedProductsDirectDealProducts = makeActionCreator(
  INITIAL_SAVED_PRODUCTS_DIRECT_DEAL_PRODUCTS,
  'payload'
)
