import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Card, DataTable, Typography } from '@chilecompra/react-kit/components'

import { Grid, Link, Info } from './DirectDealSummary.styles'

import FormattedCurrency from '../../components/components/FormattedCurrency.component'
import FormattedDateComponent from '../../components/components/FormattedDate.component'

import {
  CURRENCY_TRANSLATION,
  DIRECT_DEAL_SHEET_PURCHASE_STATUS_DICTIONARY,
  DIRECT_DEAL_REDIRECT_PURCHASE_SHEET
} from '../../config/settings/constants'
import { REACT_APP_MERCADO_PUBLICO_BASE_URL } from '../../config/settings/environment'

/**
 * The DirectDealSummary's list purchases.
 */
const DirectDealSummaryPurchases = props => {
  const { currency, loading, purchases, isBuyer, isSeller } = props

  const [listPurchases, setListPurchases] = useState([])

  useEffect(() => {
    setListPurchases(
      (purchases &&
        purchases.filter(purchase => {
          if (isSeller || (!isSeller && !isBuyer)) {
            return purchase.status !== DIRECT_DEAL_SHEET_PURCHASE_STATUS_DICTIONARY.SAVED
          } else {
            return purchase
          }
        })) ||
        []
    )
  }, [purchases])

  return (
    <Grid container spacing={2} margin="0">
      <Grid item md={8} xs={12}>
        <Card enableBorder lineColor="primary" color="blank" padding="32px 24px">
          <Grid container spacing={1}>
            <Grid item margin="0 0 14px">
              <Typography fontWeight="bold" variant="h3">
                Órdenes de compra emitidas
              </Typography>
            </Grid>

            {(isSeller || (!isSeller && !isBuyer)) && listPurchases.length <= 0 && (
              <Grid item>
                <Card color="primary" padding="10px 14px">
                  <Typography variant="body2">
                    <Info margin="0 10px -3px 0" />
                    El organismo público ha generado las órdenes de compra correspondientes, y su envío se encuentra
                    pendiente. Una vez enviadas al proveedor, serán visibles desde esta sección.
                  </Typography>
                </Card>
              </Grid>
            )}

            {listPurchases && listPurchases.length > 0 && (
              <Grid item margin="24px 0 0">
                <DataTable
                  accessibility={{ label: 'Órdenes de compra emitidas' }}
                  headers={[
                    { label: 'Orden de compra', value: 'code' },
                    { label: 'Fecha de envío', value: 'date' },
                    { label: 'Monto total', value: 'total' },
                    { label: 'Estado', value: 'status' }
                  ]}
                  headersCellsProps={[
                    { enableSort: true },
                    { enableSort: true },
                    { enableSort: true },
                    { enableSort: true }
                  ]}
                  enableStripped
                  initialRows={5}
                  loading={loading}
                  records={listPurchases}
                  recordsDecorators={[
                    ({ code }) => (
                      <Link
                        href={REACT_APP_MERCADO_PUBLICO_BASE_URL.concat(DIRECT_DEAL_REDIRECT_PURCHASE_SHEET).concat(
                          code
                        )}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {code}
                      </Link>
                    ),
                    ({ date }) => (
                      <Typography variant="body2" lineHeight="18px">
                        <FormattedDateComponent date={date} />
                      </Typography>
                    ),
                    ({ total }) => (
                      <Typography variant="body2" lineHeight="18px">
                        <FormattedCurrency
                          amount={currency === CURRENCY_TRANSLATION.CLP.cod ? parseInt(total || 0) : total || 0}
                          currency={currency || ''}
                          includeSymbol
                        />
                      </Typography>
                    ),
                    ({ status }) => (
                      <Typography variant="body2" lineHeight="18px">
                        {status}
                      </Typography>
                    )
                  ]}
                />
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

DirectDealSummaryPurchases.propTypes = {
  currency: PropTypes.string,
  loading: PropTypes.bool,
  purchases: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string,
      total: PropTypes.number,
      date: PropTypes.string,
      code: PropTypes.string
    })
  ),
  isBuyer: PropTypes.bool,
  isSeller: PropTypes.bool
}

export default DirectDealSummaryPurchases
