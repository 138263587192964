import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { Typography, Input } from '@chilecompra/react-kit/components'
import { checkInputErrorRequired, Skeleton, useInput } from '@chilecompra/react-kit'

import { GridContainer, GridItem, Link, SearchSuffix, SearchIconCustom } from './DirectDealStepTwo.styles'

import { onGetInfoProviderThunk } from './DirectDealStepTwo.actions'
import { onShowSnackbar } from '../SnackBarProvider/SnackBarProvider.actions'

import { formatDNI } from '../../modules/utils/formatters'
import { useImperativeFormRef } from '../../modules/hooks/useFormRef'
import { STATUS_ABILITY_PROVIDER_DICTIONARY } from '../../config/settings/constants'

/**
 * The DirectDealStepTwoProvider's container.
 */
const DirectDealStepTwoProvider = props => {
  const { formStepTwoRef, loading, onOpenDialog, provider, storage, onRedirect } = props

  const dispatch = useDispatch()

  const {
    error: providerDniError,
    value: providerDniValue,
    onChange: handleProviderDniChange,
    onError: handleProviderDniError,
    setValue: setProviderValue
  } = useInput({
    errorCallbacks: [checkInputErrorRequired()],
    formatCallbacks: [formatDNI],
    initialValue: storage?.providerDni || ''
  })

  const handlerSearchProvider = () => {
    dispatch(
      onGetInfoProviderThunk({
        providerDni: providerDniValue,
        callbackError: () => {
          dispatch(
            onShowSnackbar({
              title: 'No se ha encontrado RUT',
              message: 'Te pedimos que lo vuelvas a intentar.',
              severity: 'error'
            })
          )
        }
      })
    )
  }
  useEffect(() => {
    setProviderValue(storage?.providerDni || '')
  }, [storage])

  useEffect(() => {
    if (provider && provider?.skillState !== STATUS_ABILITY_PROVIDER_DICTIONARY.ABILITY) {
      onOpenDialog()
    }
  }, [provider])

  useImperativeFormRef(
    formStepTwoRef,
    () => ({
      provider: {
        error: providerDniError,
        onError: handleProviderDniError,
        value: providerDniValue
      },
      providerDniValue
    }),
    [providerDniError, providerDniValue]
  )

  return (
    <GridContainer margin="33px 0" spacing={2}>
      <GridItem xs={12} md={2}>
        <Typography variant="body1" fontWeight="bold">
          Identificación del proveedor
        </Typography>
        <Typography fontWeight="regular" variant="body2" padding="8px 0 0">
          Completa la información del proveedor con el que realizarás el Trato Directo.
        </Typography>
      </GridItem>
      <GridItem md={6} sm={12} xs={12}>
        <GridContainer spacing={1}>
          <GridItem md={6} sm={6} xs={12}>
            <Input
              error={providerDniError}
              label="Rut Proveedor"
              value={providerDniValue}
              onChange={handleProviderDniChange}
              suffix={
                <SearchSuffix aria-label="Buscar RUT" onClick={handlerSearchProvider}>
                  <SearchIconCustom />
                </SearchSuffix>
              }
            />
          </GridItem>
          <GridItem md={6} sm={6} xs={12} />
          <GridItem md={6} sm={6} xs={12}>
            {loading && <Skeleton />}
            {!loading && provider && (
              <>
                <Typography variant="body2" fontWeight="bold">
                  Razón Social
                </Typography>
                <Typography fontWeight="regular" lineHeight="16px" variant="h4" color="gray1" padding="8px 0 0">
                  {provider?.businessName}
                </Typography>
              </>
            )}
          </GridItem>
          <GridItem md={6} sm={6} xs={12}>
            {loading && <Skeleton />}
            {!loading && provider && (
              <>
                <Typography variant="body2" fontWeight="bold">
                  Estado de habilidad
                </Typography>
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  color={
                    {
                      [STATUS_ABILITY_PROVIDER_DICTIONARY.NOT_INFORMATION]: 'default',
                      [STATUS_ABILITY_PROVIDER_DICTIONARY.ABILITY]: 'success',
                      [STATUS_ABILITY_PROVIDER_DICTIONARY.NOT_ABILITY]: 'error',
                      [STATUS_ABILITY_PROVIDER_DICTIONARY.PENDING]: 'warning'
                    }[provider?.skillState]
                  }
                >
                  {provider?.skillState}
                </Typography>
                <Typography fontWeight="regular" lineHeight="16px" variant="body2" color="black1" padding="8px 0 0">
                  Revisa más información sobre este proveedor accediendo a su{' '}
                  <Link alt="Link Ficha Proveedor" onClick={onRedirect}>
                    ficha en Mercado Público
                  </Link>
                </Typography>
              </>
            )}
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  )
}

DirectDealStepTwoProvider.propTypes = {
  formStepTwoRef: PropTypes.shape({
    current: PropTypes.shape(Object)
  }),
  provider: PropTypes.shape({
    businessName: PropTypes.string,
    skillState: PropTypes.string
  }),
  loading: PropTypes.bool,
  storage: PropTypes.shape({
    providerDni: PropTypes.string
  }),
  onOpenDialog: PropTypes.func,
  onRedirect: PropTypes.func
}

export default DirectDealStepTwoProvider
