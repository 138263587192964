import React from 'react'

import {
  Accordion as AccordionBase,
  Grid as BaseGrid,
  Divider as DividerBase,
  styled,
  Link as BaseLink
} from '@mui/material'

import {
  Button as ButtonBase,
  DatePicker as DatePickerBase,
  Card as BaseCard,
  Table as TableBase,
  TableRow as TableRowBase,
  TableCell as TableCellBase
} from '@chilecompra/react-kit/components'

import AddIcon from '@mui/icons-material/Add'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import PriorityHighIcon from '@mui/icons-material/PriorityHigh'
import SearchIcon from '@mui/icons-material/Search'

export const WhiteTable = styled(TableBase)`
  background-color: ${props => props.theme.palette.brand.white};
`

export const Root = styled('div')`
  margin: 0 auto;
  padding: 0 0 20px;
  width: 100%;
`
export const Row = styled(({ margin, ...props }) => <BaseGrid container {...props} />)`
  margin: ${props => props.margin || '0 -5px'};
  padding: ${props => props.padding};
  width: 100%;
`

export const ProviderNotSuitableImage = styled('img')`
  width: 200px;
  height: 200px;
`

export const DeleteIconCustom = styled(DeleteIcon)`
  color: ${props => props.theme.palette.brand.black3};
  margin: ${props => props.margin};
  font-size: ${props => props.fontSize};
  cursor: pointer;
`

export const FileRequestImage = styled('img')`
  width: 64px;
  height: 54px;
`
export const Link = styled(({ fontWeight, ...props }) => <BaseLink {...props} />)`
  cursor: pointer;
  font-weight: ${props => props.fontWeight || 'regular'};
`
export const DeleteLink = styled('a')`
  color: ${props => props.theme.palette.brand.black3};
  text-decoration: underline;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
`

export const Column = styled(({ margin, padding, ...props }) => <BaseGrid item {...props} />)`
  &.MuiGrid-item {
    margin: ${props => props.margin || 0};
    padding: ${props => props.padding || 0};
    width: 100%;
  }
`

export const Card = styled(({ textAlign, ...props }) => <BaseCard {...props} />)`
  &.MuiPaper-root * {
    text-align: ${props => props.textAlign};
  }
`

export const Input = styled(({ fontSize, ...props }) => <BaseCard {...props} />)`
  &.MuiPaper-root * {
    text-align: ${props => props.textAlign};
    font-size: ${props => props.fontSize};
  }
`
export const AmountPrefix = styled('abbr')`
  background-color: ${props => props.theme.palette.brand.gray3};
  height: 100%;
  width: 35px;
  align-content: center;
  text-align-last: center;
`

export const SearchButton = styled(ButtonBase)`
  &.MuiButtonBase-root {
    width: 100%;
    height: 50px;
  }
`

export const AddButton = styled(ButtonBase)`
  &.MuiButtonBase-root {
    width: 180px;
    height: 40px;
    border: 1px solid;
    border-color: ${props => props.theme.palette.blue[50]};
    background-color: ${props => props.theme.palette.brand.white};
  }
`
export const FileRequestButton = styled(ButtonBase)`
  &.MuiButtonBase-root {
    padding: 0;
    width: 180px;
    height: 40px;
    border: 2px solid;
    border-color: ${props => props.theme.palette.blue[50]};
    background-color: ${props => props.theme.palette.blue[90]};
  }
`

export const PricingRequestButton = styled(ButtonBase)`
  &.MuiButtonBase-root {
    padding: 0;
    width: 240px;
    height: 40px;
    border: 2px solid;
    border-color: ${props => props.theme.palette.blue[50]};
    background-color: ${props => props.theme.palette.blue[90]};
  }
`

export const DescriptionContainer = styled('div')`
  width: 53%;
`
export const UploadDiv = styled('div')`
  cursor: pointer;
  width: 100%;
  height: 300px;
  border: solid 1.5px;
  border-radius: 8px;
  border-color: ${props => props.theme.palette.gray[0.16]};
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
`

export const SectionDiv = styled('div')`
  border: solid 1px;
  border-color: ${props => props.theme.palette.gray[0.16]};
  width: 77%;
  margin: 36px 10px 0;
  ${props => props.theme.breakpoints.up('lg')} {
    margin-top: 40px;
  }
`
export const GenericDiviser = styled('div')`
  border: solid 1px;
  border-color: ${props => props.theme.palette.gray[0.16]};
  width: 100%;
  margin: 36px 10px 0;
  ${props => props.theme.breakpoints.up('lg')} {
    margin-top: 40px;
  }
`

export const GridContainer = styled(({ textAlign, ...props }) => <BaseGrid container {...props} />)`
  &.MuiGrid-container {
    height: ${props => props.height};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
  }
`

export const GridItem = styled(({ textAlign, ...props }) => <BaseGrid item {...props} />)`
  &.MuiGrid-item {
    margin: ${props => props.margin};
    text-align: ${props => props.textAlign};

    ${props => props.theme.breakpoints.only('xs')} {
      padding: ${props => props.padding || '7px 0'};
    }

    ${props => props.theme.breakpoints.only('sm')} {
      padding: ${props => props.padding || '7px 8px'};
    }

    ${props => props.theme.breakpoints.only('md')} {
      padding: ${props => props.padding || '7px 16px'};
    }

    ${props => props.theme.breakpoints.only('lg')} {
      padding: ${props => props.padding || '7px 16px'};
    }

    ${props => props.theme.breakpoints.only('xl')} {
      padding: ${props => props.padding || '7px 16px'};
    }
  }
`

export const UploadGridItem = styled(({ textAlign, ...props }) => <BaseGrid item {...props} />)`
  &.MuiGrid-item {
    margin: ${props => props.margin};
    padding: ${props => props.padding};
  }
`
export const SuccessTableItem = styled('div')`
  background-color: ${props => props.theme.palette.blue[95]};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  border-bottom: 1px solid ${props => props.theme.palette.gray[0.16]};
  height: 50px;
  width: 30%;
`
export const SuccessTableItemDescription = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  border-bottom: 1px solid ${props => props.theme.palette.gray[0.16]};
  height: 50px;
  width: 70%;
`

export const Divider = styled(DividerBase)`
  width: 100%;
`
export const SpanTitleAccordion = styled('span')`
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: ${props => props.theme.palette.brand.black3};
  margin-left: 10px;
`
export const Accordion = styled(AccordionBase)`
  border-radius: 0;
  box-shadow: none;
  &.Mui-expanded {
    background-color: ${props => props.theme.palette.brand.gray3};
  }
`
export const DeleteLinkGray = styled(BaseLink)`
  color: ${props => props.theme.palette.brand.black3};
  text-decoration: underline;
  cursor: pointer;
`

export const DeleteIconGray = styled('img')`
  width: 12px;
  height: 13px;
  padding: 0 0 0 8px;
  cursor: pointer;
`

export const ChevronDownIconGray = styled('img')`
  width: 12px;
  height: 13px;
  cursor: pointer;
`

export const PlusIcon = styled(AddIcon)`
  font-size: 23px;
  margin: 0 12px 3px 0;
`
export const SearchIconCustom = styled(SearchIcon)`
  width: 20px;
  height: 20px;
`

export const SearchSuffix = styled('button')`
  border-right: 1px solid ${props => props.theme.palette.blue[50]};
  background-color: ${props => props.theme.palette.blue[50]};
  color: ${props => props.theme.palette.brand.white};
  padding: 10px;
  cursor: pointer;
  border: none;
  outline: none;

  &:hover {
    background-color: ${props => props.theme.palette.blue[100]};
  }
`
export const ArrowButton = styled(({ fontSize, margin, ...props }) => <KeyboardArrowRightIcon {...props} />)`
  font-size: ${props => props.fontSize};
  margin: ${props => props.margin};
`

export const DivSection = styled('div')``

export const DatePicker = styled(({ className, ...props }) => (
  <DatePickerBase
    {...props}
    PopoverProps={{
      ...props.PopoverProps,
      anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
      classes: { ...props.PopoverProps?.classes, paper: className },
      transformOrigin: { horizontal: 'left', vertical: 'top' }
    }}
    variant="inline"
  />
))`
  &.MuiPopover-paper {
    box-shadow: 0 3px 6px ${props => props.theme.palette.gray[0.16]};
  }

  &.MuiPopover-paper .MuiPickersBasePicker-container {
    .MuiPickersToolbar-toolbar {
      background-color: ${props => props.theme.palette.blue[50]};
    }

    .MuiPickersToolbar-toolbar .MuiButtonBase-root:hover {
      background-color: transparent;
    }

    .MuiTypography-subtitle1,
    .MuiTypography-body1 {
      font-size: 16px;
    }

    .MuiTypography-h4 {
      font-size: 34px;
    }

    .MuiTypography-caption {
      font-size: 14px;
    }

    .MuiTypography-body2 {
      font-size: 14px;
    }

    .MuiTypography-h5 {
      font-size: 24px;
    }

    .MuiPickersCalendarHeader-iconButton .MuiSvgIcon-root {
      font-size: 24px;
    }
  }

  &.MuiPopover-paper .MuiPickersDay-day {
    color: ${props => props.theme.palette.gray[20]};
  }

  &.MuiPopover-paper .MuiPickersDay-current {
    color: ${props => props.theme.palette.blue[50]} !important;
  }

  &.MuiPopover-paper .MuiPickersDay-dayDisabled,
  &.MuiPopover-paper .MuiPickersCalendarHeader-dayLabel {
    color: ${props => props.theme.palette.gray[50]};
  }

  &.MuiPopover-paper .MuiPickersDay-daySelected {
    background-color: ${props => props.theme.palette.blue[50]} !important;
    color: ${props => props.theme.palette.brand.white} !important;
  }

  &.MuiPopover-paper .MuiPickersCalendarHeader-transitionContainer .MuiTypography-body1 {
    color: ${props => props.theme.palette.gray[20]};
  }

  &.MuiPopover-paper .MuiPickersCalendarHeader-iconButton {
    color: ${props => props.theme.palette.gray[50]};
  }

  &.MuiPopover-paper .MuiIconButton-root.Mui-disabled {
    color: ${props => props.theme.palette.gray[50]};
  }

  &.MuiPopover-paper .MuiPickersYear-root {
    color: ${props => props.theme.palette.gray[20]};
  }

  &.MuiPopover-paper .MuiPickersYear-yearDisabled {
    color: ${props => props.theme.palette.gray[50]};
  }

  &.MuiPopover-paper .MuiPickersYear-yearSelected {
    color: ${props => props.theme.palette.blue[50]} !important;
  }

  &.MuiPopover-paper .MuiDialogActions-root {
    display: none !important;
  }

  &.MuiPopover-paper .MuiTouchRipple-root {
    display: none;
  }
`

export const MainCircle = styled('div')`
  width: 64px;
  height: 64px;
  background-color: ${props =>
    props.backgroundColor === 'success'
      ? props.theme.palette.green[95]
      : props.backgroundColor === 'warning'
        ? props.theme.palette.yellow[95]
        : props.theme.palette.red[95]};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const ContainerIconCircle = styled('div')`
  display: 'flex';
  justify-content: 'center';
  margin: ${props => props.margin};
`

export const StyledPriorityIcon = styled(PriorityHighIcon)`
  color: ${props => props.theme.palette.yellow[50]};
  font-size: 32px;
`

export const StyledCheckIcon = styled(CheckIcon)`
  color: ${props => props.theme.palette.green[50]};
  font-size: 32px;
`

export const StyledCloseIcon = styled(CloseIcon)`
  color: ${props => props.theme.palette.red[50]};
  font-size: 32px;
`
