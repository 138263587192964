import React from 'react'
import { Divider as DividerBase, Grid as BaseGrid, styled } from '@mui/material'
import { Card as BaseCard, CircleSuccessIcon as CircleSuccessIconBase } from '@chilecompra/react-kit/components'

export const Root = styled('div')`
  width: 100%;
`

export const Link = styled('a')`
  color: ${props => props.theme.palette.gray[50]};
  cursor: pointer;
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 16px;
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  width: 57px;

  &::before {
    content: '<';
    margin-right: 15px;
  }
`

export const Grid = styled(({ ...props }) => <BaseGrid {...props} />)`
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  width: 100%;
`
export const Card = styled(({ textAlign, ...props }) => <BaseCard {...props} />)`
  &.MuiPaper-root * {
    text-align: ${props => props.textAlign};
  }
`

export const Divider = styled(DividerBase)``
export const LineJump = styled('br')``

export const NotFoundImg = styled('img')`
  color: ${props => props.theme.palette.brand.black3};
  height: auto;
  width: 82px;
`

export const AddProduct = styled('div')`
  color: ${props => props.theme.palette.green[50]};
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 21px;
`

export const LinkAddProduct = styled('button')`
  color: ${props => props.theme.palette.blue[50]};
  cursor: pointer;
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 23px;
  border: none;
  background-color: ${props => props.theme.palette.brand.white};
`

export const Row = styled(({ alignItems = false, background = false, border = false, margin, ...props }) => (
  <BaseGrid {...props} />
))`
  margin: ${props => props.margin || '0 -5px'};
  width: 100%;
  background-color: ${props => props.background && props.theme.palette.green[95]};
  border: ${props => props.border && '1px solid'};
  border-color: ${props => props.border && props.theme.palette.green[75]};
  padding: ${props => props.padding && '2px'};
  align-items: ${props => props.alignItems && 'center'};
`

export const CircleSuccessIcon = styled(({ fontSize, ...props }) => <CircleSuccessIconBase {...props} />)`
  &.MuiSvgIcon-root {
    font-size: ${props => props.fontSize};
  }
`
