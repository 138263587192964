import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Divider } from '@mui/material'
import { Card, Typography } from '@chilecompra/react-kit/components'
import { Skeleton } from '@chilecompra/react-kit'

import FormattedCurrencyComponent from '../../components/components/FormattedCurrency.component'
import FormattedDateComponent from '../../components/components/FormattedDate.component'

import { GridContainer, GridItem } from './DirectDealStepTwo.styles'

import {
  CURRENCY_TRANSLATION,
  DIRECT_DEAL_HONORARIUM_PERCENT_DICTIONARY,
  DIRECT_DEAL_STEP_TWO_TAX_EXEMPT_DICTIONARY
} from '../../config/settings/constants'
import { formatCurrencyToNumber } from '../../modules/utils/formatters'
import { useImperativeFormRef } from '../../modules/hooks/useFormRef'

/**
 * The DirectDealStepTwoResume's container.
 */
const DirectDealStepTwoResume = props => {
  const { currency, currencies, formStepTwoRef, loading, taxes } = props

  const [taxName, setTaxName] = useState('')
  const [taxTotal, setTaxTotal] = useState(0)
  const [total, setTotal] = useState(0)
  const [totalUTM, setTotalUTM] = useState(0)

  const [totalDelivery, setTotalDelivery] = useState(0)
  const [totalDescount, setTotalDescount] = useState(0)
  const [totalCharge, setTotalCharge] = useState(0)

  const dateToday = new Date()

  const getTotalCurriencies = currencyType => {
    return currencies.find(currency => currency.type === currencyType)?.valueClp || 0
  }

  useEffect(() => {
    let taxSelected = null

    if (taxes) {
      taxSelected = taxes.find(t => t.value === formStepTwoRef.current.taxes?.value)
      if (taxSelected) {
        setTaxName(taxSelected?.name)
      }
    }

    const taxPercent = taxSelected?.percentage || 0
    const net = formStepTwoRef.current.netTotal || 0
    const delivery = formatCurrencyToNumber(formStepTwoRef.current.delivery?.value || '0', currency?.type)
    const charges = formatCurrencyToNumber(formStepTwoRef.current.charge?.value || '0', currency?.type)
    const discount = formatCurrencyToNumber(formStepTwoRef.current.discount?.value || '0', currency?.type)

    setTotalDelivery(delivery)
    setTotalCharge(charges)
    setTotalDescount(discount)

    const amountBeforeTaxes = Math.max(net + delivery + charges - discount, 0)
    const valueUTM = getTotalCurriencies(CURRENCY_TRANSLATION.UTM.cod)

    if (formStepTwoRef.current.taxes?.value) {
      let amountAfterTaxes = 0
      let honorariumAmount = 0

      if (
        formStepTwoRef.current.taxes?.value === DIRECT_DEAL_HONORARIUM_PERCENT_DICTIONARY.HONORARIUM_BENEFIT_SII ||
        formStepTwoRef.current.taxes?.value === DIRECT_DEAL_HONORARIUM_PERCENT_DICTIONARY.HONORARIUM
      ) {
        honorariumAmount = amountBeforeTaxes / (1 - taxPercent / 100)
        amountAfterTaxes = honorariumAmount - amountBeforeTaxes
      } else {
        amountAfterTaxes = amountBeforeTaxes * (taxPercent / 100)
      }

      if (currency?.type === CURRENCY_TRANSLATION.CLP.cod) {
        const taxTotal = Math.round(amountAfterTaxes)
        const total = Math.round(amountBeforeTaxes + amountAfterTaxes)
        const totalUTM = (total / valueUTM).toFixed(2)

        setTaxTotal(taxTotal)
        setTotal(total)
        setTotalUTM(totalUTM)
      } else if (currency?.type === CURRENCY_TRANSLATION.CLF.cod) {
        const totalTaxFixed = amountAfterTaxes.toFixed(4)
        const totalFixed = parseFloat(amountBeforeTaxes) + parseFloat(totalTaxFixed)
        const valueCLF = getTotalCurriencies(currency.type)
        const totalCLFToCLP = totalFixed * valueCLF
        const totalUTM = (totalCLFToCLP / valueUTM).toFixed(2)

        setTaxTotal(totalTaxFixed)
        setTotal(totalFixed)
        setTotalUTM(totalUTM)
      } else if (currency?.type === CURRENCY_TRANSLATION.USD.cod) {
        const totalTaxFixed = amountAfterTaxes.toFixed(2)
        const totalFixed = parseFloat(amountBeforeTaxes) + parseFloat(totalTaxFixed)
        const valueUSD = getTotalCurriencies(currency.type)
        const totalUSDToCLP = totalFixed * valueUSD
        const totalUTM = (totalUSDToCLP / valueUTM).toFixed(2)

        setTaxTotal(totalTaxFixed)
        setTotal(totalFixed)
        setTotalUTM(totalUTM)
      } else if (currency?.type === CURRENCY_TRANSLATION.UTM.cod) {
        const totalTaxFixed = amountAfterTaxes.toFixed(4)
        const totalFixed = parseFloat(amountBeforeTaxes) + parseFloat(totalTaxFixed)

        setTaxTotal(totalTaxFixed)
        setTotal(totalFixed)
        setTotalUTM(totalFixed)
      } else if (currency?.type === CURRENCY_TRANSLATION.EUR.cod) {
        const totalTaxFixed = amountAfterTaxes.toFixed(2)
        const totalFixed = parseFloat(amountBeforeTaxes) + parseFloat(totalTaxFixed)
        const valueCLF = getTotalCurriencies(currency.type)
        const totalCLFToCLP = totalFixed * valueCLF
        const totalUTM = (totalCLFToCLP / valueUTM).toFixed(2)

        setTaxTotal(totalTaxFixed)
        setTotal(totalFixed)
        setTotalUTM(totalUTM)
      }
    } else {
      if (currency?.type === CURRENCY_TRANSLATION.CLP.cod) {
        const total = Math.round(amountBeforeTaxes)
        const totalUTM = (total / valueUTM).toFixed(2)

        setTotal(total)
        setTotalUTM(totalUTM)
      } else if (currency?.type === CURRENCY_TRANSLATION.CLF.cod) {
        const totalFixed = amountBeforeTaxes.toFixed(4)
        const valueCLF = getTotalCurriencies(currency.type)
        const totalCLFToCLP = totalFixed * valueCLF
        const totalUTM = (totalCLFToCLP / valueUTM).toFixed(2)

        setTotal(totalFixed)
        setTotalUTM(totalUTM)
      } else if (currency?.type === CURRENCY_TRANSLATION.USD.cod) {
        const totalFixed = amountBeforeTaxes.toFixed(2)
        const valueUSD = getTotalCurriencies(currency.type)
        const totalUSDToCLP = totalFixed * valueUSD
        const totalUTM = (totalUSDToCLP / valueUTM).toFixed(2)

        setTotal(totalFixed)
        setTotalUTM(totalUTM)
      } else if (currency?.type === CURRENCY_TRANSLATION.UTM.cod) {
        const totalFixed = amountBeforeTaxes

        setTotal(totalFixed)
        setTotalUTM(totalFixed)
      } else if (currency?.type === CURRENCY_TRANSLATION.EUR.cod) {
        const totalFixed = amountBeforeTaxes.toFixed(2)
        const valueCLF = getTotalCurriencies(currency.type)
        const totalCLFToCLP = totalFixed * valueCLF
        const totalUTM = (totalCLFToCLP / valueUTM).toFixed(2)

        setTotal(totalFixed)
        setTotalUTM(totalUTM)
      }
    }
  }, [
    formStepTwoRef.current.charge?.value,
    formStepTwoRef.current.delivery?.value,
    formStepTwoRef.current.discount?.value,
    formStepTwoRef.current.netTotal,
    formStepTwoRef.current.taxes?.value
  ])

  useImperativeFormRef(
    formStepTwoRef,
    () => ({
      total
    }),
    [total]
  )

  return (
    <GridContainer margin="33px 0" spacing={2}>
      <GridItem lg={8} md={8} sm={12} xs={12}>
        <Card padding="24px 16px">
          <GridContainer spacing={2}>
            <GridItem xs={12}>
              <Typography variant="body1" fontWeight="bold">
                Resumen
              </Typography>
            </GridItem>
            <GridItem xs={6}>
              <Typography variant="body2" fontWeight="bold">
                Subtotal neto
              </Typography>
            </GridItem>
            <GridItem xs={6} textAlign="right">
              {loading && <Skeleton width="80px" />}
              {!loading && (
                <Typography variant="h4" fontWeight="bold">
                  <FormattedCurrencyComponent
                    amount={formStepTwoRef.current.netTotal || 0}
                    currency={currency?.type}
                    includeSymbol
                  />
                </Typography>
              )}
            </GridItem>
            <GridItem xs={6}>
              <Typography variant="body2" fontSize="14px">
                Despacho general
              </Typography>
            </GridItem>
            <GridItem xs={6} textAlign="right">
              {loading && <Skeleton width="80px" />}
              {!loading && (
                <Typography variant="h4">
                  <FormattedCurrencyComponent amount={totalDelivery || 0} currency={currency?.type} includeSymbol />
                </Typography>
              )}
            </GridItem>
            <GridItem xs={6}>
              <Typography variant="body2" fontSize="14px">
                Cargos generales
              </Typography>
            </GridItem>
            <GridItem xs={6} textAlign="right">
              {loading && <Skeleton width="80px" />}
              {!loading && (
                <Typography variant="h4">
                  <FormattedCurrencyComponent amount={totalCharge || 0} currency={currency?.type} includeSymbol />
                </Typography>
              )}
            </GridItem>
            <GridItem xs={6}>
              <Typography variant="body2" fontSize="14px">
                Descuentos generales
              </Typography>
            </GridItem>
            <GridItem xs={6} textAlign="right">
              {loading && <Skeleton width="80px" />}
              {!loading && (
                <Typography variant="h4">
                  -
                  <FormattedCurrencyComponent amount={totalDescount || 0} currency={currency?.type} includeSymbol />
                </Typography>
              )}
            </GridItem>
            {formStepTwoRef.current.taxes?.value &&
              formStepTwoRef.current.taxes?.value !== DIRECT_DEAL_STEP_TWO_TAX_EXEMPT_DICTIONARY.EXEMPT &&
              formStepTwoRef.current.taxes?.value !== DIRECT_DEAL_STEP_TWO_TAX_EXEMPT_DICTIONARY.FREE_TRADE_ZONE && (
                <>
                  <GridItem xs={6}>
                    <Typography variant="body2" fontSize="14px">
                      Impuestos
                    </Typography>

                    <Typography variant="body2" fontSize="14px" color="black3">
                      {taxName}
                    </Typography>
                  </GridItem>
                  <GridItem xs={6} textAlign="right">
                    {loading && <Skeleton width="80px" />}
                    {!loading && (
                      <Typography variant="h4">
                        <FormattedCurrencyComponent amount={taxTotal || 0} currency={currency?.type} includeSymbol />
                      </Typography>
                    )}
                  </GridItem>
                </>
              )}

            <GridItem xs={12}>
              <Divider />
            </GridItem>
            <GridItem xs={6}>
              <Typography variant="h4" color="primary" fontWeight="bold">
                Monto total
              </Typography>
            </GridItem>
            <GridItem xs={6} textAlign="right">
              {loading && <Skeleton width="80px" />}
              {!loading && (
                <>
                  <Typography variant="h4" fontWeight="bold">
                    <FormattedCurrencyComponent amount={total || 0} currency={currency?.type} includeSymbol />
                  </Typography>
                  {currency?.type !== CURRENCY_TRANSLATION.UTM.cod && (
                    <Typography variant="body2" fontSize="14px" color="black3">
                      <FormattedCurrencyComponent amount={totalUTM || 0} currency="UTM" /> UTM al día{' '}
                      <FormattedDateComponent date={dateToday} />
                    </Typography>
                  )}
                </>
              )}
            </GridItem>
          </GridContainer>
        </Card>
      </GridItem>
    </GridContainer>
  )
}

DirectDealStepTwoResume.propTypes = {
  currency: PropTypes.shape({
    value: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.string,
    symbol: PropTypes.string
  }),
  currencies: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      name: PropTypes.string,
      valueClp: PropTypes.number
    })
  ),
  formStepTwoRef: PropTypes.shape({
    current: PropTypes.shape(Object)
  }),
  loading: PropTypes.bool,
  taxes: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, name: PropTypes.string }))
}

export default DirectDealStepTwoResume
