import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Button, Card, DataTable, Typography } from '@chilecompra/react-kit/components'

import { Grid, Link, ArrowButton, Info } from './DirectDealSummary.styles'
import FormattedDateComponent from '../../components/components/FormattedDate.component'

/**
 * The DirectDealSummaryRequets's container.
 */
const DirectDealSummaryRequests = props => {
  const { contracts, loading, onOpenForm, onOpenDetail } = props

  const [listRequests, setListRequests] = useState([])

  useEffect(() => {
    setListRequests(contracts?.requests.filter(request => request.isActive))
  }, [contracts])

  return (
    <Grid container spacing={2} margin="0">
      <Grid item md={8} xs={12}>
        <Card enableBorder lineColor="primary" color="blank" padding="32px 24px">
          <Grid container spacing={1}>
            <Grid item>
              <Typography fontWeight="bold" variant="h3" lineHeight="26px" margin="0 0 8px">
                Solicitud de un nuevo proceso de contratación
              </Typography>
              <Typography variant="body2" lineHeight="18px">
                Si ofreces el mismo producto o servicio, o conoces otro proveedor que lo haga por favor adjunta la
                documentación necesaria que lo demuestre.
              </Typography>
            </Grid>

            <Grid item margin="16px 0 0">
              <Card color="primary">
                <Typography fontSize="14px" lineHeight="16px">
                  <Info margin="0 10px 0 0" />
                  Las solicitudes que se ingresen en esta sección serán de carácter público, desde el día y hora
                  indicado para el cierre de recepción de solicitudes.
                </Typography>
              </Card>
            </Grid>

            <Grid item margin="15px 0 0">
              <Button
                aria-label="Ingresar solicitud"
                color="primary"
                variant="contained"
                onClick={onOpenForm}
                loading={loading}
              >
                Ingresar solicitud
              </Button>
            </Grid>
            {listRequests.length > 0 && (
              <Grid item>
                <DataTable
                  accessibility={{ label: 'Solicitudes de procesos de contratación' }}
                  headers={[
                    { label: 'Solicitudes enviadas', value: 'num' },
                    { label: 'Fecha de solicitud', value: 'date' },
                    { label: 'Mensaje de solicitud', value: 'answer' },
                    { label: '', value: 'detail' }
                  ]}
                  headersCellsProps={[
                    { enableSort: true, initialDirection: 'descending', initialSort: true },
                    { enableSort: true },
                    { enableSort: true },
                    { enableSort: false }
                  ]}
                  enableStripped
                  initialRows="5"
                  loading={loading}
                  records={listRequests}
                  recordsDecorators={[
                    ({ num }) => (
                      <Typography variant="body2" lineHeight="18px">
                        Solicitud N° {num}
                      </Typography>
                    ),
                    ({ date }) => (
                      <Typography variant="body2" lineHeight="18px">
                        <FormattedDateComponent date={date} />
                      </Typography>
                    ),
                    ({ answer }) => (
                      <Typography variant="body2" lineHeight="18px">
                        {answer}
                      </Typography>
                    ),
                    request => {
                      const handleClick = event => {
                        event.preventDefault()
                        onOpenDetail(request)
                      }
                      return (
                        <Link fontWeight="600" href="#" textAlign="right" onClick={handleClick}>
                          Ver detalle <ArrowButton fontSize="21px" margin="0 0 -5px -6px" />
                        </Link>
                      )
                    }
                  ]}
                />
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

DirectDealSummaryRequests.propTypes = {
  contracts: PropTypes.shape({
    requestClosedDate: PropTypes.string,
    requestTotal: PropTypes.number,
    requests: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        dni: PropTypes.string,
        isActive: PropTypes.bool,
        answer: PropTypes.string
      })
    )
  }),
  loading: PropTypes.bool,
  onOpenForm: PropTypes.func,
  onOpenDetail: PropTypes.func
}

export default DirectDealSummaryRequests
