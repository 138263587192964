import { batch } from 'react-redux'

import { makeActionCreator } from '../../config/store/utils'

import { getDetailDirectDealSheet, postApplyDecisionDirectDealSheet } from '../../services/directdeal'
import { getContractRequests, postPrepareContractRequest, putCreateContractRequest } from '../../services/contracts'
import { getInfoProviderByDni } from '../../services/organizations'
import { getQuotesDirectDeal } from '../../services/quotes'
import { getLawsuitCauses } from '../../services/config'
import { getPublicToken } from '../../services/auth'

import { onShowSnackbar } from '../SnackBarProvider/SnackBarProvider.actions'
import { postIssuePurchaseToSheet } from '../../services/purchase'

export const GET_DETAIL_DIRECT_DEAL_SUMMARY = 'GET_DETAIL_DIRECT_DEAL_SUMMARY'
export const GET_DETAIL_DIRECT_DEAL_SUMMARY_SUCCESS = 'GET_DETAIL_DIRECT_DEAL_SUMMARY_SUCCESS'
export const GET_DETAIL_DIRECT_DEAL_SUMMARY_ERROR = 'GET_DETAIL_DIRECT_DEAL_SUMMARY_ERROR'

export const onGetDetailDirectDealSummary = makeActionCreator(GET_DETAIL_DIRECT_DEAL_SUMMARY, 'payload')
export const onGetDetailDirectDealSummarySuccess = makeActionCreator(GET_DETAIL_DIRECT_DEAL_SUMMARY_SUCCESS, 'payload')
export const onGetDetailDirectDealSummaryError = makeActionCreator(GET_DETAIL_DIRECT_DEAL_SUMMARY_ERROR, 'payload')

export const onGetDetailDirectDealSummaryThunk = payload => async dispatch => {
  dispatch(onGetDetailDirectDealSummary())
  try {
    const { data } = await getDetailDirectDealSheet({ id: payload, actionType: GET_DETAIL_DIRECT_DEAL_SUMMARY })

    return dispatch(
      onGetDetailDirectDealSummarySuccess({
        sheet: {
          id: data?.payload?.id || '',
          code: data?.payload?.codigoTratoDirecto || '',
          status: data?.payload?.estado || '',
          name: data?.payload?.nombre || '',
          description: data?.payload?.descripcion || '',
          cause: data?.payload?.causalTratoDirecto || '',
          justification: data?.payload?.justificacionTratoDirecto || '',
          datePublish: data?.payload?.fechaPublicacion || '',
          dateClosed: data?.payload?.fechaCierre || '',
          totalAmount: data?.payload?.montoTotal || 0,
          currency: data?.payload?.moneda || '',
          providerName: data?.payload?.proveedor || '',
          providerDni: data?.payload?.rutProveedor || '',
          contractDuration: data?.payload?.duracionContrato || '',
          contractDateFinish: data?.payload?.fechaTerminoContrato || '',
          contractCategory: data?.payload?.categoriaContrato || '',
          contractType: data?.payload?.tipoContrato || '',
          deliveryAddress: data?.payload?.direccionEntrega || '',
          deliveryRegion: data?.payload?.regionEntrega || '',
          deliveryComment: data?.payload?.comentarioEntrega || '',
          deliveryCommune: data?.payload?.comunaEntrega || '',
          products:
            data?.payload?.productos.map(product => {
              return {
                cod: product.productoServicio,
                detail: product.detalle,
                amount: product.cantidad,
                unit: product.unidad,
                total: product.total,
                nameUnit: product.nombreUnidad,
                name: product.nombreProductoServicio
              }
            }) || [],
          buyerDni: data?.payload?.rutComprador || '',
          buyerName: data?.payload?.nombreComprador || '',
          buyerLegalName: data?.payload?.nombreLegalComprador || '',
          purchases:
            data?.payload?.ordenEmitida.map(purchase => {
              return {
                status: purchase?.estado || null,
                total: purchase?.montoTotal || 0,
                date: purchase?.fechaEnvio || '',
                code: purchase?.ordenCompras || ''
              }
            }) || [],
          withPublicity: data?.payload?.conPublicidad || false,
          reasonWithdrawal: data?.payload?.motivo || '',
          purchaseMechanism: data?.payload?.mecanismoCompra || '',
          resolution: {
            subBusiness: data?.payload?.adjuntoResolucionDecreto?.idBusiness || null,
            idBusiness: data?.payload?.adjuntoResolucionDecreto?.idAdjunto || null
          },
          report: {
            subBusiness: data?.payload?.adjuntoCausal?.idBusiness || null,
            idBusiness: data?.payload?.adjuntoCausal?.idAdjunto || null
          },
          optional: {
            subBusiness: data?.payload?.adjuntoOpcional?.idBusiness || null,
            idBusiness: data?.payload?.adjuntoOpcional?.idAdjunto || null
          }
        }
      })
    )
  } catch (error) {
    return batch(() => {
      dispatch(onGetDetailDirectDealSummaryError({ error }))
    })
  }
}

export const GET_INFO_PROVIDER_DIRECT_DEAL_SUMMARY = 'GET_INFO_PROVIDER_DIRECT_DEAL_SUMMARY'
export const GET_INFO_PROVIDER_DIRECT_DEAL_SUMMARY_SUCCESS = 'GET_INFO_PROVIDER_DIRECT_DEAL_SUMMARY_SUCCESS'
export const GET_INFO_PROVIDER_DIRECT_DEAL_SUMMARY_ERROR = 'GET_INFO_PROVIDER_DIRECT_DEAL_SUMMARY_ERROR'

export const onGetInfoProviderDirectDealSummary = makeActionCreator(GET_INFO_PROVIDER_DIRECT_DEAL_SUMMARY, 'payload')
export const onGetInfoProviderDirectDealSummarySuccess = makeActionCreator(
  GET_INFO_PROVIDER_DIRECT_DEAL_SUMMARY_SUCCESS,
  'payload'
)
export const onGetInfoProviderDirectDealSummaryError = makeActionCreator(
  GET_INFO_PROVIDER_DIRECT_DEAL_SUMMARY_ERROR,
  'payload'
)

export const onGetInfoProviderDirectDealSummaryThunk = payload => async dispatch => {
  dispatch(onGetInfoProviderDirectDealSummary())

  try {
    const { data } = await getInfoProviderByDni({ dni: payload, actionType: GET_INFO_PROVIDER_DIRECT_DEAL_SUMMARY })

    return dispatch(
      onGetInfoProviderDirectDealSummarySuccess({
        provider: {
          providerLegalName: data?.payload?.nombre || '',
          providerDni: data?.payload?.rut || ''
        }
      })
    )
  } catch (error) {
    return dispatch(onGetInfoProviderDirectDealSummaryError({ error }))
  }
}

export const GET_QUOTES_DIRECT_DEAL_SUMMARY = 'GET_QUOTES_DIRECT_DEAL_SUMMARY'
export const GET_QUOTES_DIRECT_DEAL_SUMMARY_SUCCESS = 'GET_QUOTES_DIRECT_DEAL_SUMMARY_SUCCESS'
export const GET_QUOTES_DIRECT_DEAL_SUMMARY_ERROR = 'GET_QUOTES_DIRECT_DEAL_SUMMARY_ERROR'

export const onGetQuotesDirectDealSummary = makeActionCreator(GET_QUOTES_DIRECT_DEAL_SUMMARY, 'payload')
export const onGetQuotesDirectDealSummarySuccess = makeActionCreator(GET_QUOTES_DIRECT_DEAL_SUMMARY_SUCCESS, 'payload')
export const onGetQuotesDirectDealSummaryError = makeActionCreator(GET_QUOTES_DIRECT_DEAL_SUMMARY_ERROR, 'payload')

export const onGetQuotesDirectDealSummaryThunk = payload => async dispatch => {
  dispatch(onGetQuotesDirectDealSummary())
  try {
    const { data } = await getQuotesDirectDeal({ sheetId: payload, actionType: GET_QUOTES_DIRECT_DEAL_SUMMARY })

    return dispatch(
      onGetQuotesDirectDealSummarySuccess({
        quotes: data?.payload.map(quote => {
          return {
            id: quote?.id || '',
            quoteDni: quote?.rutCotizante || '',
            quoteName: quote?.nombreCotizante || '',
            quotePhone: quote?.telefonoCotizante || '',
            quoteEmail: quote?.correoCotizante || '',
            quoteAttachmentId: quote?.idAdjunto || null,
            quoteSubBusiness: quote?.idBusiness || null
          }
        })
      })
    )
  } catch (error) {
    return dispatch(onGetQuotesDirectDealSummaryError({ error }))
  }
}

export const CREATE_CONTRACT_DIRECT_DEAL_SUMMARY = 'CREATE_CONTRACT_DIRECT_DEAL_SUMMARY'
export const CREATE_CONTRACT_DIRECT_DEAL_SUMMARY_SUCCESS = 'CREATE_CONTRACT_DIRECT_DEAL_SUMMARY_SUCCESS'
export const CREATE_CONTRACT_DIRECT_DEAL_SUMMARY_ERROR = 'CREATE_CONTRACT_DIRECT_DEAL_SUMMARY_ERROR'

export const onCreateContractDirectDealSummary = makeActionCreator(CREATE_CONTRACT_DIRECT_DEAL_SUMMARY, 'payload')
export const onCreateContractDirectDealSummarySuccess = makeActionCreator(
  CREATE_CONTRACT_DIRECT_DEAL_SUMMARY_SUCCESS,
  'payload'
)
export const onCreateContractDirectDealSummaryError = makeActionCreator(
  CREATE_CONTRACT_DIRECT_DEAL_SUMMARY_ERROR,
  'payload'
)

export const onCreateContractDirectDealSummaryThunk = payload => async dispatch => {
  dispatch(onCreateContractDirectDealSummary())
  try {
    const { data } = await putCreateContractRequest({
      request: payload,
      actionType: CREATE_CONTRACT_DIRECT_DEAL_SUMMARY
    })

    return batch(() => {
      dispatch(
        onCreateContractDirectDealSummarySuccess({
          request: {
            id: data?.payload?.id,
            isActive: data?.payload?.activa,
            dni: data?.payload?.rutCotizante || '',
            answer: data?.payload?.respuestaSolicitud || '',
            legalName: data?.payload?.razonSocialDelProveedor || '',
            date: data?.payload?.fechaCreacionSolicitud || new Date()
          }
        })
      )
    })
  } catch (error) {
    return batch(() => {
      dispatch(onCreateContractDirectDealSummaryError({ error }))
      dispatch(
        onShowSnackbar({
          title: 'No ha sido posible enviar la solicitud',
          message: 'Te pedimos que lo vuelvas a intentar dentro de unos minutos.',
          severity: 'error'
        })
      )
    })
  }
}

export const GET_CONTRACT_REQUESTS_DIRECT_DEAL_SUMMARY = 'GET_CONTRACT_REQUESTS_DIRECT_DEAL_SUMMARY'
export const GET_CONTRACT_REQUESTS_DIRECT_DEAL_SUMMARY_SUCCESS = 'GET_CONTRACT_REQUESTS_DIRECT_DEAL_SUMMARY_SUCCESS'
export const GET_CONTRACT_REQUESTS_DIRECT_DEAL_SUMMARY_ERROR = 'GET_CONTRACT_REQUESTS_DIRECT_DEAL_SUMMARY_ERROR'

export const onGetContractRequestsDirectDealSummary = makeActionCreator(
  GET_CONTRACT_REQUESTS_DIRECT_DEAL_SUMMARY,
  'payload'
)
export const onGetContractRequestsDirectDealSummarySuccess = makeActionCreator(
  GET_CONTRACT_REQUESTS_DIRECT_DEAL_SUMMARY_SUCCESS,
  'payload'
)
export const onGetContractRequestsDirectDealSummaryError = makeActionCreator(
  GET_CONTRACT_REQUESTS_DIRECT_DEAL_SUMMARY_ERROR,
  'payload'
)

export const onGetContractRequestsDirectDealSummaryThunk = payload => async dispatch => {
  dispatch(onGetContractRequestsDirectDealSummary())
  try {
    const { data } = await getContractRequests({
      sheetCode: payload,
      actionType: GET_CONTRACT_REQUESTS_DIRECT_DEAL_SUMMARY
    })

    return dispatch(
      onGetContractRequestsDirectDealSummarySuccess({
        contracts: {
          requestTotal: data?.payload?.totalSolicitudes || 0,
          requestClosedDate: data?.payload?.fechaCierreCumplida || false,
          requests:
            (data?.payload?.solicitudes &&
              data?.payload?.solicitudes.map((request, index) => {
                return {
                  id: request?.id,
                  isActive: request?.activa,
                  dni: request?.rutCotizante || '',
                  answer: request?.respuestaSolicitud || '',
                  legalName: request?.razonSocialDelProveedor || '',
                  num: index + 1,
                  date: request?.fechaCreacionSolicitud || new Date(),
                  idBusiness: request?.idBusiness,
                  subBusiness: request?.subBusiness
                }
              })) ||
            []
        }
      })
    )
  } catch (error) {
    return dispatch(onGetContractRequestsDirectDealSummaryError({ error }))
  }
}

export const CLEAN_SHEET_DIRECT_DEAL_SUMMARY = 'CLEAN_SHEET_DIRECT_DEAL_SUMMARY'
export const onCleanDirectDealSummary = makeActionCreator(CLEAN_SHEET_DIRECT_DEAL_SUMMARY)

export const OPEN_ISSUE_DIALOG_DIRECT_DEAL_SUMMARY = 'OPEN_ISSUE_DIALOG_DIRECT_DEAL_SUMMARY'
export const onOpenIssueDialogDirectDealSummary = makeActionCreator(OPEN_ISSUE_DIALOG_DIRECT_DEAL_SUMMARY)

export const CLOSE_ISSUE_DIALOG_DIRECT_DEAL_SUMMARY = 'CLOSE_ISSUE_DIALOG_DIRECT_DEAL_SUMMARY'
export const onCloseIssueDialogDirectDealSummary = makeActionCreator(CLOSE_ISSUE_DIALOG_DIRECT_DEAL_SUMMARY)

export const ISSUE_PURCHASE_DIRECT_DEAL_SUMMARY = 'ISSUE_PURCHASE_DIRECT_DEAL_SUMMARY'
export const ISSUE_PURCHASE_DIRECT_DEAL_SUMMARY_SUCCESS = 'ISSUE_PURCHASE_DIRECT_DEAL_SUMMARY_SUCCESS'
export const ISSUE_PURCHASE_DIRECT_DEAL_SUMMARY_ERROR = 'ISSUE_PURCHASE_DIRECT_DEAL_SUMMARY_ERROR'

export const onIssuePurchaseDirectDealSummary = makeActionCreator(ISSUE_PURCHASE_DIRECT_DEAL_SUMMARY, 'payload')
export const onIssuePurchaseDirectDealSummarySuccess = makeActionCreator(
  ISSUE_PURCHASE_DIRECT_DEAL_SUMMARY_SUCCESS,
  'payload'
)
export const onIssuePurchaseDirectDealSummaryError = makeActionCreator(
  ISSUE_PURCHASE_DIRECT_DEAL_SUMMARY_ERROR,
  'payload'
)

export const onIssuePurchaseDirectDealSummaryThunk = payload => async dispatch => {
  dispatch(onIssuePurchaseDirectDealSummary())
  try {
    const { data } = await postIssuePurchaseToSheet({ sheetCode: payload.sheet.code })
    return dispatch(
      onIssuePurchaseDirectDealSummarySuccess({
        code: data?.payload?.codigoOrdenCompra || '',
        total: payload.sheet.total || 0,
        date: data?.payload?.fechaEmitida || '',
        status: data?.payload?.estado || '',
        id: data?.payload?.idOrdenCompra || null
      })
    )
  } catch (error) {
    return dispatch(onIssuePurchaseDirectDealSummaryError({ error }))
  }
}

export const OPEN_CONTRACT_DIALOG_DIRECT_DEAL_SUMMARY = 'OPEN_CONTRACT_DIALOG_DIRECT_DEAL_SUMMARY'
export const onOpenContractDialogDirectDealSummary = makeActionCreator(OPEN_CONTRACT_DIALOG_DIRECT_DEAL_SUMMARY)

export const CLOSE_CONTRACT_DIALOG_DIRECT_DEAL_SUMMARY = 'CLOSE_CONTRACT_DIALOG_DIRECT_DEAL_SUMMARY'
export const onCloseContractDialogDirectDealSummary = makeActionCreator(CLOSE_CONTRACT_DIALOG_DIRECT_DEAL_SUMMARY)

export const OPEN_CONTRACT_DETAIL_DIALOG_DIRECT_DEAL_SUMMARY = 'OPEN_CONTRACT_DETAIL_DIALOG_DIRECT_DEAL_SUMMARY'
export const onOpenContractDetailDialogDirectDealSummary = makeActionCreator(
  OPEN_CONTRACT_DETAIL_DIALOG_DIRECT_DEAL_SUMMARY
)

export const CLOSE_CONTRACT_DETAIL_DIALOG_DIRECT_DEAL_SUMMARY = 'CLOSE_CONTRACT_DETAIL_DIALOG_DIRECT_DEAL_SUMMARY'
export const onCloseContractDetailDialogDirectDealSummary = makeActionCreator(
  CLOSE_CONTRACT_DETAIL_DIALOG_DIRECT_DEAL_SUMMARY
)
export const APPLY_DECISION_DIRECT_DEAL_SUMMARY = 'APPLY_DECISION_DIRECT_DEAL_SUMMARY'
export const APPLY_DECISION_DIRECT_DEAL_SUMMARY_SUCCESS = 'APPLY_DECISION_DIRECT_DEAL_SUMMARY_SUCCESS'
export const APPLY_DECISION_DIRECT_DEAL_SUMMARY_ERROR = 'APPLY_DECISION_DIRECT_DEAL_SUMMARY_ERROR'

export const onApplyDecisionDirectDealSummary = makeActionCreator(APPLY_DECISION_DIRECT_DEAL_SUMMARY, 'payload')
export const onApplyDecisionDirectDealSummarySuccess = makeActionCreator(
  APPLY_DECISION_DIRECT_DEAL_SUMMARY_SUCCESS,
  'payload'
)
export const onApplyDecisionDirectDealSummaryError = makeActionCreator(
  APPLY_DECISION_DIRECT_DEAL_SUMMARY_ERROR,
  'payload'
)

export const onApplyDecisionDirectDealSummaryThunk = payload => async dispatch => {
  dispatch(onApplyDecisionDirectDealSummary())

  try {
    await postApplyDecisionDirectDealSheet({
      request: payload.decision,
      actionType: APPLY_DECISION_DIRECT_DEAL_SUMMARY
    })
    return batch(() => {
      dispatch(
        onApplyDecisionDirectDealSummarySuccess({
          mechanism: payload?.decision.mechanism,
          decision: payload?.decision.decision,
          description: payload?.decision.description
        })
      )
      dispatch(
        onShowSnackbar({
          title: 'Desistió con éxito',
          severity: 'success'
        })
      )
      if (payload?.callback) payload?.callback()
    })
  } catch (error) {
    return batch(() => {
      dispatch(onApplyDecisionDirectDealSummaryError({ error }))
      dispatch(
        onShowSnackbar({
          title: 'No ha sido posible enviar la solicitud',
          message: 'Te pedimos que lo vuelvas a intentar dentro de unos minutos.',
          severity: 'error'
        })
      )
    })
  }
}

export const GET_LAWSUITS = 'GET_LAWSUITS'
export const GET_LAWSUITS_SUCCESS = 'GET_LAWSUITS_SUCCESS'
export const GET_LAWSUITS_ERROR = 'GET_LAWSUITS_ERROR'

export const onGetLawsuits = makeActionCreator(GET_LAWSUITS, 'payload')
export const onGetLawsuitsSuccess = makeActionCreator(GET_LAWSUITS_SUCCESS, 'payload')
export const onGetLawsuitsError = makeActionCreator(GET_LAWSUITS_ERROR, 'payload')

export const onGetLawsuitsThunk = payload => async dispatch => {
  dispatch(onGetLawsuits())

  try {
    const { data } = await getLawsuitCauses({ directDealId: payload, actionType: GET_LAWSUITS })
    return dispatch(
      onGetLawsuitsSuccess({
        lawsuitAmount: {
          lawsuits: data?.payload?.totalDemandas
        }
      })
    )
  } catch (error) {
    return dispatch(onGetLawsuitsError({ error }))
  }
}

export const PREPARE_CONTRACT_DIRECT_DEAL_SUMMARY = 'PREPARE_CONTRACT_DIRECT_DEAL_SUMMARY'
export const PREPARE_CONTRACT_DIRECT_DEAL_SUMMARY_SUCCESS = 'PREPARE_CONTRACT_DIRECT_DEAL_SUMMARY_SUCCESS'
export const PREPARE_CONTRACT_DIRECT_DEAL_SUMMARY_ERROR = 'PREPARE_CONTRACT_DIRECT_DEAL_SUMMARY_ERROR'

export const onPrepareContractDirectDealSummary = makeActionCreator(PREPARE_CONTRACT_DIRECT_DEAL_SUMMARY, 'payload')
export const onPrepareContractDirectDealSummarySuccess = makeActionCreator(
  PREPARE_CONTRACT_DIRECT_DEAL_SUMMARY_SUCCESS,
  'payload'
)
export const onPrepareContractDirectDealSummaryError = makeActionCreator(
  PREPARE_CONTRACT_DIRECT_DEAL_SUMMARY_ERROR,
  'payload'
)

export const onPrepareContractDirectDealSummaryThunk = payload => async dispatch => {
  dispatch(onPrepareContractDirectDealSummary())
  try {
    const { data } = await postPrepareContractRequest({
      request: payload.sheetId,
      actionType: CREATE_CONTRACT_DIRECT_DEAL_SUMMARY
    })

    return batch(() => {
      dispatch(
        onPrepareContractDirectDealSummarySuccess({
          request: {
            id: data?.payload?.id,
            isActive: false,
            idBusiness: data?.payload?.idBusiness,
            subBusiness: data?.payload?.subBusiness
          }
        })
      )
      payload.callback()
    })
  } catch (error) {
    return batch(() => {
      dispatch(onPrepareContractDirectDealSummaryError({ error }))
      dispatch(
        onShowSnackbar({
          title: 'No ha sido posible preparar la solicitud',
          message: 'Te pedimos que lo vuelvas a intentar dentro de unos minutos.',
          severity: 'error'
        })
      )
    })
  }
}

export const GET_PUBLIC_TOKEN_DIRECT_DEAL_SUMMARY = 'GET_PUBLIC_TOKEN_DIRECT_DEAL_SUMMARY'
export const GET_PUBLIC_TOKEN_DIRECT_DEAL_SUMMARY_ERROR = 'GET_PUBLIC_TOKEN_DIRECT_DEAL_SUMMARY_ERROR'
export const GET_PUBLIC_TOKEN_DIRECT_DEAL_SUMMARY_SUCCESS = 'GET_PUBLIC_TOKEN_DIRECT_DEAL_SUMMARY_SUCCESS'
export const onGetPublicTokenDirectDealSummary = makeActionCreator(GET_PUBLIC_TOKEN_DIRECT_DEAL_SUMMARY)
export const onGetPublicTokenDirectDealSummaryError = makeActionCreator(
  GET_PUBLIC_TOKEN_DIRECT_DEAL_SUMMARY_ERROR,
  'payload'
)
export const onGetPublicTokenDirectDealSummarySuccess = makeActionCreator(GET_PUBLIC_TOKEN_DIRECT_DEAL_SUMMARY_SUCCESS)
export const onGetPublicTokenDirectDealSummaryThunk = payload => async dispatch => {
  dispatch(onGetPublicTokenDirectDealSummary())

  try {
    const { data } = await getPublicToken({ actionType: GET_PUBLIC_TOKEN_DIRECT_DEAL_SUMMARY })
    const publicAccess = {
      token: data.payload?.access_token,
      expires_in: data.payload?.expires_in
    }
    return batch(() => {
      dispatch(onGetPublicTokenDirectDealSummarySuccess())
      payload.callback(publicAccess)
    })
  } catch (error) {
    return batch(() => {
      dispatch(onGetPublicTokenDirectDealSummaryError({ error }))
      dispatch(
        onShowSnackbar({
          title: 'No ha sido posible direccionar',
          message: 'Te pedimos que lo vuelvas a intentar dentro de unos minutos.',
          severity: 'error'
        })
      )
    })
  }
}
