export const ONLY_SIMBOLS_MONEY_CHECKED = /[a-zA-Z,]/g
export const LETTERS_NUMBERS =
  /^(?![.,\s-])(?!.*[.,\s-]$)(?!.*\s[.,-])(?!.*(?:[.,-]{2,}|([.,\s-])\1))(?!.*([A-Za-z0-9À-ÖØ-öø-ÿ])\2\2)[A-Za-z0-9À-ÖØ-öø-ÿ.,\s-]+$/gi
export const ONLY_NUMBERS_CHECKED = /^\d+$/
export const ONLY_NUMBERS_AND_THOUSANDS_DOT = /^[0-9]+(\.[0-9]{3})*$/
export const REMOVED_THOUSANDS_DOT_CHECKED = /\D/g
export const ONLY_NUMBERS_CLF_CHECKED = /^(\d{1,3}(?:\.\d{3})*)(?:,\d{1,4})?$/
export const ONLY_NUMBERS_UTM_CHECKED = /^(\d{1,3}(?:\.\d{3})*)(?:,\d{1,2})?$/
export const ONLY_FORMAT_USD_CHECKED = /^\$?(\d{1,3})(,\d{3})*(\.\d{2})?$/
export const ONLY_NUMBERS_EUR_CHECKED = /^(\d{1,3}(?:\.\d{3})*)?(?:,\d{2})?$/
export const ONLY_FORMAT_INTERNATIONAL_WITH_TWO_DECIMAL_CHECKED = /^(\d{1,3}(?:\.\d{3})*)?(?:,\d{1,2})?$/
export const ONLY_FORMAT_NATIONAL_WITH_FOUR_DECIMAL_CHECKED = /^(\d{1,3}(?:\.\d{3})*)(?:,\d{1,4})?$/
export const ONLY_NUMBERS_POSITIVES_WITH_THOUSANDS_DOT_CLP = /^\$\d{1,3}(\.\d{3})*$/
export const ONLY_NUMBERS_POSITIVES_WITH_THOUSANDS_DOT_CLP_AND_SYMBOL = /^\$?$|^\$\d{1,3}(\.\d{3})*$/
