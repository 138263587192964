import { makeActionCreator } from '../../config/store/utils'
import { getCategories, getCategoriesLevelTwo, getGoodsAndServicesByCategory } from '../../services/products'

export const GET_CATEGORIES = 'GET_CATEGORIES'
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR'
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS'
export const onGetCategories = makeActionCreator(GET_CATEGORIES)
export const onGetCategoriesError = makeActionCreator(GET_CATEGORIES_ERROR, 'payload')
export const onGetCategoriesSuccess = makeActionCreator(GET_CATEGORIES_SUCCESS, 'payload')
export const onGetCategoriesThunk = () => async dispatch => {
  dispatch(onGetCategories())

  try {
    const { data } = await getCategories()

    const categories = data.payload?.clasificadores?.map(category => ({
      value: category.id,
      name: category.nombre
    }))

    return dispatch(onGetCategoriesSuccess({ categories }))
  } catch (error) {
    return dispatch(onGetCategoriesError({ error }))
  }
}

export const GET_CATEGORIES_LEVEL_TWO = 'GET_CATEGORIES_LEVEL_TWO'
export const GET_CATEGORIES_LEVEL_TWO_ERROR = 'GET_CATEGORIES_LEVEL_TWO_ERROR'
export const GET_CATEGORIES_LEVEL_TWO_SUCCESS = 'GET_CATEGORIES_LEVEL_TWO_SUCCESS'
export const onGetCategoriesLevelTwo = makeActionCreator(GET_CATEGORIES_LEVEL_TWO)
export const onGetCategoriesLevelTwoError = makeActionCreator(GET_CATEGORIES_LEVEL_TWO_ERROR, 'payload')
export const onGetCategoriesLevelTwoSuccess = makeActionCreator(GET_CATEGORIES_LEVEL_TWO_SUCCESS, 'payload')
export const onGetCategoriesLevelTwoThunk = payload => async dispatch => {
  dispatch(onGetCategoriesLevelTwo())

  try {
    const { data } = await getCategoriesLevelTwo({ category: payload.categoryId })

    const categoriesLevelTwo = data.payload?.categorias?.map(category => ({
      value: category.id,
      name: category.nombre,
      categories: category.categorias.map(category => ({
        id: category.id,
        name: category.nombre
      }))
    }))

    return dispatch(onGetCategoriesLevelTwoSuccess({ categoriesLevelTwo }))
  } catch (error) {
    return dispatch(onGetCategoriesLevelTwoError({ error }))
  }
}

export const GET_GOODS_AND_SERVICES_BY_CATEGORY = 'GET_GOODS_AND_SERVICES_BY_CATEGORY'
export const GET_GOODS_AND_SERVICES_BY_CATEGORY_ERROR = 'GET_GOODS_AND_SERVICES_BY_CATEGORY_ERROR'
export const GET_GOODS_AND_SERVICES_BY_CATEGORY_SUCCESS = 'GET_GOODS_AND_SERVICES_BY_CATEGORY_SUCCESS'
export const onGetGoodsAndServicesByCategory = makeActionCreator(GET_GOODS_AND_SERVICES_BY_CATEGORY)
export const onGetGoodsAndServicesByCategoryError = makeActionCreator(
  GET_GOODS_AND_SERVICES_BY_CATEGORY_ERROR,
  'payload'
)
export const onGetGoodsAndServicesByCategorySuccess = makeActionCreator(
  GET_GOODS_AND_SERVICES_BY_CATEGORY_SUCCESS,
  'payload'
)
export const onGetGoodsAndServicesByCategoryThunk = payload => async dispatch => {
  dispatch(onGetGoodsAndServicesByCategory())

  try {
    const { data } = await getGoodsAndServicesByCategory({ category: payload.categoryId })

    const goodsAndServices = data.payload?.productos?.map(goodAndService => ({
      value: goodAndService.id,
      name: goodAndService.nombre,
      categoryId: goodAndService.categoria
    }))

    return dispatch(
      onGetGoodsAndServicesByCategorySuccess({
        categoryName: payload.categoryName,
        goodsAndServices
      })
    )
  } catch (error) {
    return dispatch(onGetGoodsAndServicesByCategoryError({ error }))
  }
}

export const REMOVE_SEARCHING_CATEGORIES_PRODUCTS = 'REMOVE_SEARCHING_CATEGORIES_PRODUCTS'
export const onRemoveSearchingCategoriesProducts = makeActionCreator(REMOVE_SEARCHING_CATEGORIES_PRODUCTS, 'payload')
