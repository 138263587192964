import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { Typography, Radio, Input, DatePicker } from '@chilecompra/react-kit/components'
import {
  checkInputErrorRequired,
  checkInputErrorMaxLength,
  useInput,
  useDatePicker,
  useRadio,
  checkInputErrorDni
} from '@chilecompra/react-kit'

import { onGetSignerInfoDNIThunk } from './DirectDealStepZero.actions'

import { SearchSuffix, SearchIconCustom, GridItem, GridContainer } from './DirectDealStepZero.styles'

import { formatDNI, unformatDNI } from '../../modules/utils/formatters'
import { useImperativeFormRef } from '../../modules/hooks/useFormRef'
import {
  DIRECT_DEAL_STEP_ZERO_OPTIONS,
  DIRECT_DEAL_DECLARATION_ORIGIN_DATE,
  DIRECT_DEAL_DECLARATION_OLD_ORIGIN_DATE
} from '../../config/settings/constants'

/**
 * The DirectDealOrigin's
 */
const DirectDealOrigin = props => {
  const { signerInfo, loading, formRef, values } = props

  const dispatch = useDispatch()

  const [minDate, setMinDate] = useState(null)
  const [maxDate, setMaxDate] = useState(null)

  const {
    error: resolutionNumberError,
    onChange: handleResolutionNumberChange,
    onError: handleResolutionNumberError,
    value: resolutionNumberValue
  } = useInput({
    errorCallbacks: [checkInputErrorMaxLength(99), checkInputErrorRequired()],
    initialValue: values?.resolution || ''
  })

  const {
    error: endDateExecuteError,
    onChange: handleEndDateExecuteChange,
    onError: handleEndDateExecuteError,
    setValue: setEndDateExecuteValue,
    value: endDateExecuteValue
  } = useDatePicker({
    errorCallbacks: [checkInputErrorRequired()],
    initialValue: values?.resolutionDate || new Date()
  })

  const {
    error: signerError,
    onChange: handleSignerChange,
    onError: handleSignerError,
    value: signerValue
  } = useInput({
    changeCallback: () => {
      setNameValue('')
      setLastNameValue('')
    },
    errorCallbacks: [checkInputErrorRequired(), checkInputErrorDni()],
    formatCallbacks: [value => formatDNI(value)],
    initialValue: values?.dni || ''
  })

  const {
    error: nameError,
    onChange: handleNameChange,
    onError: handleNameError,
    onReset: handleNameReset,
    setValue: setNameValue,
    value: nameValue
  } = useInput({
    initialValue: values?.names || '',
    errorCallbacks: [checkInputErrorRequired()]
  })

  const {
    error: lastNameError,
    onChange: handleLastNameChange,
    onError: handleLastNameError,
    onReset: handleLastNameReset,
    value: lastNameValue,
    setValue: setLastNameValue
  } = useInput({
    initialValue: values?.lastNames || '',
    errorCallbacks: [checkInputErrorRequired()]
  })

  const {
    error: chargeError,
    onChange: handleChargeChange,
    onError: handleChargeError,
    value: chargeValue
  } = useInput({
    initialValue: values?.job || '',
    errorCallbacks: [checkInputErrorRequired()]
  })

  const { value: originValue, onChange: handleOriginChange } = useRadio({
    errorCallbacks: [checkInputErrorRequired()],
    initialValue: values?.origin || DIRECT_DEAL_STEP_ZERO_OPTIONS[1].value,
    changeCallback: updatedValue => {
      if (updatedValue === DIRECT_DEAL_STEP_ZERO_OPTIONS[0].value) {
        setEndDateExecuteValue(new Date(DIRECT_DEAL_DECLARATION_OLD_ORIGIN_DATE))
        setMaxDate(new Date(DIRECT_DEAL_DECLARATION_OLD_ORIGIN_DATE))
        setMinDate(null)
      } else if (updatedValue === DIRECT_DEAL_STEP_ZERO_OPTIONS[1].value) {
        setEndDateExecuteValue(new Date(DIRECT_DEAL_DECLARATION_ORIGIN_DATE))
        setMinDate(new Date(DIRECT_DEAL_DECLARATION_ORIGIN_DATE))
        setMaxDate(null)
      }
    }
  })

  const handlerSearchDni = () => {
    dispatch(onGetSignerInfoDNIThunk({ dni: unformatDNI(signerValue) }))
  }

  useEffect(() => {
    handleNameReset()
    handleLastNameReset()

    setNameValue(signerInfo?.names || values?.names || '')
    setLastNameValue(signerInfo?.lastNames || values?.lastNames || '')
  }, [signerInfo])

  useImperativeFormRef(
    formRef,
    () => ({
      resolution: {
        error: resolutionNumberError,
        onError: handleResolutionNumberError,
        value: resolutionNumberValue
      },
      dateExecute: {
        error: endDateExecuteError,
        onError: handleEndDateExecuteError,
        value: endDateExecuteValue
      },
      signer: {
        error: signerError,
        onError: handleSignerError,
        value: signerValue
      },
      name: {
        error: nameError,
        onError: handleNameError,
        value: nameValue
      },
      lastName: {
        error: lastNameError,
        onError: handleLastNameError,
        value: lastNameValue
      },
      charge: {
        error: chargeError,
        onError: handleChargeError,
        value: chargeValue
      },
      origin: {
        value: originValue
      }
    }),
    [
      resolutionNumberValue,
      resolutionNumberError,
      endDateExecuteValue,
      endDateExecuteError,
      signerValue,
      signerError,
      nameError,
      nameValue,
      lastNameError,
      lastNameValue,
      chargeValue,
      chargeError,
      originValue
    ]
  )

  return (
    <GridContainer margin="33px 0" spacing={2}>
      <GridItem lg={2} md={2} sm={12} xs={12}>
        <Typography fontWeight="bold" variant="body2" fontSize="16px" padding="0 0 16px">
          Origen del Trato Directo
        </Typography>
        <Typography fontSize="14px" fontWeight="regular" lineHeight="18px" variant="body2">
          Indícanos si este nuevo Trato Directo proviene de una resolución o decreto que haya sido firmado antes de la
          entrada en vigencia de la nueva ley de compras públicas N°19.886.
        </Typography>
      </GridItem>

      <GridItem lg={6} md={6} sm={12} xs={12}>
        <Typography fontWeight="bold" variant="body2" fontSize="16px" padding="0 8px 16px">
          ¿El Trato Directo proviene de una resolución firmada antes del día 12 de diciembre de 2024?
        </Typography>
        <Radio onChange={handleOriginChange} options={DIRECT_DEAL_STEP_ZERO_OPTIONS} value={originValue} />
        <GridContainer spacing={1} margin="10px 0 0" padding="16px 14px 0 0">
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <Typography fontWeight="bold" variant="body2" fontSize="16px" padding="0 0 16px">
              Acto administrativo o documento de intención de Trato Directo
            </Typography>
          </GridItem>
          <GridItem lg={6} md={6} sm={6} xs={12}>
            <Input
              error={resolutionNumberError}
              label="Número"
              maxLength={100}
              onChange={handleResolutionNumberChange}
              onError={handleResolutionNumberError}
              value={resolutionNumberValue}
            />
          </GridItem>
          <GridItem lg={6} md={6} sm={6} xs={12}>
            <DatePicker
              width="100%"
              maxDate={maxDate}
              minDate={minDate}
              error={endDateExecuteError}
              format="dd/MM/yyyy"
              label="Fecha"
              placeholder="Fecha"
              value={endDateExecuteValue}
              onChange={handleEndDateExecuteChange}
            />
          </GridItem>
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <Typography fontWeight="bold" variant="body2" fontSize="16px" padding="32px 0 16px">
              Responsable de la contratación
            </Typography>
          </GridItem>
          <GridItem lg={6} md={6} sm={6} xs={12}>
            <Input
              label="Rut responsable"
              value={signerValue}
              onChange={handleSignerChange}
              onError={handleSignerError}
              error={signerError}
              suffix={
                <SearchSuffix aria-label="Buscar RUT" onClick={handlerSearchDni}>
                  <SearchIconCustom />
                </SearchSuffix>
              }
            />
          </GridItem>
          <GridItem md={6} sm={6} />
          <GridItem lg={6} md={6} sm={6} xs={12}>
            <Input
              label="Nombre"
              value={nameValue}
              onChange={handleNameChange}
              onError={handleNameError}
              error={nameError}
              loading={loading}
              disabled
            />
          </GridItem>
          <GridItem lg={6} md={6} sm={6} xs={12}>
            <Input
              label="Apellido"
              value={lastNameValue}
              onChange={handleLastNameChange}
              onError={handleLastNameError}
              error={lastNameError}
              loading={loading}
              disabled
            />
          </GridItem>
          <GridItem lg={12} md={12} sm={12} xs={12}>
            <Input
              label="Cargo"
              value={chargeValue}
              onChange={handleChargeChange}
              onError={handleChargeError}
              error={chargeError}
            />
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem lg={4} md={4} sm={12} xs={12} />
    </GridContainer>
  )
}

DirectDealOrigin.propTypes = {
  loading: PropTypes.bool,
  signerInfo: PropTypes.shape({
    names: PropTypes.string,
    lastNames: PropTypes.string
  }),
  formRef: PropTypes.shape({
    current: PropTypes.shape(Object)
  }),
  values: PropTypes.shape({
    resolution: PropTypes.string,
    resolutionDate: PropTypes.string,
    names: PropTypes.string,
    lastNames: PropTypes.string,
    dni: PropTypes.string,
    job: PropTypes.string,
    origin: PropTypes.string
  })
}

export default DirectDealOrigin
