import React, { lazy, Suspense } from 'react'
import { Skeleton } from '@chilecompra/react-kit'
import PropTypes from 'prop-types'

const Attachments = lazy(() => import('remote/FrontAdjuntos'))

/**
 * The AttachmentsWrapper.
 *
 * business = 3000 DIRECT DEAL PROYECT
 * idBusiness = Code unique auto-increment
 * subBusiness = Profiles allow to load files (1: Buyer) (2: Seller)
 */
const AttachmentsWrapper = props => {
  const {
    business,
    canDelete = false,
    canDeleteBulk = false,
    canRead = false,
    canReadBulk = false,
    canWrite = false,
    canWriteBulk = false,
    color,
    idBusiness,
    isPrivate,
    onOpenModalAction = () => {},
    openModal = false,
    openModalDeleteAll = false,
    setOpenModal = () => {},
    setOpenModalDeleteAll = () => {},
    showIconList = false,
    showModal,
    subBusiness,
    typeView
  } = props

  return (
    <Suspense fallback={<Skeleton width="150px" />}>
      <Attachments
        business={business?.toString()}
        idBusiness={idBusiness?.toString()}
        color={color}
        DELETE={canDelete}
        DELETEBULK={canDeleteBulk}
        isPrivate={isPrivate}
        LIST
        READ={canRead}
        READBULK={canReadBulk}
        showIconList={showIconList}
        showModal={!!showModal}
        subBusiness={subBusiness?.toString()}
        typeView={typeView}
        WRITE={canWrite}
        WRITEBULK={canWriteBulk}
        onOpenModalAction={onOpenModalAction}
        openModal={openModal}
        openModalDeleteAll={openModalDeleteAll}
        setOpenModal={setOpenModal}
        setOpenModalDeleteAll={setOpenModalDeleteAll}
      />
    </Suspense>
  )
}

AttachmentsWrapper.propTypes = {
  business: PropTypes.number,
  canDelete: PropTypes.bool,
  canDeleteBulk: PropTypes.bool,
  canRead: PropTypes.bool,
  canReadBulk: PropTypes.bool,
  canWrite: PropTypes.bool,
  canWriteBulk: PropTypes.bool,
  color: PropTypes.string,
  idBusiness: PropTypes.number,
  isPrivate: PropTypes.bool,
  onOpenModalAction: PropTypes.func,
  showIconList: PropTypes.bool,
  showModal: PropTypes.bool,
  subBusiness: PropTypes.number,
  typeView: PropTypes.string,
  openModal: PropTypes.bool,
  openModalDeleteAll: PropTypes.bool,
  setOpenModal: PropTypes.func,
  setOpenModalDeleteAll: PropTypes.func
}

export default AttachmentsWrapper
