import {
  ONLY_NUMBERS_AND_THOUSANDS_DOT,
  ONLY_FORMAT_INTERNATIONAL_WITH_TWO_DECIMAL_CHECKED,
  ONLY_FORMAT_NATIONAL_WITH_FOUR_DECIMAL_CHECKED
} from './regexes'

import { CURRENCY_TRANSLATION } from '../../config/settings/constants'

/**
 * Checks if the value is below the minimum and returns an error message if true
 *
 * @param {number} minValue
 * @param {string} customMessage
 * @returns
 */
export const checkInputErrorMinValue = (minValue, customMessage) => value => {
  return value < minValue ? customMessage || `El monto total cotizado no debe ser inferior a ${minValue}.` : ''
}

/**
 * Returns the appropriate regular expression for validating currency input
 * based on the provided currency type.
 *
 * Depending on the `currencyType` parameter, it matches specific formats:
 * - 'CLP': Only numbers with thousand separators (dot format).
 * - 'USD': Standard US Dollar format with potential checks.
 * - 'CLF' & 'UTM': Special formats for Chilean financial units (CLF and UTM).
 *
 * @param {string} currencyType - The type of currency ('CLP', 'USD', 'CLF', or 'UTM').
 * @returns {RegExp} - A regular expression tailored to the currency type.
 */
export function getRegexByCurrency(currencyType) {
  if (currencyType === CURRENCY_TRANSLATION.CLP.cod) {
    return ONLY_NUMBERS_AND_THOUSANDS_DOT
  } else if (currencyType === CURRENCY_TRANSLATION.CLF.cod || currencyType === CURRENCY_TRANSLATION.UTM.cod) {
    return ONLY_FORMAT_NATIONAL_WITH_FOUR_DECIMAL_CHECKED
  } else if (currencyType === CURRENCY_TRANSLATION.USD.cod || currencyType === CURRENCY_TRANSLATION.EUR.cod) {
    return ONLY_FORMAT_INTERNATIONAL_WITH_TWO_DECIMAL_CHECKED
  } else {
    return true
  }
}

/**
 * Checks if the value is below the maximun and returns an error message if true
 *
 * @param {number} maxValue
 * @param {string} customMessage
 * @returns
 */
export const checkInputErrorMaxValue = (maxValue, customMessage) => value => {
  return value > maxValue ? customMessage || `El monto total cotizado no debe ser mayor a ${maxValue}.` : ''
}

/**
 * Checks if the value is below the maximun and returns an error message if true
 *
 * @param {number} maxValue
 * @param {string} customMessage
 * @returns
 */
export const checkInputErrorCustomValue = (condicion, customMessage) => () => {
  return condicion ? customMessage || `Campo erroneo` : ''
}
