import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { PUBLIC_URL } from './config/settings/environment'
import routes from './config/settings/routes'

import DirectDealSearch from './pages/DirectDealSearch/DirectDealSearch'
import DirectDealStepZero from './pages/DirectDealStepZero/DirectDealStepZero'
import DirectDealStepOne from './pages/DirectDealStepOne/DirectDealStepOne'
import DirectDealStepTwo from './pages/DirectDealStepTwo/DirectDealStepTwo'
import DirectDealSummary from './pages/DirectDealSummary/DirectDealSummary'
import DirectDealSearchPublic from './pages/DirectDealSearch/DirectDealSearch.public'
import DirectDealVoucher from './pages/DirectDealVoucher/DirectDealVoucher'
import NotFoundPage from './pages/NotFound/NotFound'
import ProductSearch from './pages/ProductSearch/ProductSearch'

import ScrollToTop from './components/components/ScrollToTop.component'
import DirectDealSummaryPublic from './pages/DirectDealSummary/DirectDealSummary.public'

/**
 * The Routing' component.
 */
const Routing = () => {
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <ScrollToTop />
      <Routes>
        <Route caseSensitive element={<DirectDealSearch />} path={routes.root} />
        <Route caseSensitive element={<DirectDealSearch />} path={routes.search} />
        <Route caseSensitive element={<DirectDealSearchPublic />} path={routes.searchPublic} />

        <Route caseSensitive element={<DirectDealStepZero />} path={routes.directDealStepZero} />
        <Route caseSensitive element={<DirectDealStepOne />} path={routes.directDealStepOne} />
        <Route caseSensitive element={<DirectDealStepTwo />} path={routes.directDealStepTwo} />

        <Route caseSensitive element={<DirectDealSummary />} path={routes.directDealSummary} />
        <Route caseSensitive element={<DirectDealSummaryPublic />} path={routes.directDealSummaryPublic} />

        <Route caseSensitive element={<DirectDealVoucher />} path={routes.directDealVoucher} />

        <Route caseSensitive element={<ProductSearch />} path={routes.productSearch} />

        <Route element={<NotFoundPage />} path="*" />
      </Routes>
    </BrowserRouter>
  )
}

export default Routing
