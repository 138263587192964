import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Typography, Skeleton } from '@chilecompra/react-kit/components'
import { useQuery } from '@chilecompra/react-kit'

import { Grid, GridItem } from './DirectDealSearchDownload.styles'
import { onDownloadDirectDealResultsThunk } from './DirectDealSearchDownload.actions'

import DirectDealSearchDownloadLink from './DirectDealSearchDownload.link'

import { useAuthorization } from '../AuthProvider/AuthProvider.hooks'

/**
 * The DirectDealSearchDownload container.
 */
const DirectDealSearchDownload = () => {
  const { loading: searchLoading, searchResult, error: searchError } = useSelector(state => state.searchFilters)
  const { loading: downLoading } = useSelector(state => state.directDealSearchDownload)

  const dispatch = useDispatch()
  const query = useQuery()
  const { isBuyer, isSeller } = useAuthorization()

  const handleDownload = event => {
    event.preventDefault()
    dispatch(onDownloadDirectDealResultsThunk({ query }))
  }

  return (
    <Grid>
      <GridItem margin="10px 0 16px 0">
        {searchLoading && <Skeleton width="150px" />}
        {!searchLoading && (
          <Typography variant="h4" fontWeight="bold" fontSize="16px">
            Existen {searchResult.resultCount} resultados para tu búsqueda
          </Typography>
        )}
      </GridItem>

      {isBuyer && (
        <DirectDealSearchDownloadLink
          error={searchError}
          loadingDownload={downLoading}
          loadingSearch={searchLoading}
          onDownload={handleDownload}
          resultCount={searchResult.resultCount}
          rule={features => features['download.link.excel.result.buyer']}
        />
      )}
      {isSeller && (
        <DirectDealSearchDownloadLink
          error={searchError}
          loadingDownload={downLoading}
          loadingSearch={searchLoading}
          onDownload={handleDownload}
          resultCount={searchResult.resultCount}
          rule={features => features['download.link.excel.result.seller']}
        />
      )}
      {!isSeller && !isBuyer && (
        <DirectDealSearchDownloadLink
          error={searchError}
          loadingDownload={downLoading}
          loadingSearch={searchLoading}
          onDownload={handleDownload}
          resultCount={searchResult.resultCount}
          rule={features => features['download.link.excel.result.public']}
        />
      )}
    </Grid>
  )
}

export default DirectDealSearchDownload
