import React from 'react'
import PropTypes from 'prop-types'

import { useViewport } from '@chilecompra/react-kit'
import { Button, Dialog, Typography } from '@chilecompra/react-kit/components'

import { GridContainer, GridItem, Link, ProviderNotSuitableImage } from '../DirectDealStepTwo.styles'

/**
 * The DirectDealStepTwo's ability dialog.
 */
const DirectDealStepTwoAbilityDialog = props => {
  const { open, onClose, onRedirect } = props

  const { size } = useViewport()

  return (
    <Dialog
      open={open}
      disableBackdropClick={false}
      maxWidth={size.isTiny || size.isSmall ? '400px' : '700px'}
      height="522px"
      verticalAlign="top"
      onClose={onClose}
    >
      <GridContainer
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{
          padding: {
            xs: '33px 16px',
            md: '33px 30px',
            lg: '33px 53px',
            sm: '33px 24px'
          }
        }}
      >
        <GridItem>
          <ProviderNotSuitableImage alt="Proveedor sin habilidad" src="/assets/images/ProviderNotSuitable.svg" />
        </GridItem>
        <GridItem>
          <GridItem item textAlign="-webkit-center">
            <Typography align="center" variant="h3" lineHeight="26px" color="black1" fontWeight="bold">
              Para crear el Trato Directo el proveedor <br />
              debe estar en estado hábil
            </Typography>
          </GridItem>
          <GridItem padding="16px 24px">
            <Typography align="center" variant="body1" regular lineHeight="21px" color="black1">
              El proveedor con el cuál deseas realizar el Trato Directo no se encuentra hábil según la información del
              Registro de Proveedores. Revisa más información sobre este proveedor accediendo a su{' '}
              <Link onClick={onRedirect}>ficha en Mercado Público</Link>
            </Typography>
          </GridItem>
        </GridItem>
        <GridItem item padding="0 30px" margin="4px 0" textAlign="center">
          <Button aria-label="Cerrar" color="default" variant="contained" onClick={onClose}>
            Cerrar
          </Button>
        </GridItem>
      </GridContainer>
    </Dialog>
  )
}

DirectDealStepTwoAbilityDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onRedirect: PropTypes.func
}

export default DirectDealStepTwoAbilityDialog
