import React from 'react'

import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material'
import { Divider as DividerBase, Grid as BaseGrid, ListItemText as BaseListItemText, styled } from '@mui/material'
import { CircleSuccessIcon as CircleSuccessIconBase } from '@chilecompra/react-kit/components'

export const Root = styled('div')`
  width: 100%;
`

export const AddProduct = styled('div')`
  color: ${props => props.theme.palette.green[50]};
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 21px;
`

export const ButtonWrapper = styled('section')`
  width: 100%;
  margin-left: 10px;
`

export const Divider = styled(({ margin, ...props }) => <DividerBase {...props} />)`
  &.MuiDivider-root {
    margin: ${props => props.margin};
  }
`

export const SkeletonWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`

export const Link = styled('a')`
  color: ${props => props.theme.palette.gray[50]};
  cursor: pointer;
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 16px;
  height: 20px;
  letter-spacing: 0;
  line-height: 20px;
  width: 57px;

  &::before {
    content: '<';
    margin-right: 15px;
  }
`

export const LinkAddProduct = styled('button')`
  color: ${props => props.theme.palette.blue[50]};
  cursor: pointer;
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 23px;
  border: none;
  background-color: ${props => props.theme.palette.brand.white};
`

export const Grid = styled(BaseGrid)`
  margin: ${props => props.margin};
  width: 100%;
`

export const ListItemText = styled(BaseListItemText)`
  & .MuiListItemText-root {
    padding-left: 10px;
    margin: 5px 0;
  }
  & .MuiListItemText-primary {
    color: ${props => props.theme.palette.brand.black1};
    font-size: 16px;
    font-weight: bold;
    padding-left: 10px;
    cursor: pointer;
  }
  & .MuiListItemText-primary:hover {
    background-color: ${props => props.theme.palette.brand.gray2};
  }
  & .MuiListItemText-secondary:hover {
    background-color: ${props => props.theme.palette.brand.gray3};
  }
`
export const ListItemSecondaryText = styled('button')`
  color: ${props => props.theme.palette.brand.black1};
  font-size: 14px;
  font-weight: bold;
  padding-left: 20px;
  cursor: pointer;
  border: none;
  background-color: ${props => props.theme.palette.brand.white};
`

export const GridRow = styled(({ alignItems, background = false, border = false, margin, textAlign, ...props }) => (
  <BaseGrid container {...props} />
))`
  align-items: ${props => props.alignItems};
  background-color: ${props => props.background && props.theme.palette.green[95]};
  border: ${props => props.border && '1px solid'};
  border-color: ${props => props.border && props.theme.palette.green[75]};
  margin: ${props => props.margin};
  padding: 5px 0;
  text-align: ${props => props.textAlign};
  width: 100%;
`

export const GridColumn = styled(({ alignItems, background = false, border = false, margin, ...props }) => (
  <BaseGrid item {...props} />
))`
  align-items: ${props => props.alignItems};
  background-color: ${props => props.background && props.theme.palette.green[95]};
  border: ${props => props.border && '1px solid'};
  border-color: ${props => props.border && props.theme.palette.green[75]};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  width: 100%;
`

export const ArrowDownIcon = styled(({ size, ...props }) => <KeyboardArrowDown {...props} />)`
  &.MuiSvgIcon-root {
    fill: ${props => props.theme.palette.brand.primary};
    font-size: ${props => {
      if (props.size === 'large') {
        return '26px'
      }

      if (props.size === 'small') {
        return '20px'
      }

      return '24px'
    }};
  }
`

export const ArrowRightIcon = styled(({ size, ...props }) => <KeyboardArrowRight {...props} />)`
  &.MuiSvgIcon-root {
    fill: ${props => props.theme.palette.brand.primary};
    font-size: ${props => {
      if (props.size === 'large') {
        return '26px'
      }

      if (props.size === 'small') {
        return '20px'
      }

      return '24px'
    }};
  }
`

export const StatusWrapper = styled(({ justifyContent, ...props }) => <div {...props} />)`
  display: flex;
  gap: 10px;
  justify-content: ${props => props.justifyContent};
  margin-left: 10px;
  text-align: center;
`
export const CircleSuccessIcon = styled(({ fontSize, ...props }) => <CircleSuccessIconBase {...props} />)`
  &.MuiSvgIcon-root {
    font-size: ${props => props.fontSize};
  }
`
