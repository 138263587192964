import {
  CLEAN_INITIAL_SHEET_DIRECT_DEAL_STEP_ZERO,
  CLEAN_SIGNER_DIRECT_DEAL_STEP_ZERO,
  GET_INFO_DNI_SIGNER,
  GET_INFO_DNI_SIGNER_SUCCESS,
  GET_INFO_DNI_SIGNER_ERROR,
  RETURN_INFO_STEP_ZERO_DIRECT_DEAL_STEP_ZERO
} from './DirectDealStepZero.actions'

const directDealStepZeroState = {
  error: undefined,
  loading: false,
  initialSheet: null,
  signer: null
}

/**
 * The DirectDealStepOne reducer.
 */
const directDealStepZeroReducer = (state = directDealStepZeroState, { payload, type }) => {
  switch (type) {
    case GET_INFO_DNI_SIGNER: {
      return { ...state, error: undefined, loading: true }
    }
    case GET_INFO_DNI_SIGNER_ERROR: {
      return { ...state, error: payload.error, loading: false }
    }
    case GET_INFO_DNI_SIGNER_SUCCESS: {
      return { ...state, error: undefined, loading: false, signer: payload }
    }
    case RETURN_INFO_STEP_ZERO_DIRECT_DEAL_STEP_ZERO: {
      return { ...state, initialSheet: payload }
    }
    case CLEAN_SIGNER_DIRECT_DEAL_STEP_ZERO: {
      return { ...state, signer: null }
    }
    case CLEAN_INITIAL_SHEET_DIRECT_DEAL_STEP_ZERO: {
      return { ...state, initialSheet: null }
    }
    default: {
      return state
    }
  }
}

export default directDealStepZeroReducer
