import React from 'react'
import { styled, Divider as DividerBase, Grid as BaseGrid } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

export const Divider = styled(DividerBase)`
  width: 100%;
`
export const Grid = styled(({ ...props }) => <BaseGrid {...props} />)`
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  width: 100%;
  text-align: ${props => props.textAlign};
`
export const RowRadio = styled('div')`
  text-align: left;
  width: 100%;
  margin-top: 8px;
`

export const SearchIconCustom = styled(SearchIcon)`
  width: 20px;
  height: 20px;
`

export const SearchSuffix = styled('button')`
  border-right: 1px solid ${props => props.theme.palette.blue[50]};
  background-color: ${props => props.theme.palette.blue[50]};
  color: ${props => props.theme.palette.brand.white};
  padding: 10px;
  cursor: pointer;
  border: none;
  outline: none;

  &:hover {
    background-color: ${props => props.theme.palette.blue[100]};
  }
`

export const Strong = styled('strong')`
  font-size: 14px;
`

export const Root = styled('div')`
  margin: 0 auto;
  padding: 0 0 20px;
  width: 100%;
`
export const Row = styled(({ margin, ...props }) => <BaseGrid container {...props} />)`
  margin: ${props => props.margin || '0 -5px'};
  padding: ${props => props.padding};
  width: 100%;
`

export const DeleteLink = styled('a')`
  color: ${props => props.theme.palette.brand.black3};
  text-decoration: underline;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
`

export const Column = styled(({ margin, padding, ...props }) => <BaseGrid item {...props} />)`
  &.MuiGrid-item {
    margin: ${props => props.margin || 0};
    padding: ${props => props.padding || 0};
    width: 100%;
  }
`

export const AmountPrefix = styled('abbr')`
  background-color: ${props => props.theme.palette.brand.gray3};
  height: 100%;
  width: 35px;
  align-content: center;
  text-align-last: center;
`

export const DescriptionContainer = styled('div')`
  width: 53%;
`
export const UploadDiv = styled('div')`
  cursor: pointer;
  width: 100%;
  height: 300px;
  border: solid 1.5px;
  border-radius: 8px;
  border-color: ${props => props.theme.palette.gray[0.16]};
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
`

export const SeparatorDiv = styled('div')`
  border: solid 1px;
  border-color: ${props => props.theme.palette.gray[0.16]};
  width: 98%;
  ${props => props.theme.breakpoints.up('lg')} {
    margin-top: 24px;
  }
`
export const SectionDiv = styled('div')`
  border: solid 1px;
  border-color: ${props => props.theme.palette.gray[0.16]};
  width: 77%;
  margin: 36px 10px 0;
  ${props => props.theme.breakpoints.up('lg')} {
    margin-top: 40px;
  }
`
export const GenericDiviser = styled('div')`
  border: solid 1px;
  border-color: ${props => props.theme.palette.gray[0.16]};
  width: 100%;
  margin: 36px 10px 0;
  ${props => props.theme.breakpoints.up('lg')} {
    margin-top: 40px;
  }
`

export const GridContainer = styled(({ textAlign, ...props }) => <BaseGrid container {...props} />)`
  &.MuiGrid-container {
    height: ${props => props.height};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
  }
`

export const GridItem = styled(({ textAlign, ...props }) => <BaseGrid item {...props} />)`
  &.MuiGrid-item {
    margin: ${props => props.margin};
    text-align: ${props => props.textAlign};

    ${props => props.theme.breakpoints.only('xs')} {
      padding: ${props => props.padding || '7px 0'};
    }

    ${props => props.theme.breakpoints.only('sm')} {
      padding: ${props => props.padding || '7px 8px'};
    }

    ${props => props.theme.breakpoints.only('md')} {
      padding: ${props => props.padding || '7px 16px'};
    }

    ${props => props.theme.breakpoints.only('lg')} {
      padding: ${props => props.padding || '7px 16px'};
    }

    ${props => props.theme.breakpoints.only('xl')} {
      padding: ${props => props.padding || '7px 16px'};
    }
  }
`
export const SuccessTableItem = styled('div')`
  background-color: ${props => props.theme.palette.blue[95]};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  border-bottom: 1px solid ${props => props.theme.palette.gray[0.16]};
  height: 50px;
  width: 30%;
`
export const SuccessTableItemDescription = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  border-bottom: 1px solid ${props => props.theme.palette.gray[0.16]};
  height: 50px;
  width: 70%;
`

export const SpanTitleAccordion = styled('span')`
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: ${props => props.theme.palette.brand.black3};
  margin-left: 10px;
`
