import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { Card, Input, Typography } from '@chilecompra/react-kit/components'
import {
  checkInputErrorRequired,
  checkInputErrorMaxLength,
  checkInputErrorEmail,
  useInput
} from '@chilecompra/react-kit'

import { Grid, GridItem, SearchSuffix, SearchIconCustom } from './DirectDealStepOne.styles'
import { onGetInfoDNIThunk } from './DirectDealStepOne.actions'

import { useImperativeFormRef } from '../../modules/hooks/useFormRef'
import { formatDNI, unformatDNI } from '../../modules/utils/formatters'
import { DIRECT_DEAL_MORE_DOCUMENTS_ARE_REQUIRED, BINARY_CONFIRMATION_RESPONSE } from '../../config/settings/constants'

/**
 * The DirectDealStepOne's requester information.
 */
const DirectDealRequester = props => {
  const { formRef, loading, requesterInfo, requiredMoreDocuments } = props

  const dispatch = useDispatch()
  const {
    error: dniError,
    onChange: handleDniChange,
    onError: handleDniError,
    value: dniValue
  } = useInput({
    changeCallback: () => {
      setNameValue('')
      setLastNameValue('')
    },
    errorCallbacks: [checkInputErrorRequired()],
    formatCallbacks: [value => formatDNI(value)]
  })
  const {
    error: nameError,
    onChange: handleNameChange,
    onError: handleNameError,
    onReset: handleNameReset,
    setValue: setNameValue,
    value: nameValue
  } = useInput({
    errorCallbacks: [checkInputErrorRequired()]
  })
  const {
    error: lastNameError,
    onChange: handleLastNameChange,
    onError: handleLastNameError,
    onReset: handleLastNameReset,
    setValue: setLastNameValue,
    value: lastNameValue
  } = useInput({
    errorCallbacks: [checkInputErrorRequired()]
  })
  const {
    error: jobError,
    onChange: handleJobChange,
    onError: handleJobError,
    value: jobValue
  } = useInput({
    errorCallbacks: [checkInputErrorRequired()]
  })
  const {
    error: emailError,
    onChange: handleEmailChange,
    onError: handleEmailError,
    value: emailValue
  } = useInput({
    errorCallbacks: [
      checkInputErrorRequired(),
      checkInputErrorMaxLength(200),
      checkInputErrorEmail('Formato de email incorrecto.')
    ]
  })

  const handlerSearchDni = () => {
    dispatch(onGetInfoDNIThunk({ dni: unformatDNI(dniValue) }))
  }

  useEffect(() => {
    handleNameReset()
    handleLastNameReset()

    setNameValue(requesterInfo?.names || '')
    setLastNameValue(requesterInfo?.lastNames || '')
  }, [requesterInfo])

  useImperativeFormRef(
    formRef,
    () => ({
      dni: {
        error: dniError,
        onError: handleDniError,
        value: dniValue
      },
      email: {
        error: emailError,
        onError: handleEmailError,
        value: emailValue
      },
      job: {
        error: jobError,
        onError: handleJobError,
        value: jobValue
      },
      lastName: {
        error: lastNameError,
        onError: handleLastNameError,
        value: lastNameValue
      },
      name: {
        error: nameError,
        onError: handleNameError,
        value: nameValue
      }
    }),
    [dniError, dniValue, emailError, emailValue, jobError, jobValue, lastNameError, lastNameValue, nameError, nameValue]
  )

  return (
    <Grid container margin="33px 0" spacing={2}>
      <GridItem xs={12} md={2}>
        <Typography fontWeight="bold" variant="body1" margin="0 0 8px">
          Requirente del Trato Directo
        </Typography>
        <Typography fontSize="14px" fontWeight="regular" lineHeight="18px" variant="body2">
          Información de la persona que realizó la solicitud de compra
        </Typography>
      </GridItem>
      <GridItem xs={12} md={6}>
        <Grid container spacing={1}>
          <GridItem xs={12} sm={6} md={6}>
            <Input
              label="RUT"
              value={dniValue}
              onChange={handleDniChange}
              onError={handleDniError}
              error={dniError}
              suffix={
                <SearchSuffix aria-label="Buscar RUT" onClick={handlerSearchDni}>
                  <SearchIconCustom />
                </SearchSuffix>
              }
              loading={loading}
            />
          </GridItem>

          <GridItem md={6} />

          <GridItem xs={12} sm={6} md={6}>
            <Input
              label="Nombres"
              disabled
              value={nameValue}
              onChange={handleNameChange}
              onError={handleNameError}
              error={nameError}
              loading={loading}
            />
          </GridItem>

          <GridItem xs={12} sm={6} md={6}>
            <Input
              label="Apellidos"
              disabled
              value={lastNameValue}
              onChange={handleLastNameChange}
              onError={handleLastNameError}
              error={lastNameError}
              loading={loading}
            />
          </GridItem>

          <GridItem xs={12} sm={6} md={6}>
            <Input
              label="Cargo"
              value={jobValue}
              onChange={handleJobChange}
              onError={handleJobError}
              error={jobError}
              loading={loading}
            />
          </GridItem>

          <GridItem xs={12} sm={6} md={6}>
            <Input
              type="email"
              label="Correo electrónico"
              value={emailValue}
              onChange={handleEmailChange}
              onError={handleEmailError}
              error={emailError}
              loading={loading}
            />
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem xs={12} md={4}>
        {formRef.current.greaterThanThounsand?.value === BINARY_CONFIRMATION_RESPONSE[0].value &&
          requiredMoreDocuments && (
            <Card
              enableBorder
              lineColor="default"
              color="blank"
              padding="24px"
              sx={{
                position: 'absolute',
                top: '0%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 10,
                maxWidth: '400px',
                width: '90%',
                display: { sm: 'none', xs: 'none', lg: 'block', md: 'block' }
              }}
            >
              <Grid container>
                <GridItem>
                  <Typography variant="h4" fontWeight="bold" padding="0 0 8px">
                    Se requiere informe técnico
                  </Typography>
                </GridItem>
                <GridItem>
                  <Typography variant="body2" lineHeight="18px">
                    {DIRECT_DEAL_MORE_DOCUMENTS_ARE_REQUIRED}
                  </Typography>
                </GridItem>
              </Grid>
            </Card>
          )}
      </GridItem>
    </Grid>
  )
}

DirectDealRequester.propTypes = {
  loading: PropTypes.bool,
  requesterInfo: PropTypes.shape({
    names: PropTypes.string,
    lastNames: PropTypes.string
  }),
  formRef: PropTypes.shape({
    current: PropTypes.shape(Object)
  }),
  requiredMoreDocuments: PropTypes.bool
}

export default DirectDealRequester
