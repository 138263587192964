import React from 'react'
import PropTypes from 'prop-types'

import { useViewport } from '@chilecompra/react-kit'
import { Button, Dialog, Typography } from '@chilecompra/react-kit/components'

import { ContainerCheck, Grid, MainCircle, StyledCheckIcon } from './DirectDealStepOne.styles'

/**
 * The DirectDealStepOne's dialog.
 */
const DirectDealStepOneSuccessDialog = ({ open, sheetCode, onBack, onNext }) => {
  const { size } = useViewport()

  return (
    <Dialog
      open={open}
      disableBackdropClick
      verticalAlign="top"
      onClose={onBack}
      padding={size.isSmall || size.isTiny ? '0' : '20px'}
    >
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Grid item textAlign="center" padding="13px">
          <ContainerCheck style={{ display: 'flex', justifyContent: 'center' }} margin="0 0 18px">
            <MainCircle>
              <StyledCheckIcon />
            </MainCircle>
          </ContainerCheck>
          <Typography fontWeight="bold" variant="h3">
            ID de Trato Directo creado
          </Typography>
        </Grid>
        <Grid item textAlign="center">
          <Typography variant="body1">Número ID de la ficha de Trato Directo</Typography>
          <Typography fontWeight="bold" variant="h2" color="success">
            {sheetCode || ''}
          </Typography>
        </Grid>
        <Grid item padding="32px 0 0" margin="4px 0" textAlign="center">
          <Button
            aria-label="Continuar creación de Ficha de Trato Directo"
            color="success"
            variant="contained"
            onClick={onNext}
          >
            Continuar creación de Ficha de Trato Directo
          </Button>
        </Grid>
        <Grid item padding="0 0 28px" margin="4px 0" textAlign="center">
          <Button aria-label="Guardar y volver al inicio del módulo" variant="text" onClick={onBack}>
            Guardar y volver al inicio del módulo
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

DirectDealStepOneSuccessDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  sheetCode: PropTypes.string,
  onBack: PropTypes.func,
  onNext: PropTypes.func
}

export default DirectDealStepOneSuccessDialog
