import React from 'react'
import { getCookie } from '@chilecompra/react-kit'
import { useNavigate } from 'react-router-dom'

import AuthorizationContext from '../../containers/AuthProvider/AuthProvider.context'
import AuthorizationProviderContainer from '../../containers/AuthProvider/AuthProvider.container'
import DirectDealPageHeader from '../../containers/DirectDealPageHeader/DirectDealPageHeader.container'
import DirectDealSummaryContainer from '../../containers/DirectDealSummary/DirectDealSummary.container'

import BrandLayout from '../../components/Layouts/BrandLayout'

import { Grid } from './DirectDealSummary.styles'

import routes from '../../config/settings/routes'

/**
 * The DirectDealSummary's page.
 */
const DirectDealSummary = () => {
  const navigate = useNavigate()

  const handlerRedirectSearch = () => {
    const query = getCookie('search')
    navigate({ pathname: routes.search, search: query.toString() }, { replace: true })
  }

  const summaryHeader = {
    breadcrumbs: [
      {
        text: 'Escritorio',
        onRedirectTo: handlerRedirectSearch
      },
      {
        text: 'Trato Directo',
        onRedirectTo: handlerRedirectSearch
      }
    ]
  }

  return (
    <AuthorizationProviderContainer>
      <AuthorizationContext.Consumer>
        {() => (
          <BrandLayout featured={<DirectDealPageHeader {...summaryHeader} />}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <DirectDealSummaryContainer />
              </Grid>
            </Grid>
          </BrandLayout>
        )}
      </AuthorizationContext.Consumer>
    </AuthorizationProviderContainer>
  )
}

export default DirectDealSummary
