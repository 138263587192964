import React from 'react'
import { Card, Spinner, Typography } from '@chilecompra/react-kit/components'
import { GridContainer, GridItem } from './SearchResults.styles'

/**
 * The SearchResultsLoading container.
 */
const SearchResultsLoading = () => {
  return (
    <Card enableBorder color="blank" padding="118px 138px">
      <GridContainer>
        <GridItem xs={12} textAlign="center">
          <Typography variant="h4" fontWeight="bold" padding="16px 0 20px " color="black1">
            Estamos realizando la búsqueda de Tratos Directos
          </Typography>
          <Spinner padding="16px 0 0" />
        </GridItem>
      </GridContainer>
    </Card>
  )
}

export default SearchResultsLoading
