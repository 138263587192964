import { makeActionCreator } from '../../config/store/utils'
import { getIdentity } from '../../services/config'
import { batch } from 'react-redux'
import { onShowSnackbar } from '../SnackBarProvider/SnackBarProvider.actions'

export const GET_INFO_DNI_SIGNER = 'GET_INFO_DNI_SIGNER'
export const GET_INFO_DNI_SIGNER_SUCCESS = 'GET_INFO_DNI_SIGNER_SUCCESS'
export const GET_INFO_DNI_SIGNER_ERROR = 'GET_INFO_DNI_SIGNER_ERROR'

export const onGetSignerInfoDNI = makeActionCreator(GET_INFO_DNI_SIGNER, 'payload')
export const onGetSignerInfoDNISuccess = makeActionCreator(GET_INFO_DNI_SIGNER_SUCCESS, 'payload')
export const onGetSignerInfoDNIError = makeActionCreator(GET_INFO_DNI_SIGNER_ERROR, 'payload')

export const onGetSignerInfoDNIThunk = payload => async dispatch => {
  dispatch(onGetSignerInfoDNI())

  try {
    const { data } = await getIdentity(payload)

    return dispatch(
      onGetSignerInfoDNISuccess({
        names: data?.payload.nombres || '',
        lastNames: `${data?.payload.apellidoPaterno || ''} ${data?.payload.apellidoMaterno || ''}`
      })
    )
  } catch (err) {
    return batch(() => {
      dispatch(onGetSignerInfoDNIError({ err }))
      dispatch(
        onShowSnackbar({
          title: 'No ha sido posible encontrar información',
          message: 'Te pedimos que lo vuelvas a intentar dentro de unos minutos.',
          severity: 'error'
        })
      )
    })
  }
}

export const RETURN_INFO_STEP_ZERO_DIRECT_DEAL_STEP_ZERO = 'RETURN_INFO_STEP_ZERO_DIRECT_DEAL_STEP_ZERO'
export const onReturnInfoStepZeroDirectDealStepZero = makeActionCreator(
  RETURN_INFO_STEP_ZERO_DIRECT_DEAL_STEP_ZERO,
  'payload'
)

export const CLEAN_SIGNER_DIRECT_DEAL_STEP_ZERO = 'CLEAN_SIGNER_DIRECT_DEAL_STEP_ZERO'
export const onCleanSignerDealStepZero = makeActionCreator(CLEAN_SIGNER_DIRECT_DEAL_STEP_ZERO)

export const CLEAN_INITIAL_SHEET_DIRECT_DEAL_STEP_ZERO = 'CLEAN_INITIAL_SHEET_DIRECT_DEAL_STEP_ZERO'
export const onCleanInitialSheetDealStepZero = makeActionCreator(CLEAN_INITIAL_SHEET_DIRECT_DEAL_STEP_ZERO)
