import React from 'react'

import { Grid as BaseGrid, styled } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

import { Button as ButtonBase, DatePicker as DatePickerBase, Card as BaseCard } from '@chilecompra/react-kit/components'

export const Root = styled('div')`
  margin: 0 auto;
  padding: 0 0 20px;
  width: 100%;
`

export const Row = styled(({ margin, padding, ...props }) => <BaseGrid container {...props} />)`
  &.MuiGrid-container {
    margin: ${props => props.margin || 0};
    padding: ${props => props.padding || 0};
    align-items: center;
  }
`

export const Column = styled(({ margin, padding, ...props }) => <BaseGrid item {...props} />)`
  &.MuiGrid-item {
    margin: ${props => props.margin || 0};
    padding: ${props => props.padding || 0};
    width: 100%;
  }
`

export const Card = styled(({ textAlign, ...props }) => <BaseCard {...props} />)`
  &.MuiPaper-root * {
    text-align: ${props => props.textAlign};
  }
`

export const Input = styled(({ fontSize, ...props }) => <BaseCard {...props} />)`
  &.MuiPaper-root * {
    text-align: ${props => props.textAlign};
    font-size: ${props => props.fontSize};
  }
`
export const HeadingFiltersForm = styled('form')`
  background-color: ${props => props.theme.palette.brand.gray3};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  grid-area: heading-filters;
  padding: 32px 24px 22px;
  width: 100%;

  ${props => props.theme.breakpoints.down('xs')} {
    flex-direction: column;
    padding: 12px;
  }
`

export const HeadingFiltersArea = styled('div')`
  grid-area: heading-filters;
`

export const SearchButton = styled(ButtonBase)`
  &.MuiButtonBase-root {
    width: 100%;
    height: 50px;
  }
`

export const GridContainer = styled(({ textAlign, ...props }) => <BaseGrid container {...props} />)`
  &.MuiGrid-container {
    height: ${props => props.height};
    margin: ${props => props.margin};
    padding: ${props => props.padding};
  }
`

export const GridItem = styled(({ textAlign, ...props }) => <BaseGrid item {...props} />)`
  &.MuiGrid-item {
    margin: ${props => props.margin};
    padding: ${props => props.padding};
  }
`
export const DatePicker = styled(({ className, ...props }) => (
  <DatePickerBase
    {...props}
    PopoverProps={{
      ...props.PopoverProps,
      anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
      classes: { ...props.PopoverProps?.classes, paper: className },
      transformOrigin: { horizontal: 'left', vertical: 'top' }
    }}
    variant="inline"
  />
))`
  &.MuiPopover-paper {
    box-shadow: 0 3px 6px ${props => props.theme.palette.gray[0.16]};
  }

  &.MuiPopover-paper .MuiPickersBasePicker-container {
    .MuiPickersToolbar-toolbar {
      background-color: ${props => props.theme.palette.blue[50]};
    }

    .MuiPickersToolbar-toolbar .MuiButtonBase-root:hover {
      background-color: transparent;
    }

    .MuiTypography-subtitle1,
    .MuiTypography-body1 {
      font-size: 16px;
    }

    .MuiTypography-h4 {
      font-size: 34px;
    }

    .MuiTypography-caption {
      font-size: 14px;
    }

    .MuiTypography-body2 {
      font-size: 14px;
    }

    .MuiTypography-h5 {
      font-size: 24px;
    }

    .MuiPickersCalendarHeader-iconButton .MuiSvgIcon-root {
      font-size: 24px;
    }
  }

  &.MuiPopover-paper .MuiPickersDay-day {
    color: ${props => props.theme.palette.gray[20]};
  }

  &.MuiPopover-paper .MuiPickersDay-current {
    color: ${props => props.theme.palette.blue[50]} !important;
  }

  &.MuiPopover-paper .MuiPickersDay-dayDisabled,
  &.MuiPopover-paper .MuiPickersCalendarHeader-dayLabel {
    color: ${props => props.theme.palette.gray[50]};
  }

  &.MuiPopover-paper .MuiPickersDay-daySelected {
    background-color: ${props => props.theme.palette.blue[50]} !important;
    color: ${props => props.theme.palette.brand.white} !important;
  }

  &.MuiPopover-paper .MuiPickersCalendarHeader-transitionContainer .MuiTypography-body1 {
    color: ${props => props.theme.palette.gray[20]};
  }

  &.MuiPopover-paper .MuiPickersCalendarHeader-iconButton {
    color: ${props => props.theme.palette.gray[50]};
  }

  &.MuiPopover-paper .MuiIconButton-root.Mui-disabled {
    color: ${props => props.theme.palette.gray[50]};
  }

  &.MuiPopover-paper .MuiPickersYear-root {
    color: ${props => props.theme.palette.gray[20]};
  }

  &.MuiPopover-paper .MuiPickersYear-yearDisabled {
    color: ${props => props.theme.palette.gray[50]};
  }

  &.MuiPopover-paper .MuiPickersYear-yearSelected {
    color: ${props => props.theme.palette.blue[50]} !important;
  }

  &.MuiPopover-paper .MuiDialogActions-root {
    display: none !important;
  }

  &.MuiPopover-paper .MuiTouchRipple-root {
    display: none;
  }
`

export const SearchIconCustom = styled(SearchIcon)`
  width: 20px;
  height: 20px;
  margin-left: 12px;
`
