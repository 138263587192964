import React from 'react'
import PropTypes from 'prop-types'

import { Card, Radio, Select, Textarea, Typography } from '@chilecompra/react-kit/components'
import {
  checkInputErrorMaxLength,
  checkInputErrorRequired,
  useRadio,
  useSelect,
  useTextarea
} from '@chilecompra/react-kit'

import { Grid, RowRadio } from './DirectDealSummary.styles'

import { useImperativeFormRef } from '../../modules/hooks/useFormRef'
import {
  DIRECT_DEAL_SUMMARY_DECISION_ISSUE_PURCHASE_OPTIONS,
  DIRECT_DEAL_SUMMARY_DECISION_ISSUE_PURCHASE_DICTIONARY,
  DIRECT_DEAL_SUMMARY_DECISION_OTHER_MECHANISM_OPTIONS
} from '../../config/settings/constants'

/**
 * The DirectDealSummary's decision.
 */
const DirectDealSummaryDecision = props => {
  const { contracts, formRef, loading } = props

  const { onChange: handleRadioDecision, value: valueRadioDecision } = useRadio({
    changeCallback: () => {
      setMechanismValue('')
      setReasonValue('')
    },
    errorCallbacks: [checkInputErrorRequired()]
  })

  const {
    error: mechanismError,
    onChange: handleMechanismChange,
    setValue: setMechanismValue,
    value: mechanismValue
  } = useSelect({ errorCallbacks: [checkInputErrorRequired()] })

  const {
    error: reasonError,
    onChange: handleReasonChange,
    setValue: setReasonValue,
    value: reasonValue
  } = useTextarea({ errorCallbacks: [checkInputErrorMaxLength(500), checkInputErrorRequired()] })

  useImperativeFormRef(
    formRef,
    () => ({
      mechanismError,
      mechanismValue,
      reasonError,
      reasonValue,
      valueRadioDecision
    }),
    [mechanismError, mechanismValue, reasonError, reasonValue, valueRadioDecision]
  )

  return (
    <Grid container spacing={2} margin="0">
      <Grid item md={8} xs={12}>
        <Card enableBorder lineColor="primary" color="blank" padding="32px 24px">
          <Grid container>
            <Grid item lg={10} md={8} sm={7} xs={12}>
              <Typography fontWeight="bold" variant="h3" margin="0 0 16px">
                Decisión del Trato Directo
              </Typography>

              {contracts.requestTotal > 0 && (
                <Typography variant="body2">
                  La publicación de tu Trato Directo recibió respuestas de proveedores, selecciona como deseas continuar
                  con tu proceso de compra:
                </Typography>
              )}
              {contracts.requestTotal <= 0 && (
                <Typography variant="body2">
                  La publicación de tu Trato Directo no recibió respuestas de proveedores, selecciona como deseas
                  continuar con tu proceso de compra:
                </Typography>
              )}
            </Grid>
            <Grid item margin="24px 0 0">
              <RowRadio>
                <Radio
                  options={DIRECT_DEAL_SUMMARY_DECISION_ISSUE_PURCHASE_OPTIONS}
                  loading={loading}
                  value={valueRadioDecision}
                  onChange={handleRadioDecision}
                />
              </RowRadio>
            </Grid>
            {valueRadioDecision ===
              DIRECT_DEAL_SUMMARY_DECISION_ISSUE_PURCHASE_DICTIONARY.CONTINUE_WITH_ORIGINAL_PROVIDER && (
              <Grid item>
                <Typography variant="body2">
                  Debes ingresar la justificación para realizar el Trato Directo con el proveedor declarado.
                </Typography>
                <Typography variant="body2" fontWeight="bold" margin="0 0 24px">
                  Esta información será visible para todos los usuarios.
                </Typography>
                <Textarea
                  error={reasonError}
                  label="Justificación"
                  loading={loading}
                  maxCount={500}
                  size="medium"
                  value={reasonValue}
                  onChange={handleReasonChange}
                />
              </Grid>
            )}
            {valueRadioDecision === DIRECT_DEAL_SUMMARY_DECISION_ISSUE_PURCHASE_DICTIONARY.USE_ANOTHER_MECHANISM && (
              <Grid item>
                <Typography variant="body2" fontWeight="bold" margin="0 0 24px">
                  Indícanos el nuevo mecanismo de compra que utilizarás para realizar el proceso de compra y el motivo
                  por el cuál decidiste realizar el cambio.
                </Typography>
                <Select
                  error={mechanismError}
                  label="Mecanismo de compra"
                  loading={loading}
                  options={DIRECT_DEAL_SUMMARY_DECISION_OTHER_MECHANISM_OPTIONS}
                  value={mechanismValue}
                  onChange={handleMechanismChange}
                />
                <Textarea
                  error={reasonError}
                  label="Motivo"
                  loading={loading}
                  maxCount={500}
                  size="medium"
                  value={reasonValue}
                  onChange={handleReasonChange}
                />
              </Grid>
            )}

            {valueRadioDecision === DIRECT_DEAL_SUMMARY_DECISION_ISSUE_PURCHASE_DICTIONARY.STOP_PROCESS && (
              <Grid item>
                <Typography variant="body2" fontWeight="bold" margin="0 0 24px">
                  Indícanos el motivo por el cuál decidiste no realizar el Trato Directo
                </Typography>
                <Textarea
                  error={reasonError}
                  label="Motivo"
                  loading={loading}
                  maxCount={500}
                  size="medium"
                  value={reasonValue}
                  onChange={handleReasonChange}
                />
              </Grid>
            )}
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}

DirectDealSummaryDecision.propTypes = {
  contracts: PropTypes.shape({
    requestClosedDate: PropTypes.string,
    requestTotal: PropTypes.number,
    requests: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        dni: PropTypes.string,
        isActive: PropTypes.bool,
        answer: PropTypes.string,
        legalName: PropTypes.string
      })
    )
  }),
  formRef: PropTypes.shape({
    current: PropTypes.shape(Object)
  }),
  loading: PropTypes.bool
}

export default DirectDealSummaryDecision
