import React, { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { List, ListItem, Collapse, IconButton } from '@mui/material'

import { useEffectOnce, useSelect } from '@chilecompra/react-kit'
import { Select, Skeleton, Toast, Typography } from '@chilecompra/react-kit/components'

import ProductCategorySearchRow from '../../containers/ProductCategorySearch/ProductCategorySearch.row'
import { onAddProductSuccess } from '../ProductSearch/ProductSearch.actions'
import {
  onGetCategoriesLevelTwoThunk,
  onGetCategoriesThunk,
  onGetGoodsAndServicesByCategoryThunk
} from './ProductCategorySearch.actions'
import {
  ArrowDownIcon,
  ArrowRightIcon,
  Divider,
  Root,
  Grid,
  SkeletonWrapper,
  ListItemText,
  ListItemSecondaryText
} from './ProductCategorySearch.styles'

/**
 * The ProductSearchCategory's tab container.
 */
const ProductSearchTabCategory = () => {
  const {
    categories,
    categoriesLevelTwo,
    error,
    gettingCatergories,
    gettingCatergoriesLevelTwo,
    gettingGoodsAndServices,
    goodsAndServices
  } = useSelector(state => state.productCategorySearch)
  const { productsSaved } = useSelector(state => state.productSearch)

  const dispatch = useDispatch()

  const [categoryName, setCategoryName] = useState('')
  const [openItems, setOpenItems] = useState({})

  const handleOnChangeCategory = updatedValue => {
    setCategoryName('')
    dispatch(onGetCategoriesLevelTwoThunk({ categoryId: updatedValue }))
  }

  const { onChange: handleCategoryChange, value: categoryValue } = useSelect({
    changeCallback: handleOnChangeCategory
  })

  const handleGetGoodAndServices = (categoryId, categoryName) => () => {
    setCategoryName(categoryName)
    dispatch(onGetGoodsAndServicesByCategoryThunk({ categoryId, categoryName }))
  }

  const handleAdd = (id, name, categoryId) => () => {
    const productKey = (Math.random() + 1).toString(36).substring(7)
    const newProduct = {
      amount: '0',
      categoryId,
      charge: '0',
      codeZGEN: '',
      detail: '',
      discount: '0',
      discountOrCharge: '',
      dontKnowCodeZGEN: false,
      isInvalid: false,
      productId: id,
      productKey,
      productName: name,
      unitMeasure: '',
      unitPrice: '0',
      withDiscountOrCharge: false
    }
    dispatch(onAddProductSuccess({ newProduct }))
  }

  const handlerToggle = nodeId => {
    return () =>
      setOpenItems(prevState => ({
        ...prevState,
        [nodeId]: !prevState[nodeId]
      }))
  }

  useEffectOnce(() => {
    dispatch(onGetCategoriesThunk())
  })

  return (
    <Root>
      <Grid container>
        <Grid item>
          <Select
            label="Clasificador"
            loading={gettingCatergories}
            onChange={handleCategoryChange}
            options={categories || []}
            value={categoryValue}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item>
          {gettingCatergoriesLevelTwo && (
            <SkeletonWrapper>
              <Skeleton height={35} variant="text" width={300} />
              <Skeleton height={35} margin="0 0 0 20px" variant="text" width={300} />
              <Skeleton height={35} margin="0 0 0 20px" variant="text" width={300} />
              <Skeleton height={35} variant="text" width={300} />
              <Skeleton height={35} margin="0 0 0 20px" variant="text" width={300} />
              <Skeleton height={35} margin="0 0 0 20px" variant="text" width={300} />
            </SkeletonWrapper>
          )}
          {!gettingCatergoriesLevelTwo && (
            <List aria-labelledby="Clasificador de rubros">
              {categoriesLevelTwo.map(level2 => (
                <div key={level2.value}>
                  <ListItem onClick={handlerToggle(level2.name)}>
                    <ListItemText primary={level2.name} />
                    <IconButton size="small">
                      {openItems[level2.name] ? <ArrowDownIcon /> : <ArrowRightIcon />}
                    </IconButton>
                  </ListItem>
                  <Collapse in={!!openItems[level2.name]} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {level2.categories.map(category => (
                        <ListItem key={category.id}>
                          <ListItemText
                            secondary={
                              <ListItemSecondaryText
                                aria-label="Nombre categoria"
                                onClick={handleGetGoodAndServices(category.id, category.name)}
                              >
                                {category.name}
                              </ListItemSecondaryText>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </div>
              ))}
            </List>
          )}
        </Grid>
      </Grid>
      <Divider margin="32px 0 24px" />
      <Grid container>
        <Grid item>
          <Typography color="black1" fontWeight="bold" variant="h4">
            Selecciona los productos a agregar
          </Typography>
        </Grid>
      </Grid>
      {gettingGoodsAndServices && (
        <Grid container margin="13px 0">
          <Grid item>
            <SkeletonWrapper>
              <Skeleton height={35} width={150} />
              <Skeleton height={50} variant="text" />
              <Skeleton height={50} variant="text" />
              <Skeleton height={50} variant="text" />
              <Skeleton height={50} variant="text" />
            </SkeletonWrapper>
          </Grid>
        </Grid>
      )}
      {!gettingGoodsAndServices && goodsAndServices?.length > 0 && (
        <>
          <Grid container margin="16px 0 0">
            <Grid item>
              <Typography color="black1" fontWeight="bold" variant="body2">
                {categoryName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container margin="13px 0">
            <Grid item>
              {goodsAndServices.map(goodAndService => (
                <Fragment key={goodAndService.value}>
                  <ProductCategorySearchRow
                    categoryId={goodAndService.categoryId}
                    handleAdd={handleAdd}
                    id={goodAndService.value}
                    isAdded={productsSaved.some(added => added.productId === goodAndService.value)}
                    key={goodAndService.value}
                    name={goodAndService.name}
                  />
                  <Divider key={goodAndService.value} />
                </Fragment>
              ))}
            </Grid>
          </Grid>
        </>
      )}
      <Grid container margin="16px 0 0">
        {!gettingCatergories && !gettingCatergoriesLevelTwo && !gettingGoodsAndServices && error && (
          <Toast severity="error" title="Lo sentimos, estamos presentando problemas con nuestro servidor.">
            En estos momentos tenemos problemas para disponibilizar la información. Por favor, consulta en unos minutos
            más.
          </Toast>
        )}
      </Grid>
    </Root>
  )
}

export default ProductSearchTabCategory
