import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import { useEffectOnce } from '@chilecompra/react-kit'
import { Spinner } from '@chilecompra/react-kit/components'

import AuthorizationContext from './AuthProvider.context'
import { Root } from './AuthProvider.styles'

import { onGetTokenThunk, onGetPublicTokenThunk } from './AuthProvider.actions'

import routes from '../../config/settings/routes'

/**
 * The Authorization Provider container.
 */
const AuthorizationProvider = ({ children }) => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()

  const {
    authInfo: { isBuyer, isSeller, loading: loadingAuthInfo, userDni, userType },
    accessToken,
    gettingToken
  } = useSelector(state => state.authorizationProvider)

  const isModePublic =
    pathname === routes.searchPublic || pathname.split('/')[1] === routes.directDealSummaryPublic.split('/')[1]
  const isPrivated = useMemo(() => {
    return userType === '' ? undefined : userType === 'Comprador' || userType === 'Proveedor'
  }, [userType])

  useEffectOnce(() => {
    if (accessToken === undefined && isModePublic) {
      dispatch(onGetPublicTokenThunk())
      return
    }

    if (accessToken === undefined && !isModePublic) {
      dispatch(onGetTokenThunk())
    }
  })

  if (!(accessToken && !gettingToken && userType && !loadingAuthInfo)) {
    return (
      <Root>
        <Spinner />
      </Root>
    )
  }

  return (
    <AuthorizationContext.Provider value={{ accessToken, isBuyer, isPrivated, isSeller, userDni, userType }}>
      {children}
    </AuthorizationContext.Provider>
  )
}

AuthorizationProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export default AuthorizationProvider
