import React from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { Button, DataTable, Typography } from '@chilecompra/react-kit'

import {
  onStorageIdDirectDealQuote,
  onGenerateDirectDealQuoteThunk,
  onOpenDetailQuoteDirectDealQuote,
  onOpenQuoteDirectDealQuote,
  onRemoveQuoteDirectDealQuoteThunk
} from './DirectDealQuote.actions'
import { onShowSnackbar } from '../../SnackBarProvider/SnackBarProvider.actions'

import DirectDealStepTwoQuoteDialog from '../Quotes/DirectDealStepTwo.quote.dialog'
import DirectDealStepTwoDetailQuoteDialog from './DirectDealStepTwo.quote.detail.dialog'

import {
  ArrowButton,
  GridContainer,
  GridItem,
  Link,
  PlusIcon,
  DeleteIconCustom,
  DeleteLink
} from '../DirectDealStepTwo.styles'

import { dialogScrollTop } from '../../../modules/utils/dialogScrollTop'

/**
 * The DirectDealStepTwo quotes container.
 */
const DirectDealStepTwoQuotes = props => {
  const { code, dialog, loading, onAdd, quote, quoteKey, quotes } = props

  const dispatch = useDispatch()

  const handlerOpenQuoteEdit = idQuote => {
    return () => {
      onAdd()
      dispatch(onStorageIdDirectDealQuote(idQuote))
      dispatch(onOpenDetailQuoteDirectDealQuote())
      dialogScrollTop()
    }
  }

  const handlerOpenQuote = () => {
    dispatch(
      onGenerateDirectDealQuoteThunk({
        code,
        callback: () => {
          onAdd()
          dispatch(onOpenQuoteDirectDealQuote())
          dialogScrollTop()
        }
      })
    )
  }

  const handlerRemoveQuote = idQuote => {
    return () => {
      dispatch(
        onRemoveQuoteDirectDealQuoteThunk({
          idQuote,
          callback: () => {
            dispatch(
              onShowSnackbar({
                title: 'Cotización removida con éxito.',
                severity: 'success'
              })
            )
            dialogScrollTop()
          },
          callbackError: () => {
            dispatch(
              onShowSnackbar({
                title: 'No ha sido posible remover la cotización.',
                message: 'Te pedimos que lo vuelvas a intentar dentro de unos minutos.',
                severity: 'error'
              })
            )
            dialogScrollTop()
          }
        })
      )
    }
  }

  return (
    <>
      <GridContainer margin="33px 0" spacing={2}>
        <GridItem lg={2} md={2} sm={12} xs={12}>
          <Typography variant="body1" fontWeight="bold">
            Datos de cotización
          </Typography>
          <Typography variant="body2" padding="8px 0 0">
            En caso de que hayas realizado un proceso de cotización previo, puedes adjuntar dicha información a tu Trato
            Directo.
          </Typography>
        </GridItem>

        <GridItem lg={6} md={8} sm={12} xs={12}>
          <Typography variant="body2" fontWeight="bold">
            Cotizaciones
          </Typography>
          <Typography variant="body2" margin="0 0 17px" padding="8px 0 0">
            Para incluir la o las cotizaciones recibidas haz clic en "Adjuntar nueva cotización", completa el formulario
            con la información del proveedor y adjunta tus archivos.
          </Typography>

          {quotes && quotes.length > 0 && (
            <DataTable
              accessibility={{ label: 'Cotizaciones' }}
              headers={[
                { label: 'Rut', value: 'contributorDni' },
                { label: 'Razón social', value: 'contributorLegalName' },
                { label: '', value: '' },
                { label: '', value: '' }
              ]}
              headersCellsProps={[
                { enableSort: true, width: '20%', style: { padding: '14px 8px' } },
                { enableSort: true, width: '35%', style: { padding: '14px 8px' } },
                { enableSort: false, width: '15%', style: { padding: '14px 8px' } },
                { enableSort: false, width: '25%', style: { padding: '14px 8px' } }
              ]}
              enableStripped
              loading={loading}
              records={quotes}
              recordsCellsProps={[
                { key: 'col1', style: { padding: '14px 8px' } },
                { key: 'col2', style: { padding: '14px 8px' } },
                { key: 'col3', style: { padding: '14px 8px' } },
                { key: 'col4', style: { padding: '14px 8px', textAlign: 'right' } }
              ]}
              recordsDecorators={[
                ({ contributorDni }) => (
                  <Typography variant="body2" lineHeight="18px">
                    {contributorDni}
                  </Typography>
                ),
                ({ contributorLegalName }) => (
                  <Typography variant="body2" lineHeight="18px">
                    {contributorLegalName}
                  </Typography>
                ),
                ({ idQuote }) => (
                  <>
                    <Typography variant="body2">
                      <Link fontWeight="bold" onClick={handlerOpenQuoteEdit(idQuote)}>
                        Ver detalle <ArrowButton margin="0 0 -3px 4px" fontSize="16px" />
                      </Link>
                    </Typography>
                  </>
                ),
                ({ idQuote }) => (
                  <Typography variant="body2">
                    <DeleteLink fontWeight="bold" onClick={handlerRemoveQuote(idQuote)}>
                      Eliminar cotización <DeleteIconCustom margin="0 0 -3px 4px" fontSize="16px" />
                    </DeleteLink>
                  </Typography>
                )
              ]}
            />
          )}
          {!loading && (
            <Button
              aria-label="Adjuntar nueva cotización"
              variant="outlined"
              color="primary"
              onClick={handlerOpenQuote}
            >
              <PlusIcon /> Adjuntar nueva cotización
            </Button>
          )}
        </GridItem>
      </GridContainer>
      <DirectDealStepTwoQuoteDialog open={dialog.openQuote} quote={quote} loading={loading} />
      <DirectDealStepTwoDetailQuoteDialog
        open={dialog.openQuoteDetail}
        quotes={quotes}
        quoteId={quoteKey}
        loading={loading}
      />
    </>
  )
}

DirectDealStepTwoQuotes.propTypes = {
  code: PropTypes.string,
  onAdd: PropTypes.func,
  quotes: PropTypes.arrayOf(
    PropTypes.shape({
      contributorDni: PropTypes.string,
      contributorLegalName: PropTypes.string,
      idQuote: PropTypes.string
    })
  ),
  dialog: PropTypes.shape({
    openQuote: PropTypes.bool,
    openQuoteDetail: PropTypes.bool
  }),
  loading: PropTypes.bool,
  quote: PropTypes.shape({
    contributorDni: PropTypes.string,
    contributorLegalName: PropTypes.string,
    idQuote: PropTypes.string
  }),
  quoteKey: PropTypes.string
}

export default DirectDealStepTwoQuotes
