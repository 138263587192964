import { factoryService, getFileRequest, getJsonRequest, postJsonRequest } from '@chilecompra/react-kit/requests'
import { REACT_APP_API_BASE_URL } from '../config/settings/environment'

/**
 * Gets a excel file to download with the quote search results
 */
export const getDownloadDirectDealSearch = factoryService(({ queryString }) =>
  getFileRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/reportes/excel?${queryString}`)
)

/**
 * Gets the DIRECT DEAL search
 */
export const getDirectDealSearch = factoryService(({ queryString }) =>
  getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/buscar?${queryString}`)
)

/**
 * Gets the DIRECT DEAL search for saved deals
 */
export const getSavedDirectDealSearch = factoryService(({ directDealId }) =>
  getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/buscar/${directDealId}`)
)

/**
 * POST: Create a direct deal sheet
 */
export const postDirectDealCreate = factoryService(({ sheetStepOne, sheetStepZero }) => {
  const body = {
    causal: sheetStepOne?.cause?.value,
    esMayor1000UTM: sheetStepOne?.amount,
    publicidad: sheetStepOne?.withPublicity,
    requirente: {
      rut: sheetStepOne?.dni,
      nombres: sheetStepOne?.name,
      apellidos: sheetStepOne?.lastName,
      cargo: sheetStepOne?.job,
      correo: sheetStepOne?.email
    },
    firmante: {
      resolucion: sheetStepZero?.resolution,
      fechaResolucion: sheetStepZero?.resolutionDate,
      nombres: sheetStepZero?.names,
      apellidos: sheetStepZero?.lastNames,
      rut: sheetStepZero?.dni,
      cargo: sheetStepZero?.job
    },
    origen: parseInt(sheetStepZero?.originDirectDeal)
  }
  return postJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/crear`, { body })
})
/**
 * Gets the descriptions of the direct deal causes
 */
export const getDetailDirectDealSheet = factoryService(directDeal => {
  return getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/detalle?idFicha=${directDeal.id}`)
})
/**
 * POST: to apply the decision of a direct deal sheet
 */
export const postApplyDecisionDirectDealSheet = factoryService(({ request }) => {
  const body = {
    idTratoDirecto: request.sheetId,
    mecanismoCompra: request.mechanism,
    decision: request.decision,
    descripcion: request.description
  }

  return postJsonRequest(`${REACT_APP_API_BASE_URL}/v1/trato-directo/decision`, { body })
})
